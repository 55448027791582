
@use "../../scss/abstract/variable" as variable;

.TagParent__Container{
    justify-content: center;
    display: inline-flex;
    align-items: center;
    top: 5px;
    position: absolute;
    min-width: 30px;
    color: variable.$orange;
    background-color: variable.$orange-light;
    font-size: .775rem;
    padding-left: 2px;
    min-height: 24px;
    z-index: 1;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-normal);
}
.TagRadius_Normal{
    border-radius: 2px;
}

.TagRadius_Round{
    border-radius: 15px;
}
