@use "../../scss/abstract/fonts" as fonts;
//@use "../../scss/abstract/colors" as colors;

.main {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .hasPadding {
    padding: calc(2vw + 1vh);
  }
  .hasBorder {
    border: 1px solid #e3e8ee;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  }
  .centerGradient::before,
  .bottomGradient::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-clip-path: polygon(0% 45px, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0% 45px, 100% 0, 100% 100%, 0% 100%);
  }
  .centerGradient::before {
    height: 65%;
    background: linear-gradient(180deg, #f2f3f9 -8%, rgba(242, 243, 249, 0) 20%);
  }
  .bottomGradient::before {
    height: 18%;
    background: linear-gradient(
      180deg,
      #f2f3f9 -30%,
      rgba(242, 243, 249, 0) 100%
    );
  }
  .graphic {
    position: relative;
    margin-bottom: 20px;
  }
  .isIcon {
    background-color: #eff1f5;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isIcon svg {
    width: 48px;
    height: 48px;
    vertical-align: bottom;
  }
  .textContainer {
    position: relative;
    text-align: center;
    max-width: 520px;
  }
  .heading {
    font-size: 18px;
    margin: 0;
    line-height: 1;
    font-family: fonts.$fontfamily;
    text-transform: capitalize;
  }
  .heading.faded {
    color: #767676;
  }
  // .heading.small {
  // }
  .text {
    font-size: 14px;
    margin-bottom: 24px;
  }
  .text.faded {
    color: #767676;
  }
  .link {
    text-decoration: underline;
    white-space: nowrap;
    color: inherit;
  }
  