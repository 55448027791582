
/*===============================================================

    #Variables

==================================================================*/

/**

* TABLE OF CONTENTS

* COLORS

* TYPOGRAPHY

* BREAKPOINTS

* SPACING

*/

/*=============================================
=             Colors            =
=============================================*/

/* Brand Colors */




//$primary: #E04826;
$primary-light:#fc624d;

$primary: #F8880A;
$primary-400: #F8A141;
$primary-300: #FFB665;
$primary-200: #FFD09C;
$primary-100: #FFE5C8;

//$secondary: #232F3E; 
$secondary-light: #364353;

$secondary: #1D4057; 
$secondary-400: #2F6081; 
$secondary-300: #4C7692; 
$secondary-200: #6991AD; 
$secondary-100: #B9D1E2;

$greyscale: #212121;

//$Alert
$color-blue-light: #F5FBFF;
$color-success: #4ADE80; 
$color-info: #246BFD; 
$color-warning: #FACC15; 
$color-error: #F75555; 
$color-disabled: #D8D8D8; 
$color-disabled-btn: #393939; 


//$Other color
$color-white: #ffff;
$color-black: #000;
$color-red: #F54336;
$color-success-500: #12B76A;



/* Neutral Colors */

$bg-color: #f9f9f9;
$white: #fff; 
$white-dark: #F2F2F2;  //body-color
$white-grey: #f1f2f3;

$gray-lighter: #dbdbdb; //border color e9ecef
$gray-light: #E5E7EB; //border light
$gray-neural: #6c757d; 
$gray-dark: #424656; //body-text color
$gray-icon: #DDE4F0;
/* Utility Colors  */
$transparent: transparent;

$black: #000;

$red: #d12727;
$red-light: #f8dedf;
$red-dark:  #de1c24;

$green: #0F8C24;
$green-light: #62d58c;
$green-dark: #1d5f02;

$blue: #0A54B1;
$blue-light: #f0f9ff57;

$orange: #f68b1e;
$orange-light: #feefde;



/*=============================================
=            Opacity            =
=============================================*/
$alpha-sm: 0.8;

$alpha-md: 0.5;

$alpha-lg: 0.3;

$alpha-xl: 0.1;


/*=====  End of Opacity  ======*/



/*=====  End of Colors  ======*/

/*=============================================
=            Typography            =
=============================================*/

/* Font Families */

//@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font: 'Urbanist', sans-serif;

// @import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
// $font: 'Euclid Circular A', sans-serif;



/* Font weights */

$fw-thin: 100;

$fw-extralight: 200;

$fw-light: 300;

$fw-normal: 400;

$fw-medium: 500;

$fw-semibold: 600;

$fw-bold: 700;

$fw-extrabold: 800;

$fw-black: 900;

/*=============================================
=            Breakpoints            =
=============================================*/

$breakpoints: (
 // 768px
  sm: 576px, //37.5rem,

  // 768px
  md: 768px, //48rem,

  // 1024px
  lg: 992px, //64rem,

  // 1280px
  xl: 1200px,

  xxl: 1400px,
) !default;
