.DropdownMenu {
	display: block;
	position: relative;
	cursor: default;
	outline: none;
	font-size: 14px;
}
.DropdownMenu__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	min-width: 24px;
	margin-right: 2px;
	margin-left: 2px;
	/* color: #464a55; */
	/* color: currentColor; */
}
.DropdownMenu__icon svg {
	width: 16px;
}
.DropdownMenu__item {
	display: flex;
	padding: 0 6px;
	line-height: 2;
	align-items: center;
	cursor: pointer;
}
.DropdownMenu__item:hover:not(.is-disabled) {
	color: #fff !important;
	background-color: rgb(210, 209, 209);
}
.DropdownMenu__item:hover:not(.is-disabled) .DropdownMenu__icon {
	color: #fff;
}
.DropdownMenu__item:hover:not(.is-disabled) .DropdownMenu__body {
	color: #fff;
}
.DropdownMenu__item.is-disabled {
	cursor: default;
	pointer-events: none;
	opacity: 0.3;
}
.DropdownMenu__link,
.DropdownMenu__link:hover {
	color: inherit;
	text-decoration: inherit;
}
.DropdownMenu__entry,
.DropdownMenu .dropdown-menu > li > a.DropdownMenu__entry {
	display: block;
	white-space: normal;
	align-items: center;
	padding: 6px;
	line-height: 1.42857143;
}
.DropdownMenu__title {
	font-weight: bold;
}
.DropdownMenu__body {
	font-size: 13px;
	line-height: 1.23076923;
	color: #464a55;
}

.DropdownMenu__item--withTitle .DropdownMenu__icon {
	align-self: flex-start;
	margin-top: 10px;
}

.DropdownMenu__item--withTitle {
	min-width: 250px;
}

.DropdownMenu__item.text-danger:hover {
	background: #dc3545;
	color: #fff;
}
