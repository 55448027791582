
.InputFieldBorder{
  border: none;
  box-shadow: rgb(217, 219, 222) 0px 0px 0px 1px !important;
}

.InputFieldBorder_error{
  border-width: 1px !important;
  border: 1px solid  var(--eke-border-danger-color) !important;

}




.InputField__input[type="text"],
.InputField__input[type="password"],
.InputField__input[type="datetime"],
.InputField__input[type="datetime-local"],
.InputField__input[type="number"],
.InputField__input[type="email"],
.InputField__input[type="url"],
.InputField__input[type="search"],
.InputField__input[type="color"],
textarea 

{
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 12px 12px;
}


input::placeholder,
textarea::placeholder {
  font-size: 14px !important;
}


.InputField__fieldContainer {
    position: relative;
  }

.InputField--error .InputField__input {
  border: 1px solid  var(--eke-border-danger-color) !important;
  background: #fce8e7 !important;
}
.InputField--error .InputField__input:focus {
  border: 1px solid  var(--eke-border-danger-color) !important;
}
.InputField--small .InputField__fieldContainerPrefix {
    left: 10px;
    font-size: inherit;
}

.InputField--small .InputField__input[type="checkbox"]{
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.InputField--small .InputField__input[type="text"],
.InputField--small .InputField__input[type="password"],
.InputField--small .InputField__input[type="date"],
.InputField--small .InputField__input[type="number"],
.InputField--small .InputField__input[type="email"],
.InputField--small .InputField__input[type="search"],
.InputField--small .InputField__input[type="tel"],
.bbf-editor  [type="text"] {
  color: #262e39;
  text-align: left;
  white-space: nowrap;
  box-sizing: border-box;
  flex: 1 1 auto;
  font-weight: var(--eke-font-weight-normal);
  font-family: var( --eke-body-font-family);
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  align-items: center;
  background-color: var(--eke-bg-light-color);
  border-radius: 5px;
  display: flex;
  min-width: 0;
  padding: 8px;

  &:disabled{
    background-color: #fbf9f9 !important;
  }
}


.InputField__input[type="text"]:focus,
.InputField__input[type="password"]:focus,
.InputField__input[type="date"]:focus,
.InputField__input[type="month"]:focus,
.InputField__input[type="time"]:focus,
.InputField__input[type="week"]:focus,
.InputField__input[type="number"]:focus,
.InputField__input[type="email"]:focus,
.InputField__input[type="url"]:focus,
.InputField__input[type="search"]:focus,
textarea:focus

{
  outline: 0;
 
}

.InputField__input[disabled] {
  cursor: not-allowed;
  background-color: #fbf9f9 !important;
}


  .InputField--large .InputField__input[type=checkbox]{
    width: 1.1rem;
    height: 1.1rem;
    cursor: pointer;
  }
  
  // .InputField--large .InputField__input[type="checkbox"]:checked{
  //   background-color: red !important;
  // }

  .description {
    margin-top: 1px;
    line-height: 1.5rem;
    font-size: .75rem;
    color: var(--eke-body-text-gray-color);
    font-weight: var(--eke-font-weight-light);
    font-family: var(--eke-body-font-family);
  }
  

.InputField--large .InputField__input[type="text"],
.InputField--large .InputField__input[type="password"],
.InputField--large .InputField__input[type="date"],
.InputField--large .InputField__input[type="number"],
.InputField--large .InputField__input[type="email"],
.InputField--large .InputField__input[type="search"],
.InputField--large .InputField__input[type="tel"],
  .InputField--large textarea{
    color: #262e39;
    text-align: left;
    white-space: nowrap;
    box-sizing: border-box;
    flex: 1 1 auto;
    font-weight: var(--eke-font-weight-normal);
    font-family: var( --eke-body-font-family);
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    align-items: center;
    background-color: var(--eke-bg-light-color);
    border-radius: 5px;
    display: flex;
    min-width: 0;
    padding: 12px;

    &:disabled{
      background-color: #fbf9f9 !important;
    }
  }

  .InputField__input[type="password"] {
      padding-right: 20px;
      position: relative;
  }
  
  .InputField__Password_Visibility{
    position: absolute;
    right: 0;
    top: 0;
    //flex: none;
    padding: 4px 0 0px;

    button{
      text-decoration: underline;

      .BtnSpan{
        font-size: 12.4px;
      }
    }
}

.InputField--error .InputField__input[type="text"]:focus {
    // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    // rgb(208 1 27 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    // rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    // rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px !important;
}
