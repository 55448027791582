.ListItem_FrameWrapper{
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.ListItemFlex{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
}

.ListItem_Typography_Div{
    width: 100%;
    flex-grow: 1;

    .ListItem_Heading{
        font-weight: 540 !important;
        color: #000 !important;
    }

    .Address_Para{
        margin-top: 5px !important;
        margin-bottom: 0 !important;
        font-size: 13.5px !important;
    }

    .AddressDefault{
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 3px;
        line-height: 1.5rem;
        font-size: .65rem !important;
        padding: 0 !important;
        color: var(--eke-body-text-gray-color) !important;
        font-weight: var(--eke-font-weight-light) !important;

        svg{
            width: 0.85rem !important;
            //fill: var(--eke-bg-success-color);
        }
    }
}

.BtnSpan{
    font-size: 13px !important;
}

.ListItem_Button{
    padding: 0 !important;
    text-decoration: underline;
}
