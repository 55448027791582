
.loading {
	opacity: 0.5;
}
.input[type="checked"]{
	cursor: pointer  !important;
}
.th {
	/* padding: 8px 16px 6px; */
	border-bottom: 1px solid #e3e8ee;
	white-space: nowrap;
	padding: 8px 16px 6px 16px !important;
	background: transparent;
	font-family: var(--eke-body-font-family);
	color: var(--greyscale-900, #212121) !important;
	font-weight: var(--eke-font-weight-semibold) !important;
	font-size: 12.5px;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.2px;

}
.th:first-child {
	border-top-left-radius: 4px;
	padding-left: 8px !important;

}
table tr{
	border: none !important;
}
.th:last-child {
	border-top-right-radius: 4px;
}
.td {
	padding: 5px 10px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	color: var(--greyscale-700, #333) !important;
	font-family: var(--eke-body-font-family);
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.2px;
}

.td:nth-child(2){
	text-transform: capitalize !important;
}

 .td:first-child {
	width: 10px !important;
}

.tr:not(:first-child) .td {
	border-top: 1px solid #f2f2f2;
}

.tr:last-child .td:first-child {
	border-bottom-left-radius: 4px;
}
.tr:last-child .td:last-child {
	border-bottom-right-radius: 4px;
}
.tr.clickable {
	cursor: pointer;
	font-size: 14px !important;
}
.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
.content-center {
	display: flex;
	align-items: center;
	& > * {
		margin-right: 10px;
	}
}
.tr.hover:hover {
	background: #ffe5c8e0;
}
.cover {
	padding-top: 24px;
	padding-bottom: 24px;
	text-align: center;
}
.more {
	border: none;
}
.moreIcon {
	width: 16px;
	padding: 4px;
	box-sizing: content-box;
	background: none;
	border: none;
	outline: none;
	color: #9b9b9b;
	display: inline-flex;
	border-radius: 4px !important;
	padding: 6px !important;
}
.moreIcon:hover {
	color: #262e39;
}
.moreIcon svg {
	width: 100%;
}
.moreIcon:hover {
	background-color: rgb(255, 255, 255) !important;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
		rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
		rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
		rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
	border: 0 !important;
}

.simple {
	border: none !important;
	font-weight: 400 !important;
}

.simple .th,
.simple .td {
	background: transparent;
	padding-left: 0;
	padding-right: 0;
}

.simple .th {
	text-transform: uppercase;
	padding: 12px 8px !important;
}

.simple .td {
	padding: 7px 8px;
}

.simple .td:first-child {
	width: 10%px !important;
}

.withMargin {
	margin-bottom: 40px;
}

.withMargin .Select__input[type='search'] {
	max-width: 100% !important;
	margin: 0 !important;
}

.integration table {
	margin-top: 20px !important;
}

.integration table td {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.widthMinimize {
	width: 1px;
}

.noWrap {
	white-space: nowrap;
}

.hasLink:hover {
	background-color: #f7fafc;
}

@media (max-width: 600px) {
	.simple {
		overflow-x: scroll !important;
		display: block;
	}
}
