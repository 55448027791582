
@use "../../../../scss/abstract/mixin" as *;


.Analytics__Div{
    padding: 0px 10px;

    @include desktop(){
        padding: 0px 0px;

    }

    .Analytics_rowDirection{
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

      
        .Analytics__Col{
            width: 100% !important;
            padding-right: 12px;
            @include desktop(){
                width: 32.333% !important;
            }

            a{
                text-decoration: none;
                color: var(--eke-color-secondary) !important;
            }
            
            .card {
                margin-bottom: 10px;
                .card-body{
                    padding: 40px 30px 40px 30px !important;
                    position: relative;

                    .Analytics__heading-title{
                        font-size: 16px;
                        color: var(--eke-color-secondary) !important;
                        text-transform: capitalize;
                        font-family: var(--eke-body-font-family);
                        font-weight: var(--eke-font-weight-normal);
                    }
                    
                    .Analytics__heading-count{
                        padding: 0 !important;
                    }

                    span.Analytics__icon{
                        position: absolute;
                        right: 10%;
                        top: 42%;

                        svg{
                            font-size: 35px;
                            color: var(--eke-color-secondary) !important;
                        }
                    }
                }
    
            }
        }

    }
}