.wrapContainer{
    height:600px;
    padding-top: 15rem;
    padding-bottom: 8rem;
    margin-bottom: 2rem;
    vertical-align:middle;
}

.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title{
    text-align: center;
    font-size: 27px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 10px;
    font-family: 'Noto Sans', sans-serif;
}
.subtitle {
    margin-top: 10px;
	margin-bottom: 30px;
    font-family: 'Noto Sans', sans-serif;
}
.children {
    margin-top: 10px;

}
.icon {
	width: 80px;
	margin-bottom: 10px;
	color: #9b9b9b;
}
.logo {
	width: 130px;
	margin-bottom: 30px;
}
.status {
	margin-top: 50px;
}
.content {
	margin-bottom: 30px;
    font-size: 13px !important;
    line-height: 29px;
    font-family: 'Noto Sans', sans-serif;
}
