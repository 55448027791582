

.sort_container_{
    padding-left: 0 !important;
    width: 100%;
    margin-top: 0.7rem;
    
    
    ._row_{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
        margin: 0;

        .sort_first_wrapper{
            display: flex;
            align-items: baseline;
            padding-left: 0 !important;

            h5{
                padding-left: 0;
                font-size: 12.8px !important;
                color: var(--eke-color-secondary);
                font-weight: var(--eke-font-weight-medium);
                margin-right: 0.45rem;
            }

            ._list_items_{
                width: auto;
                background-color: var(--eke-color-white);
                padding: 0.3rem 0;
                border: 1px solid #ddd;
                border-radius: 2px;

                .list_ul_{
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    list-style: none !important;
                    padding: 0 !important;
                    margin: 0 !important;

                    li{
                        padding-left: 0.7rem;
                        padding-right: 0.7rem;
                        border-right: 1px solid #eee;

                        a{
                            font-size: 12.5px !important;
                            color: var( --eke-body-text-gray-color);
                            text-decoration: none;
                            font-family: var(--eke-body-font-family);
                            font-weight: var(--eke-font-weight-normal);

                            &:hover{
                               color: var(--eke-color-primary);
                            }

                        }
                    }
                }
            }

        }


        .sort_second_wrapper{
            display: flex;
            align-items: center;

            span{
                font-size: 13.5px !important;
                color: var(--eke-color-secondary);
                font-family: var(--eke-body-font-family) !important;
                font-weight: 500;
                margin-right: 0.55rem;
            }

            ._layout_changes_{
                display: flex;
                align-items: center;
                gap: 2px;

                
                svg{
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    fill: rgba(35, 47, 62, 0.5)
                    ;
                }
                .active_svg_{
                    fill: var(--eke-color-primary) !important;
                }
            }
        }
    }
}