
.FollowingButton_Wrapper{
    position: relative;
    display: flex;
    align-items: center;

    .Button{
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .FlexTypography{
        display: flex;
        align-items: center;


        .FollowerTypography{
            margin-top: 0.1rem;
            margin-left: 3px;
        }
    }
}