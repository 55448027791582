
.acc-mob-user-container{

    width: 100%;
    //margin-bottom: 9rem;
    //padding-top: 1.1rem;
    padding: 1rem 0rem 0;

    .acc-m-pro-row{
        height: auto;
        padding-bottom: 0rem;
        padding-top: 0px;

        .rv_{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            position: relative;
            align-content: flex-start;
            border: 0 solid #000;
            margin: 0;
            padding: 0;
            min-width: 0;
        }

        .list-group{
            ._display-header{
                margin-bottom: 5px;
                padding: 10px;
              ._wrapper_h5{
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 16rem;
                  margin-bottom: 0 !important;

                  a{
                      font-size: 20px !important;
                      line-height: 1.5;
                      color: $black;
                      @extend .fontFamily700;
                      cursor: pointer;
                      text-decoration: none;

                      &:hover{
                          text-decoration: underline;
                      }
                  }
              }

              p{
                  margin-top: 0 !important;
                  padding-top: 0 !important;
                  margin-bottom: 0 !important;
                  font-size: 11.2px;
                  color: $secondary;
                  @extend .fontFamily400;
                  font-weight: lighter !important;
              }
            }
            .member-login{
                padding: 8px 10px;
                padding-top: 14px;
                flex-direction: row!important;
                //padding: 3.2vw!important;
                background: $white;
                align-items: center;

                .avatar__log_{
                    display: flex;
                    flex: 0 0 12.8vw;
                    clear: both;
                    background-clip: padding-box;
                    background-color: #ffe;
                    overflow: hidden;
                    width: 12.8vw;
                    height: 12.8vw;
                    border-radius: 12.8vw;

                    img{
                        width: 12.8vw;
                        height: 12.8vw;
                        border-radius: 12.8vw;
                        object-fit: cover;
                    }
                }

                .wrap__sign{
                    text-decoration: none;

                    .rv {
                        box-sizing: border-box;
                        display: block;
                        font-size: 4.26667vw;
                        white-space: pre-wrap;
                    }

                    ._signOrRegister{
                        // margin-left: 2.67px!important;
                        // margin-right: 2.67px!important;
                        // padding: 1.33333px 4.8px!important;
                        margin-left: 2.66667vw!important;
                        margin-right: 2.66667vw!important;
                        padding: 1.33333vw 4.8vw!important;
                        background: $primary-light;
                        border-radius: 3.73333vw;

                        ._signOrRegisterText{
                            line-height: 4.26667vw;
                            color: $white;
                            font-size: 12.4px;
                            @extend .fontFamily300;
                        }
                    }

                }
            }

            ._list_itm_group{
                border-bottom:  none;
                padding: 0 10px;
                padding-right: 2px !important;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                background-color: #fff;

            }
        
            .list-group-item{
                background-color: transparent !important;
                border: none !important;
                color: rgba($secondary, $alpha: 0.8) !important;
                transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
                border-radius: 0px !important;
                text-decoration: unset;
                outline-offset: -2px;
                font-size: 13.8px !important;
                line-height: 1.3rem !important;
                color: $black !important;
                padding: .5rem 1rem .4rem 0rem !important;
                text-overflow: ellipsis;
                @extend .fontFamily400;
    
    
                .sD_m_{
                    display: flex;
                    align-items: center;
                    justify-content: space-between !important;
                }
    
                // svg{
                //     width: 5px;
                //     height: 22px;
                //     fill: $secondary;
                //     
                // } 
                .icon__acc_2{
                    width: 1.2rem;
                    vertical-align: -0.175em;
                    display: inline-block;
                    margin-right: 0.5rem;
                    fill: $black;
                    stroke-width: 1 !important;
                }
                .icon__acc_,  .icon__acc_dash{
                    font-size: 1.1rem !important;
                    vertical-align: -0.175em;
                    display: inline-block;
                    margin-right: 0.5rem;
                  }

                  .icon__acc_dash{
                    font-size: 1.2rem !important;
                  }
    
                &:hover{
                   text-decoration: underline;
                    color: $primary-light !important;
                    .icon__acc_2{
                        fill: $primary-light !important;
                    }
                    
                }
    
                
                
            }
    
            .active{ 
                border-left: 3px solid $primary-light !important;
                background-color: rgba(#fff, 0.9) !important;
            }
        }
        } 
}