@use "../../../../scss/abstract/colors.scss" as colors;

.SellerProfile_Container{
    width: 100%;   

    .SellerProfile_Row{

        .SellerProfile_RowLeft{
            padding-top: 20px;
            border-right: 1px solid rgb(235, 234, 234);
            height: 100vh;
            background-color: #f8f8f8f2;
            
            .AvatarImg_Icon{
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .AvatarImg{
                    height: 100%;
                    text-align: center;

                    .Img{
                       width: 50px;
                       height: auto;
                       border-radius: 50%;
                       background-color: colors.$white;
                       padding: 3px;
                    }
                }
            }
            .CollectionConfig__Sec{
                padding-left: 10px;

                .Typography_h6{
                    font-size: 0.9rem !important;
                }


                .outerClass{
                    height: 26px !important;
                    width:54px !important;
                    padding-right: 1rem;
                }

                .innerClass{
                    height: 22px !important;
                    width: 22px !important;
                }

                .activeColor{
                    background-color: colors.$secondary-brand !important;
                }

                .innerPos{
                    transform: translateX(1.95rem) !important;
                }
                
                .CollectionSec{
                    min-height: 100px;

                    .CollectionConfig{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 0.3rem;
                        padding-top: 0.8rem;
                    }
                }
    
                .SellerCollection{
                    .SellerCollection__Child{
                        margin-top: 20px;

                        .SellerCollection__ChildItem{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-right: 0.3rem;
                            margin-bottom: 17px;
                            margin-top: 17px;

                           
                           
                        }
                    }
                }
            }
            
        }

        .SellerProfile_RowRight{

        }
    }
}