.app-modalbody .modal-content{
    border-radius: 16px !important;
    border: 1px solid var(--greyscale-100, #F5F5F5) !important;
    background: var(--others-white, #FFF) !important;
  }


@media (min-width: 576px){
    .app-modalbody  .modal-sm {
        min-width: 539px !important;
    }

    .app-infomodal .modal-sm {
        min-width:  474px !important;
    }

}

.app-modalheader{
    display: flex !important;
}

.event-modalhead{
    border-bottom:  none !important;
    padding-top: 30px !important;
    align-items: flex-start !important; 
    width: 98%;
    margin: 0 auto;
    height: 70px !important;

}

.event-modalhead button{
    margin-top: 8px !important;
}



.app-modalheader .display_lg_header{
    color: var(--greyscale-900, #212121) !important;
    font-family:var(--eke-body-font-family);
    font-size: 24px !important;
    font-style: normal;
    font-weight: var(--eke-font-weight-bold);
    line-height: 120%;
}