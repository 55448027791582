

.features_group_wrap{
    margin-top: 1rem;


        .features_overflow{
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 200px;
        }
        
      
        .list-group{
            
            .list-group-item{
                font-size: 13px !important;
                background-color: transparent !important;
                border: none !important;
                color: var(--eke-color-secondary);
                font-family: var(--eke-font-weight-normal);
                padding-top: 0rem;
                padding-bottom: 0rem;
                transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
                border-radius: 0px;
                text-decoration: unset;
                outline-offset: -2px;
                margin-bottom: 0 !important;
                padding-left: 0;
                font-family: var(--eke-body-font-family);
                text-decoration: none !important;



                &:hover{
                    color: var(--eke-color-primary);
                    text-decoration: underline;
                }

                label{
                    cursor: pointer;
                    color: var(--greyscale-900, #212121) !important;
                    font-family: var(--eke-body-font-family);
                    font-weight: var(--eke-font-weight-medium) !important;
                    font-size: 13px !important;
                    line-height: 140% !important; /* 19.6px */
                    letter-spacing: 0.2px;
                }

                input{
                    cursor: pointer !important;
                margin-top: -1px !important;
                }



            }
        }



}
