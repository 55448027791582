@use "./abstract/colors" as *;
@use "./abstract/fonts" as *;


@import "./base/usebootstrap";

@import "./abstract/util";

// $containerwidth-xl:

// $theme-colors:(
//     "primary": $primary,
//     "secondary": $secondary,
// );

@import "~react-image-gallery/styles/scss/image-gallery.scss";

.zI{
  z-index: -10000;
  position: "relative";
}


html body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}
 body{
     background-color: #F9FAFB !important; // #F8FAFC !important;
    overflow-x:hidden !important;
 } 

 .pac-container {
    z-index: 99999999999 !important;
  }

 .form-control {

  &:focus {
     box-shadow: none;
     outline: none;
  }

}

.bgcolorBlue{
  background-color: #232f3e
}

tbody + thead{
  display: none;
}

.video_wrapper_dashboard{
  height: 100px;
  width: 150px;
}

.image-wrapper-div{
  height: 100px;
}

.search-auto-complete_{
  z-index: 999;
  // height: 300px;
  height: 70vh;
  overflow-y: auto;
}

.picture__thumb{
  width: 40%;
  height: auto;
}

.custom_table_listing tbody tr td:first-child {
  width: 250px !important;
}

.search-auto-complete_canvas{
  z-index: 999;
  height: 600px;
  overflow-y: auto;
}

.list-item{
  cursor: pointer;
}

.list-item:hover{
  background-color: #232f3e;
  color: white;
}

.down_flex{
  // align-items: baseline;
  margin-top: auto !important;
  color: #10B181;
  display: flex;
  align-items: center !important;
}

.followButton{
  background-color: #0A54B1 !important;
  color: white !important;
  padding: 2px;
}

.bold-header{
  color: "#232F3E";
  font-size: "40px";
  font-family: "Roboto";
}

.react-stars span{
  margin-right: 10px;
}

.help-header{
  color: #333333;
  font-weight: 600;

}

.badge-position{
  position: absolute;
  top: 50%;
}

.badge-position-top{
  position: relative;
  top: 1%;
}

.collection_sideBar_kitchen{
  height: 200vh;
}

.my-btn2:hover{
  background-color: #232f3e;
  padding: 3px;
  border-radius: 5px;
  color: white;
  margin-left: 2px;
  transition: .2s;
}

.myflex{
  display: flex;
}

.my-header{
  font-size: 20px;
  color: #232F3E;
}

.my-btn{
  height: 28px;
  font-size: 18px;
  color: #666666;
  border: none;
  font-weight: bolder;
}

.fav_ikitchen svg:hover{
  fill: #E04826;
  stroke: #E04826;
}

input[type="checkbox"].ikitchenCheckbox:checked {
  border-color: red;
  background-color:red;
}

.fav_ikitchen{
  border-color: rebeccapurple;
}

.increment-button{
  color: #E04826;
}

.decrement-button{
  background-color: #F4F4F4 !important;
}

.view-recipe{
  text-decoration: underline;
  color: #E04826;
  font-size: 16px;
  cursor: pointer;
}

.recipe-list{
  list-style-type: square;
}

.recipe-list li::before {
  color: #E04826; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.my-text{
  font-size: 16px;
  color: #232F3E;
}

.ikitchen-link{
  color: #E04826;
  font-size: 15px;
  font-weight: 500;
}
.ikitchen-title{
  color: #232F3E;
  text-decoration: none;
}

.brand-color-text{
  color: #333333;
}
.ikitchen-header{
  font-size: 28px;
  font-weight: 700;
}

.bg-gray{
  background-color: #F4F4F4;
  padding: 5px;
}

.main2{
  padding-top: 4rem;
}

.ikitchen_description {
  white-space: nowrap; 
  width: 50px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  border: 1px solid #000000;
}

.container-kitchen{
  position: relative;
}

.tag {
  float: left;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1000;
  background-color: #92AD40;
  padding: 5px;
  color: #FFFFFF;
  font-weight: bold;
}

.headings_review{
  color: #333333;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

}

.text_p_review_order{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #E04826;
  text-decoration: none !important;
}

.text_p_review_date{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #10B181;
}

  .main--container{
    min-height: 100%;
  }

  // .modal-backdrop{
  //   z-index: 999999 !important;
  // }
  
  
  ._ct_wrapper_{
    font-size: 12px !important;
    padding: 15px 15px 0.1px 0px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .__sub_wrapper{
      display: none;

      @include media-breakpoint-up(lg){
        display: block;
      }
    }

    .breadcrumb-item{
        a{
            text-decoration: none;
            color: var(--eke-color-secondary);
            @extend .fontFamily400;

            
            &:hover{
                text-decoration: underline;
                
            }
        }
    }

    .active_breadcrumb{
        a{
            color: var(--eke-color-dark) !important;
            @extend .fontFamily500;
            cursor: text !important;

            &:hover{
                text-decoration: none !important;
                
            }

        }
      }
 }

 .centered {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreen-modal{
  margin-top: 45px !important;
}

//  .display_lg_header{
//     font-size: 24.5px;
//     line-height: 40px;
//     padding-bottom: 0px;
//     padding-top: 10px;
//     @extend.fontFamily800;

//     @include media-breakpoint-up(lg){
//     font-size: 29.5px;
//     padding-top: 16px;
//     padding-bottom: 16px;


//     }
//  }

//  .display_sm_header{
//   font-size: 15.5px;
//   margin-top: 0.81rem;
//   line-height: 12px;
//   @extend.fontFamily800;


//  }





@import "./abstract/mixins";
@import "base/typography";
@import "base/button";

//@import "components/navigationbar";

//@import "components/bottom-nav";
@import "components/dropdown";
// @import "components/collection-card";
// @import "components/address_form";
//@import "components/button";
@import "components/marketday-card";
//@import "components/videoplayerwithoutcontrols";
//@import "components/_top_home_section";
@import "components/skeleton-loader";
@import "components/horizontal-slider-card";
// @import "components/tag";
//@import "components/modal";
@import "components/custom_tab";
@import "components/offcanvas";
@import "components/_quantity_cartpage_button.scss";
@import "components/search_mobile_offcanvas";
@import "components/collection_section_card";
@import "components/utils";
//@import "components/footer";

//@import "sections/accounts/account-layout"; 
//@import "sections/accounts/account-login"; 
//@import "sections/accounts/account-register"; 
@import "sections/accounts/account-manage-account"; 
@import "sections/accounts/mobile_screen_accountSide"; 
@import "sections/accounts/account-buyer-order"; 
@import "sections/accounts/account-buyer-return-request"; 

@import "sections/livecommerce/all_live_p";
@import "sections/livecommerce/live_product_card";
@import "sections/livecommerce/view_single_live_p";


@import "sections/cart/cart";




@import "sections/dashboard/new_dashboard"; 
@import "sections/dashboard/new/dashboard_custom_mainbar"; 
@import "sections/dashboard/new/dashboard_custom_sidebar"; 


@import "sections/dashboard/dashboard_messageframe"; 
// @import "sections/dashboard/dashboard_mainbar"; 

// @import "sections/dashboard/dashboard_allproducts"; 
@import "sections/dashboard/dashboard_addproduct"; 

//@import "sections/dashboard/dashboard_unauthorized";


@import "sections/dashboard/dashboard_livecommerce"; 
@import "sections/dashboard/_dashboard_ekemarket";

@import "sections/savetheplanet/savetheplanet"; 


