.mb-title{
    font-size: 15px;
    color: #000;
    margin-top: 8px;
    @extend .fontFamily400;
}

.close__dialog{

    .mb-closeDialog-footer{
        .confirm_btn_close{
            background-color: rgb(255, 0, 0) !important;
            color: #fff;
        }
    }
}

.live__streams__frame{
    width: 100%;
    padding-top: 0;

    @include media-breakpoint-up(lg){
        .prompt__dialog{
            width: 400px !important;
        }
    }

   .live__s__f{
       position: relative !important;
       padding-left: 0px !important;
       padding-right: 0px !important;

       @include media-breakpoint-up(lg){
            padding-left: 10px !important;
            padding-right: 10px !important;
       }
   }

    .main__v_stream_col{
        padding-right: 0 !important;
        padding-left: 0 !important;
        min-height: 100vh;

        .vid__stream-section{
            position: relative;
            height: 100%;

                .frame_section{
                    width: 100%;
                    
                    .inner_row{
                        display: flex;
                        align-items: center;
                        padding: 0 !important;

                        .stream_page{
                            flex-grow: 1;
                        }

                        .stream_chat{
                            height: 100vh;
                            display: none;
                            border-left: 1px solid rgba($color: #323e4e, $alpha: .4) ;
                            @extend .shadow-lg;

                            @include media-breakpoint-up(lg){
                                width: 300px;
                                display: flex;
                            }
                        }

                        .mx_QQ{
                            position: relative;
                            height: 100vh;

                            .vid__stream_wrapper_{
                                padding-top: 20px;
                                padding-bottom: 5px !important;
                                background-color: transparent;
                            

                                .vid__stream_navigationBar{
                                    //position: absolute;
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    justify-content: space-between;
                                    padding-right: 20px;
                                    z-index: 9999;

                                    border-bottom: 0 ;
                                    @extend .shadow-lg;

                                    @include media-breakpoint-up(lg){
                                        border-bottom: 1px solid rgba($color: #323e4e, $alpha: .4) ;

                                    }



                                    .vid__stream_brand{
                                        display: flex;
                                        align-items: center;
                                        position: relative;

                                        .d-liveicon{
                                            position: absolute;
                                            right: -9%;
                                            top: -50%;
                                        }
                                    }

                                    .vid__stream_right_container{
                                        
                                        @include  ShareButtonStyle;
                                        
                                        .close-live-stream{
                                            display: flex;
                                            align-items: center;
                                            background-color: rgb(255, 0, 0);
                                            border-radius: 8px;
                                            box-shadow: rgba(187, 44, 44, 0.2) 0 -25px 18px -14px inset,rgba(187, 44, 44, 0.15) 0 1px 2px,rgba(187, 44, 44, 0.15) 0 2px 4px,rgba(187, 44, 44, 0.15) 0 4px 8px,rgba(187, 44, 44, 0.15) 0 8px 16px,rgba(173, 49, 49, 0.15) 0 16px 32px;
                                            color: #fff;
                                            cursor: pointer;
                                            display: inline-block;
                                            font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
                                            padding: 7px 9px;
                                            text-align: center;
                                            text-decoration: none;
                                            transition: all 250ms;
                                            border: 0;
                                            font-size: 11px;
                                            user-select: none;
                                            -webkit-user-select: none;
                                            touch-action: manipulation;
                                            text-align: center;
                                            gap: 2rem;

                                                &:hover {
                                                box-shadow: rgba(187, 51, 44, 0.35) 0 -25px 18px -14px inset,rgba(187, 61, 44, 0.25) 0 1px 2px,rgba(187, 65, 44, 0.25) 0 2px 4px,rgba(187, 51, 44, 0.25) 0 4px 8px,rgba(187, 44, 44, 0.25) 0 8px 16px,rgba(187, 51, 44, 0.25) 0 16px 32px;
                                                transform: scale(1.05) rotate(-1deg);
                                                }

                                                svg{
                                                    margin-bottom: 0.2rem;
                                                    margin-right: 5px;
                                                }


                                                @include media-breakpoint-up(lg){
                                                    padding: 7px 15px;
                                                    font-size: 14px;

            
                                                }
            
                                            }
                                            
                                        }


                                }

                                .vid__stream_container{
                                    display: flex;
                                    align-items: flex-start;
                                    width: 100%;
                                    min-height: 600px;
                                    height:90vh;
                                    border-radius: 8px;
                                     padding-top: 0px;
                                     padding-right: 0px;

                                     @include media-breakpoint-up(lg){
                                        padding-top: 20px;
                                        padding-right: 20px;
                                        height:88vh;


                                    }

                               


                                    .vid_list_product_container{
                                        width: 180px;
                                        display: none;
                                        min-height: 600px;
                                        height: 630px;
                                        padding: 10px;
                                        padding-right: 0;
                                        padding-top: 0;

                                        @media (min-width: 1200px) { 
                                            display: block;
                                        }

                                        

                                        .vid__h5{
                                            font-size: 13px;
                                            padding-bottom: 2px;
                                            color: rgba($color: #ddd, $alpha: .7);
                                            text-transform: uppercase;
                                            letter-spacing: 0.8px;
                                            margin: 0;
                                            @extend.fontFamily800;
                                        }

                                        .vid_product_contain{

                                            .vid__li_{
                                                list-style-type: none;
                                                padding: 0.2rem 0;
                                                padding-right: 1rem;
                                                width: auto;

                                                .active--3QLNx {
                                                    border: 1px solid $primary-light;
                                                    box-shadow: none;
                                                }

                                                button{
                                                    width: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    flex-direction: column;
                                                    padding: 7px 5px;
                                                    cursor: pointer;
                                                    position: relative;
                                                    overflow: hidden;
                                                    background-color: #fff;
                                                    text-align: left;
                                                    border-radius: 15px;
                                                    transition: .3s ease-in-out;

                                                    .vid__image_prod{
                                                        max-width: 100px;
                                                        height: 120px;
                                                        background-color: #eee;
            
                                                        img{
                                                            width: 100%;
                                                            height: 100%;
                                                            border-radius: 5px;
                                                        }
                                                    }

                                                    .vid__product_col{

                                                        .title_Qts{
                                                            width: 100%!important;
                                                            display:flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            display: -webkit-box;
                                                            text-decoration: none;
                                                            font-size: 14px !important;
                                                            color: #000;
                                                            padding-bottom: 7.55px;
                                                            padding-left: 0.5rem;
                                                            max-width: 100%;
                                                            height: 43px; /* Fallback for non-webkit */
                                                            margin-bottom: 0.1rem;
                                                            line-height: 1.4;
                                                            -webkit-line-clamp: 2; 
                                                            -webkit-box-orient: vertical;
                                                            overflow: hidden !important;
                                                            text-overflow: ellipsis;
                                                            margin-top: 0.2rem; 
                                                            transition: .3s ease-in-out;
                                                            @extend .fontFamily400;
                                                        }
                                                    }

                                                    &:hover{
                                                        border: 3px solid $primary-light;
                                                        .title_Qts{
                                                            opacity: .7;
                                                        }

                                                    }

                                                }
                                            }
                                        }
                                    }

                                    .video__player__s3{
                                        width: 100% !important;
                                        height: 100% !important;

                                        .iframe-stream-div{
                                            position: relative;
                                            overflow: hidden;
                                            width: 100% !important;
                                            height: 89.55vh !important;
                                            background-color: rgba($black, $alpha: .3);

                                            .live-stream-frame{
                                                width: 97% !important;
                                                height: 100% !important;
                                                border-radius: 8px;
                                                border-radius: 8px;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                height: 100%;

                                                @include media-breakpoint-up(lg){
                                                width: 100% !important;
                                                }
    
                                            }

                                            iframe.webform-frame {
                                               .bubble-element{
                                                 background-color: pink !important;
                                               }
                                             }

                                             .iframe-stream-cover_blank{
                                                position: absolute;
                                                display: none;
                                                left: 32.4%;
                                                bottom: 0%;
                                                width: 20%;
                                                background-color: #121a24;
                                                height: 54px;

                                                @include media-breakpoint-up(lg){
                                                   display: block;
                                                }
                                             }
                                        }
                                        
                                        video{
                                            width: 100% !important;
                                            height: 100% !important;
                                            object-fit: cover;
                                            background-position: center;
                                            background-size: cover;
                                            -webkit-transform: scaleX(-1);
                                            transform: scaleX(-1);
                                            transition: all .3s ease-in-out;
                                        }
                                    }
                    
    
                                }

                            }

                            .vid_controller_div{
                                //border-top: 1px solid rgba($color: #323e4e, $alpha: .4) ;
                                //padding:5px 20px 10px 20px;
                                //min-height: 0px;

                                .list__controller_parent{
                                    list-style: none;
                                    display: flex;
                                    width: 48%;
                                    margin: 0 auto;
                                    align-items: center;
                                    justify-content: space-evenly;
                                    padding-top: 10px;


                                 

                                    .list__controller_child{
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 50%; 
                                        text-align: center;
                                        cursor: pointer;
                                        transition: all .3s ease-in-out;
                                        @extend .shadow-sm;

                                        .default_b {
                                            background: rgba($color: #323e4e, $alpha: .2);
                                            border: 1px solid rgba($color: #323e4e, $alpha: .4);
    
                                            &:hover {
                                                background-color: rgba($secondary, $alpha: .6);
                                                border: 1px solid rgba($color: #323e4e, $alpha: .8);
                                            }
                                        }
    
                                        .disabled_bg{
                                            background-color: #ea4335 !important;
                                            border: 0;
                                        }
                                    
                                        .icon__span{
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 100%;
                                            height: 100%;
                                            color: rgba($color: #fff, $alpha: .8);
                                            padding: 8px 0px 8px 0px;
                                            border-radius: 50%; 
                                            user-select: none;
                                            -webkit-user-select: none;

                                            svg{
                                                font-size: 20px;
                                            }
                                        }

                                        &:hover{

                                            .icon__span{
                                                color: rgba($color: #fff, $alpha: 1);


                                            }
                                        }
                                    }


                                    .list__controller_button{

                                        .endButton{
                                            background-color: rgb(255, 0, 0);
                                            border-radius: 50%;
                                            box-shadow: rgba(187, 44, 44, 0.2) 0 -25px 18px -14px inset,rgba(187, 44, 44, 0.15) 0 1px 2px,rgba(187, 44, 44, 0.15) 0 2px 4px,rgba(187, 44, 44, 0.15) 0 4px 8px,rgba(187, 44, 44, 0.15) 0 8px 16px,rgba(173, 49, 49, 0.15) 0 16px 32px;
                                            color: #fff;
                                            cursor: pointer;
                                            display: inline-block;
                                            font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
                                            padding: 15px 15px;
                                            text-align: center;
                                            text-decoration: none;
                                            transition: all 250ms;
                                            border: 0;
                                            font-size: 14px;
                                            user-select: none;
                                            -webkit-user-select: none;
                                            touch-action: manipulation;
                                            text-align: center;

                                                &:hover {
                                                box-shadow: rgba(187, 51, 44, 0.35) 0 -25px 18px -14px inset,rgba(187, 61, 44, 0.25) 0 1px 2px,rgba(187, 65, 44, 0.25) 0 2px 4px,rgba(187, 51, 44, 0.25) 0 4px 8px,rgba(187, 44, 44, 0.25) 0 8px 16px,rgba(187, 51, 44, 0.25) 0 16px 32px;
                                                transform: scale(1.05) rotate(-1deg);
                                                }
                                            }

                                        }
                                    }

                                }
                            
                        }

                        .mr_QQ{
                           
                            
                        }
                    }

                }


            .msg__main__div{

                .msges__list__r{
                    position: absolute;
                    display: inline-block;
                    bottom: 120px;
                    left: 16px;
                    z-index: 1;

                    .messaging__stream__WRAPPER{
                        width: 300px !important;
                        max-height: 250px;
                        padding-top: 8px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        box-sizing: content-box;

                        &::-webkit-scrollbar {
                            width: 0px;
                            height: 0;
                            background: transparent; 
                        }
                        &::-ms-scrollbar {
                            width: 0px;
                            height: 0;
                        }
                        &::-o-scrollbar {
                            width: 0px;
                            height: 0;
                        }
                        &::-webkit-scrollbar {
                            width: 0;
                            height: 0;
                        }

                        .messaging__comment__list{
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;

                            .messaging__display__section{
                                //padding: 8px;
                                //background: rgba(34,34,34,.6);
                                margin-top: 4px;

                                .seller_identity__text{
                                    backdrop-filter: blur(5px) saturate(180%) !important;
                                    -webkit-backdrop-filter: blur(15px) saturate(180%) !important;
                                    //border: 1px solid #333;

                                    .msg__u__info{
                                        color: rgb(236, 157, 108) !important;
                                    }
                                }

                                .single__text_msg_{
                                     border-radius: 4px;
                                     padding: 8px;
                                     margin-bottom: 0.5rem;
                                      backdrop-filter: blur(5px) saturate(180%);
                                      -webkit-backdrop-filter: blur(15px) saturate(180%);
                                      background-color: rgba(0, 0, 0, 0.20);

                                    .msg__u__info{
                                        font-size: 12.3px;
                                        font-weight: 400;
                                        color: rgb(195, 191, 191);
                                        display: block;
                                        margin-bottom: 0.2rem;
                                        @extend .fontFamily500;

                                    }

                                    .msg__txt{ 
                                        font-size: 12.1px;
                                        line-height: 15.5555px;
                                        color: rgba($white, $alpha: 1);
                                        display: block;
                                        max-width: 100%;
                                        height: auto; /* Fallback for non-webkit */
                                       // -webkit-line-clamp: 2; 
                                        -webkit-box-orient: vertical;
                                        @extend .fontFamily400;
                                    }
                                }

                            }
                        }
                    }
                }

                .messaging__bottom{
                    bottom: 21% !important;
                    right: 5% !important;
                }

                .messaging__stream__div{
                    position: absolute;
                    display: flex; 
                    align-items: stretch;
                    left: 10px; 
                    right: 10px;
                    bottom: 8px;
                    //height: 36.5px;
                    height: auto;
                    max-height: 40.555px;
                    z-index: 1;
                    backdrop-filter: blur(5px) saturate(180%);
                    -webkit-backdrop-filter: blur(15px) saturate(180%);


                    .form__container_div{
                        display: flex;
                        width: 100%;
                        height: 100%;
                        
                        form{flex-grow: 1;}

                        .form-groups{
                            display: flex;
                            align-items: center;
                            flex-grow: 1;
                            width: 100%;
                            height: 100%;

                            .input_group{
                                position: relative;
                                border-radius: 4px !important;
                                width: 100%;

                                @include media-breakpoint-up(lg){
                                    width: 95% !important;
                                }
                    
                                @include media-breakpoint-up(xl){
                                    width: 95% !important;
                                }

                                .word__counter__input{
                                    position: absolute;
                                    right: 10px;
                                    bottom: 7.555px;
                                    font-size: 12.5px;
                                    font-weight: 400;
                                    color: rgba($white, $alpha: .4) !important;

                                }
                    
                                
                                .form-control{
                                    border: none;
                                    padding: 7.5999px 10px !important;
                                    padding-right: 60.5555px !important;
                                    font-size: 14px;
                                    font-weight: 400;
                                    border-radius: 0px !important;
                                    border-bottom-left-radius: 4px !important;
                                    border-top-left-radius: 4px !important;
                                    box-shadow: none !important;
                                    background-color: #6b859d6d;
                                    color: #fff;

                                    //background-color: rgba(0, 0, 0, 0.30) !important;

                                    &:focus{
                                        color: rgba($white, $alpha: .8) !important;
                                    }

                                    &::placeholder{
                                        color: rgba($white, $alpha: .8) !important;
                                        font-weight: 300;
                                    }

                                    
                                }

                                .is-invalid{
                                    background-image: none !important;
                                    background-color: #F87171;

                                }


                            }
                            button{
                                box-shadow: none !important;
                                padding: 5.09999px 9.55px !important;  
                                font-size: 14px;
                                font-weight: 400;     
                                background-color: $primary-light;       
                                color: #fff;
                                border-radius: 0px !important;
                                border-bottom-right-radius: 4px !important;
                                border-top-right-radius: 4px !important;
                                @extend .fontFamily400;

                                .send__icon_svg{
                                    fill: #fff ;
                                }
                            }
                        }

                        .message__other__option{
                            display: flex;
                            align-items: center;
                            margin-left: 10px;
                            gap: 2px;

                            @include ShareButtonStyle;

                            @include media-breakpoint-up(lg){
                                display: none;
                            }
                        }
                    }

                }
            }

        }
    }
}