@use "../../scss/abstract/mixin" as mixin;

.Documentation__main{
    padding-top: 0.8rem;
    margin: 0 auto;

    .Documentation__Container{
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;


        .PageTitle {
            padding-left: 14px !important;
            padding-bottom: 10px !important;

            h1{
                font-size: 24px !important;
            }

            @include mixin.laptop(){
                padding-left: 20px !important;
                padding-bottom: 0 !important;
            h1{
                font-size: 29.5px !important;
            }
            }
        }
      

        @include mixin.laptop(){
            max-width: 54.8rem !important;
            padding-right: 2rem;
            padding-left: 2rem;
        }

        
        @include mixin.desktop(){
            max-width: 54.8rem !important;
            padding-right: 2.4rem;
            padding-left: 2.4rem;
        }

        .Documentation__Intro{
            p{
                margin-bottom: 0.4rem !important;
            }
        }

        .MarginTop{
            margin-top: 2.8rem;
        }

        .Highlight_Section{
            border: 1px solid #d1d7dc;
            margin: 0.8rem 0 1.6rem;
            padding: 1.6rem;

            p{
                font-style: italic;
            }
        }

        .Documentation_Heading{
            font-weight: 550 !important;
            
        }

        .Documentation__Heading{
            font-weight: 550 !important;
            font-size: 23px !important;

        }

        .Documentation_Para{
            line-height: 21px;
        }

        .DocList{
            list-style: none;
            padding-left: 0 !important;
            padding-top: 4px;
            padding-bottom: 10px;

            &__Heading{
                margin-top: 1.5rem !important;
                margin-bottom: 1.5rem !important;
                line-height: 1.2;
                letter-spacing: -.02rem;
                font-size: 20.5px !important;
            }
        }
    }
}

.TableContent{
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 100%;

    @include mixin.tablet(){
        width: 90%;
            
        }

    @include mixin.laptop(){
    width: 60%;
        
    }

    .TableContent_Heading{
        line-height: 1.2;
        letter-spacing: -.02rem;
        font-size: 20.5px !important;
    }

    &__List{
     margin: 0;

     li{
        line-height: 25px;;
     }

     a{
        text-decoration: none;
        cursor: pointer;
     }
    }

    &__Item{
    }

    }
