.nav-tabs{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;

    .nav-link.active{
        color: var(--eke-body-text-primary-color) !important;
        background-color: transparent !important;
        border: none !important;
        border-bottom: 3px solid var(--eke-border-primary-color) !important;
        font-family: var(--eke-body-font-family) !important;

    }
    .nav-item{

        button{
            padding-top: 15px !important;
            padding-bottom: 15px !important;
        }

        .nav-link {
            min-width: 150px;
           font-size: 13.5px !important;
           color: var(--eke-body-text-secondary-color);
           border-top: 0px !important;
           border-left: 0px !important;
           border-right: 0px !important;
           padding-bottom: 1rem;
           font-weight: var(--eke-font-weight-normal) !important;
           font-family: var(--eke-body-font-family);

        }
       
    }
  
}

