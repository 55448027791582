.offcanvas-backdrop {
    z-index: 1040 !important;
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 350px !important;

    @include media-breakpoint-down(md){
        width: 300px !important;
    }
}


.offcanvas{
    z-index: 99999 !important;
    // background-color: #fff;//#EAF1FB !important;
    // background: rgba( 255, 255, 255, .8 );
    box-shadow: 0 2px 2px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 6px );
    -webkit-backdrop-filter: blur( 5px );
    overflow: hidden;
    
    .offcanvas-header{
        padding: 0.5rem 1rem !important;
        padding-left: 0.455rem !important;

    //    background-color: rgba($secondary, $alpha: 1) !important;
        color: #fff !important;

        .btn-close{
            color: #fff !important
        }

        @include media-breakpoint-down(md){

            .offcanvas-title{
                font-size: 16px !important;
            }
       

        }
        @include media-breakpoint-up(lg){
            padding: 0.5rem 1rem !important;
            padding-left: 1rem !important;
        }
    }

   
    .offcanvas_bd{
        width: 100%;
        display: block;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        padding: 0 10px;
        background-color: white;

        @include media-breakpoint-down(md){
            padding: 0 0px !important;
        }
        
        .list__general_ug{
            transform: translateX(2%);
            overflow: hidden;
            transition: height 500ms ease;
        }

        .m_collection_title{
            display: block;
            width: 100%;
            border-top: 1px solid #d1d7dc;
            padding: 10px 10px 0 10px;
            margin-bottom: -10px;

            a{
                text-decoration: none;
                color: #6a6f73;
                @extend .fontFamily600;
            }
        }

        .listgroup__container_inner{
           position: absolute;
           top:0%;
           left: 0%;

           @include media-breakpoint-up(lg){
             top:7% !important;
           }
        }

        .menu-pq-enter{
            transform: translateX(-110%);
        }
        .menu-pq-enter-active{
            transform: translateX(0%);
            transition: all 500ms ease;
        }
        .menu-pq-exit{
            transform: translateX(0%);
        }

        .menu-pq-exit-active{
            transform: translateX(-110%);
        }
      
        

        .menu-inner-enter{
            transform: translateX(110%);
        }
        
        .menu-inner-enter-active{
            transform: translateX(0%);
            transition: all 500ms ease;
        }
        
        .menu-inner-exit{
            transform: translateX(-110%);
        }
        
        .menu-inner-exit-active{
            transform: translateX(110%);
            transition: all 500ms ease;

        }


        .listgroup__container,.listgroup__container_inner{
            width: 100%;
            padding-right: 0.5rem;

            .top__bar_return{
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0.85rem 0.2rem  0.85rem 1.0rem;
                border-bottom: 1px solid #ddd;
                cursor: pointer;
                background-color: #fff;

                svg{
                    color: rgb(0, 0, 0,.5)!important;
                }
                .main__menu_back{
                    font-size: 13.5px;
                    font-weight: 600;
                    text-transform: uppercase;
                    padding-left: 0.5rem;
                    font-family: $fontfamily;
                }
            }
            
            .list-group{
                padding-bottom: 1rem;
                padding-left: 0.3rem;
                border-radius: 0 !important;
                border-bottom: 1px solid #eee;

                @include media-breakpoint-down(md){
                    padding-left: 0.2rem;
                }
        
                
                ._header__group{
                    align-items: flex-start;
                    padding: 1rem;
                    padding-top: 1rem !important;
                    padding-bottom: 0rem !important;

                    @include media-breakpoint-down(md){
                        padding: 0rem;
                    }
                    
                    .icon__wrapper{
                        width: 32px;
                        height: auto;
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 50%;
                        background: #f7f7f7;
                        overflow: hidden;
                        
                        img{
                            border-radius: 50%;
                            width: 32px;
                            height: 100%;
                        }
                        
                    }
                    
                    .collection___title{
                        width: 100%;
                        display: block;

                       
                        h4{
                            font-size: 16px;
                            font-weight: bold !important;
                            font-family: var(--eke-body-font-family);
                            margin-top: 0.4rem;
                            margin-left: 0.5rem;

                            a{
                                text-decoration: none !important;
                                color: inherit !important;
                                display: block;
                            }
                        }
                    }

                }

            .group__subcollection_list{
                display: flex;
                align-items: center;
            }
             .list-group-item{
                 padding-left: 1.5rem;
                 background-color: transparent !important;
                 border: 1px solid transparent;
                 box-sizing: content-box;
                 cursor: pointer;

                 @include media-breakpoint-down(md){
                    padding-left: 0rem;
                }
                

            
                 .icon__right{
                    color: rgb(185, 185, 185) !important;
                 }
                 
                 .sub_collection{
                     display: flex;
                     align-items: center;

                     .icon__wrapper{
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #f7f7f7;
                        //overflow: hidden;
                        
                    }
                     span{
                         font-size: 13.8px;
                         font-weight: 499;
                         color: $secondary;
                         font-family: $fontfamily;
                     }
                 }

               &:hover{
                   -webkit-box-shadow: -2px 1px 5px #ddd;
                   box-shadow: -2px 1px 5px #ddd;
                   border: 1px solid #ddd;
               }
                
             }

            }
        }


        .off_profile{
            display: block;

            @include media-breakpoint-up(lg){
                display: none !important;
            }
            


            .off_profile_ul{
                list-style: none;
                margin: 0;
                padding: 0;
                max-width: none;
                
                li{
                    button, [type=button] {
                        -webkit-appearance: none !important;
                        border: none !important;
                    }
                    .lite-btn.mobile-nav--nav-item--1hfFJ{
                        display: flex;
                        align-items: center;
                        background: #f7f9fa;
                        padding-left: 0.7rem;
                        padding-right: 1.6rem;
                        padding-top: 0.7rem;
                        padding-bottom: 0.7rem;
                        color: #1c1d1f;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        letter-spacing: normal;
                        white-space: normal;
                        text-decoration: none !important;

                        .lite-block-list-item-content{
                            min-height: 2.24rem;
                            flex: 1;
                            min-width: 1px;

                            .mobile-nav--profile-section-content---4ow{
                                display: flex;
                                align-items: center;
                                text-decoration: none !important;


                             
                                .lite-avatar.lite-avatar-image{
                                    background: $primary-light;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                    object-fit: cover;
                                    color: #fff;
                                    font-size: 30px;
                                    @extend .fontFamily800;


                                }

                                .lite-heading-md{
                                    display: block;
                                    margin: 0 0.6rem 0 1.0rem;
                                    word-break: break-word;
                                    line-height: 1.2;
                                    letter-spacing: -.02rem;
                                    @extend .fontFamily700;
                                }

                                .lite-text-sm{
                                    display: block;
                                    margin: 0.2rem 0.6rem 0 1.0rem;
                                    word-break: break-word;
                                    line-height: 1.2;
                                    font-size: 13px;
                                    @extend .fontFamily400;

                                }
                            }
                        }
                    }

                    .switch_user_link{
                        display: flex;
                        align-items: center;
                        padding-left: 1.4rem;
                        padding-right: 1.6rem;
                        padding-top: 1.0rem;
                        padding-bottom: 1.0rem;
                        color: #1c1d1f;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        letter-spacing: normal;
                        white-space: normal;
                        min-height: 2.24rem;
                        text-decoration: none;
                        font-size: 14px;
                        @extend .fontFamily500;

                        .store__icon{
                            width: 18px;
                            height: 18px;
                            margin-right: 0.2rem;
                            stroke-width: 4px;
                        }

                        .icon__acc_dash{
                            margin-top: 0.1rem;
                            margin-right: 0.2rem;

                        }

                    }
                }
            }
        }

       

    }
}