html {
  font-feature-settings: normal;
}

head title {
  text-transform: lowercase !important;
}

:root,
[data-theme='default'] {
  --eke-color-bg: #{$color-blue-light};
  --eke-bg-primary-color: #{$primary};
  --eke-bg-primary-light-color: #{$primary-light};

  //primary
  --eke-color-primary: #{$primary};
  --eke-color-primary-400: #{$primary-400};
  --eke-color-primary-300: #{$primary-300};
  --eke-color-primary-200: #{$primary-200};
  --eke-color-primary-100: #{$primary-100};

  //secondary
  --eke-color-secondary: #{$secondary};
  --eke-color-secondary-400: #{$secondary-400};
  --eke-color-secondary-300: #{$secondary-300};
  --eke-color-secondary-200: #{$secondary-200};
  --eke-color-secondary-100: #{$secondary-100};

  --eke-color-greyscale: #{$greyscale};

  //other colors
  --eke-color-success-500: #{$color-success-500};
  --eke-color-success: #{$color-success};
  --eke-color-error: #{$color-error};
  --eke-color-info: #{$color-info};
  --eke-color-warning: #{$color-warning};
  --eke-color-disabled: #{$color-disabled};
  --eke-color-disabled-btn: #{$color-disabled-btn};
  --eke-color-white: #{$color-white};
  --eke-color-dark: #{$color-black};
  --eke-color-red: #{$color-red};

  --eke-bg-secondary-color: #{$secondary};
  --eke-bg-secondary-light-color: #{$secondary-light};
  --eke-bg-light-color: #{$white};
  --eke-bg-white-dark-color: #{$white-dark};
  --eke-bg-dark-color: #{$black};
  --eke-bg-danger-color: #{$red};
  --eke-bg-success-color: #{$green};

  --eke-body-text-light-color: #{$white};
  --eke-body-text-dark-color: #{$black};
  --eke-body-text-secondary-color: #{$secondary};
  --eke-body-text-primary-color: #{$primary};
  --eke-body-text-primary-light-color: #{$primary-light};
  --eke-body-text-danger-color: #{$red};
  --eke-body-text-success-color: #{$green};
  --eke-body-text-gray-color: #{$gray-neural};
  --eke-body-text-gray-dark-color: #{$gray-dark};

  --eke-btn-light-color: #{$white};
  --eke-btn-primary-bg: #{$primary};
  --eke-btn-primary-bg-hover: #{$primary-light};

  --eke-btn-secondary-color: #{$black};
  --eke-btn-secondary-bg: #{$secondary};
  --eke-btn-success-bg: #{$green};
  --eke-btn-danger-bg: #{$red};

  --eke-border-primary-color: #{$primary};
  --eke-border-primary-light-color: #{$primary-light};
  --eke-border-secondary-color: #{$secondary};
  --eke-border-secondary-light-color: #{$secondary-light};
  --eke-border-white-dark-color: #{$white-dark};
  --eke-border-white-gray-color: #{$white-grey};

  --eke-border-danger-color: #{$red-dark};
  --eke-border-success-color: #{$green-dark};

  --eke-form-color: #{$secondary};
  --eke-form-bg: #{$white};
  --eke-form-error-color: #{$red};
  --eke-form-error-border: #{$red};
  --eke-form-border-focus-color: #{$primary};

  //font

  --eke-body-font-family: #{$font};
  // --eke-body-font-size: #{$fs-base};
  --eke-font-weight-thin: #{$fw-thin};
  --eke-font-weight-extralight: #{$fw-extralight};
  --eke-font-weight-light: #{$fw-light};
  --eke-font-weight-normal: #{$fw-normal};
  --eke-font-weight-medium: #{$fw-medium};
  --eke-font-weight-semibold: #{$fw-semibold};
  --eke-font-weight-bold: #{$fw-bold};
  --eke-font-weight-extrabold: #{$fw-extrabold};
  --eke-font-weight-deep: #{$fw-black};

  --eke-opacity-md: #{$alpha-md};

  --eke-container-width: #{$containerwidth};
  --eke-container-maxWidth: #{$maxWidth};
}

body {
    font-family: 'Urbanist', sans-serif !important;
  background-color: var(--eke-color-bg) !important;

  .react-tiny-popover-container {
    z-index: 9999 !important;
  }
}
