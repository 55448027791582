@use "../../../../../scss/abstract/mixin" as mixin;


.SingleProd_group_item{
    position: relative;
    width: 100%;
    border: none !important;
    padding: 11px 2px !important;
    margin-top: 0rem;
    margin-bottom: 0rem;
    border-bottom: 1px solid var(--eke-border-white-gray-color)!important;
    
  

    .card{
        border: none !important;

        .group-item{
            position: relative;
            width: 250px;;

            @include mixin.laptop(){
            width: 100%;
            }

        }
    
        .card-body{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-right: 1px;
            display: flex;
            align-items: flex-start;
            gap: 10px; 

            a{
                
                text-decoration:  none !important;
            }

            .cart_sys_div{
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 9 !important; 

                .group--components{
                    display: flex;
        
                    .quantity--picker{
                        display: none;
                    }
        
                    .action_round_btn{
                        width: 36px;
                        height: 36px;
                        font-size: 15px;
                        border-radius: 50%;
                        text-align: center;
                        color: var(--eke-body-text-light-color);
                        border: none !important;
                        font-weight: 600 !important;
                        background-color: var(--eke-bg-primary-color) !important;
                        box-shadow: 0px 10px 10px rgba(0,0,0, 0.1);
                        transition: all 0.3s ease-out;
            
                        &:hover{
                            background-color: var(--eke-bg-primary-light-color) !important;
                        }
                    }
        
                    &:hover{
                        .action_round_btn{display: none;}
                        .quantity--picker{display: block;}
                    }
                }
            }

            .image_u{
                width: 90px !important;
                height: 90px !important;
                display: inline-block;
    
                img{
                    border-radius: 10px;
                    width: 90px !important;
                    height: 100% !important;
                    object-fit: cover;
    
                }
            }

            .content-section{
                position: relative;
                width: 100%;
                height: 100px;

                .title-h5{
                    display:block;
                    display: -webkit-box;
                    color: var(--eke-body-text-secondary-color) !important;
                    margin-bottom: 0.2rem;
                    max-width: 100%;
                    height: auto; /* Fallback for non-webkit */
                    line-height: 1.4;
                    -webkit-line-clamp: 2; 
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 13.5px !important;
                    font-family: var(--eke-body-font-family);
                    font-weight: var( --eke-font-weight-medium) !important;
    
                    &:hover{
                        color: var(--eke-body-text-primary-color) !important;
                        text-decoration: none !important;
                    }
                }
    
                .card-bottom-group{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    bottom: 0;
                    width: 100%;
    
                    .cart_listing_price{
                        display: flex;
                        flex-direction: column;

                        .actual_price_Span{
                            color: var(--eke-body-text-secondary-color) !important;
                            font-size: 16.55px !important;
                            font-family: var(--eke-body-font-family);
                            font-weight: var(--eke-font-weight-bold);
                        }
    
                        .span__body{
                            font-size: 13.5px !important;
                            color: var(--eke-body-text-secondary-color);
                            opacity: .6;
                            font-weight: normal !important;
                            text-decoration: line-through;
                            font-family: var(--eke-body-font-family);
                            font-weight: (--eke-font-weight-light);
                        }
                    }

                  
                }
            }

           
        }

    }
}