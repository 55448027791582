.row__col__div{
    width: 100%;
    min-height: 50vh;
    @include media-breakpoint-up(lg){
        .col-lg-3{
            width: 20% !important;
        }
    }

    .col__cardb-wrapper{
        padding: 6px;
        box-sizing: border-box;


        .card{
            border-radius: 8px;
            overflow: hidden;
            //height: 200px !important;
            text-decoration: none !important;
            border: none !important;
            @include boxShadow;

            .card-body {
                background-color: #ddd;
                padding: 0;

                .image__wrapper{
                    width: 100%;
                    min-height:150px;
                    //height: 40%;
                    
                    img{
                        width: 100%;
                        height: 150px;
                        object-fit: cover;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        border-bottom-left-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;
                        
                    }
                }

                .desc__lives-tream{
                    // position:absolute;
                    // bottom: 0;
                    // left: 0;
                    // right: 0;
                    width: 100%;
                    margin-top: -1.2px;
                    line-height: 0;
                    position: relative;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    overflow: hidden;
                    height: 65px;
                    background-position: center center;
                    
                    .col-absolute{
                        width: 100%;
                        height: 100%;
                        padding: 10px 16px 12px 16px;
                        backdrop-filter: blur(20px);
                        -webkit-backdrop-filter: blur(20px);
                        background-color: rgba(255,255,255,.63);
                        //background-color: rgba(253,198,78, 0.28);
                        box-shadow: rgba(0, 0, 0, 0.10) 0px 2px 4px;
                        //border: 0px rgba(255,255,255,0.4) solid;
                       border: none !important;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;


                        h5{
                            display:block;
                            display: -webkit-box;
                            font-size: 15px;
                            margin-bottom: 11px;
                            color: $secondary;
                            height: 30px; /* Fallback for non-webkit */
                            line-height: 1.5;
                            text-transform: capitalize;
                            -webkit-line-clamp: 2; 
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @extend .fontFamily600;

                        }
                        
                    }
                }
            }
        }
    }

}