@use "../../scss/abstract/mixin" as *;

.ReelsVideo {
  height: 25rem;
  width: 100%;
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.span__new {
    height: 20px;
    font-size: 13px !important;
    color: var(--eke-body-text-light-color);
    background-image: linear-gradient(270deg, #364353, #232F3E);
    border-radius: 6px;
    padding: 1.5px 6px;
    font-weight: var(--eke-font-weight-medium);
    font-family: var(--eke-body-font-family);
}


.card-body {
  padding-bottom: 1rem;
  padding-left: 12px !important;
  padding-right: 12px !important;

  .card-h5-title {
    width: 100% !important;
    display: block;
    display: -webkit-box;
    text-decoration: none;
    text-transform: capitalize;
    color: var(--eke-body-text-secondary-color) !important;
    padding-bottom: 0.2rem;
    max-width: 100%;
    height: 20px; /* Fallback for non-webkit */
    margin-bottom: 0.3rem;
    line-height: 1.5;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14.5px !important;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-medium) !important;

    &:hover {
      color: var(--eke-body-text-primary-color) !important;
      text-decoration: none !important;
    }
  }

  .card-text {
    margin-bottom: 0rem !important;

    .wrap__t__group {
      display: flex;
      align-items: baseline;
      padding-top: 0.255rem;

      .card-image-wrapper {
        width: 0px;
        height: 40px;
        //background-color: #eee !important;
        //padding: 0.1rem;

        //    @include media-breakpoint-up(lg){
        //         width: 60px;
        //         height: 40px;
        //     }
        //    @include media-breakpoint-up(xl){
        //         width: 60px;
        //         height: 40px;
        //     }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      ._main_price_ {
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 18.55px !important;
          color: var(--eke-body-text-secondary-color);
          margin-bottom: 0rem !important;
          margin-right: 0.5rem;
          font-family: var(--eke-body-font-family);
          span {
            font-weight: var(--eke-font-weight-bold);
          }
        }
        .span__body {
          font-size: 13.5px !important;
          color: var(--eke-body-text-secondary-color);
          opacity: 0.6;
          font-weight: normal !important;
          text-decoration: line-through;
          font-family: var(--eke-body-font-family);
          font-weight: (--eke-font-weight-light);
        }
      }

      @include conditionBadgeStyle;
    }

    // ._product_price{
    //     display: flex;
    //     align-items: flex-start;
    //     justify-content: space-between;
    // // border-bottom: 1px solid #eee solid #f2f2f2;
    //     padding-top: 0.8rem;
    //     padding-bottom: 0.3rem;

    // }

    .no_item_sold_ {
      padding-top: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.3rem;

      ._no_p_ {
        color: var(--eke-body-text-gray-color);
        margin-bottom: 0 !important;
        font-family: var(--eke-body-font-family);
        font-weight: (--eke-font-weight-normal);

        ._text_ {
          font-size: 12px !important;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
      }

      ._n_rating_row {
        display: flex;
        align-items: center;
        line-height: 0 !important;
        padding-left: 0.2rem;

        .star-ratings {
          margin-top: 0 !important;
          padding-top: 0 !important;
          margin-right: 0.1rem;
          .star-container {
            padding-right: 2px !important;
            .widget-svg {
              width: 14px !important;
              height: 14px !important;
            }
          }
        }

        .rate-score {
          span {
            font-size: 12.2px !important;
            color: var(--eke-body-text-gray-color);
            font-family: var(--eke-body-font-family);
            font-weight: (--eke-font-weight-normal);
          }
        }
      }
    }

    .description_of_product {
      font-size: 13px;
      color: var(--eke-body-text-secondary-color);
      line-height: 20px;
      font-family: var(--eke-body-font-family);
      font-weight: (--eke-font-weight-normal);
    }

    ._bottom_of_card {
      margin-top: 0.6rem !important;
      padding-top: 0.3rem !important;
      padding-bottom: 0rem !important;
      border-top: 1px solid #eee solid #f2f2f2;

      ._no_p_ {
        display: flex;
        align-items: baseline;
        color: var(--eke-body-text-gray-color);
        margin-bottom: 0 !important;
        font-family: var(--eke-body-font-family);
        font-weight: (--eke-font-weight-normal);

        ._no_count {
          font-size: 13px !important;
          text-shadow: 0.1px solid #eee 0.1px solid #eee
            var(--eke-body-text-gray-color);
          margin-right: 2.5px;
        }
        ._text_ {
          font-size: 12px !important;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
