
.cardTable{
  width: 100%;
  min-height: 30vh;
  height: auto;
    border: none !important;
    border-radius: 10px !important;
}

.selection {
    width: 10px !important;
  }
  
  .noResults {
    text-align: center;
    padding: 5px 0;
    font-weight: var(--eke-font-weight-light) !important;
    font-size: 13px;
    font-family:  var(--eke-body-font-family);
  }
  
  .sortable {
    position: relative;
  }
  
  .sortable span:before,
  .sortable span:after {
    content: "";
    position: absolute;
    right: 0;
    display: inline-block;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .sortable span:before {
    top: 13px;
    border-top: 4px solid #b2b2b2;
  }
  
  .sortable span:after {
    top: 7px;
    border-bottom: 4px solid #b2b2b2;
  }
  
  .sortable .sort {
    position: absolute;
    top: 4px;
    right: 20px;
  }
  
  .loader {
    padding: 0px 0 0 10px ;
    text-align: left;
  }
  
  .sortable {
    position: relative;
    cursor: pointer;
  }
  
  .sortable span:before,
  .sortable span:after {
    content: "";
    position: absolute;
    right: 0;
    display: inline-block;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .sortable span:before {
    top: 13px;
    border-top: 4px solid #b2b2b2;
  }
  
  .sortable span:after {
    top: 7px;
    border-bottom: 4px solid #b2b2b2;
  }
  
  .renderTop__Horizontal{
    border-bottom: 1px solid #e3e8ee;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .TheadWith__Bg{
    background-color: #F8FAFF;
  }

 