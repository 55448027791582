

.__back_Btn_mobile{
    display: block;
    margin-bottom: 15px;
    span{
        font-size: 14px;
        @extend .fontFamily400;
    }
    @include media-breakpoint-up(lg){
       display: none;
    }
}

.my_account_item{
    font-size:17px;
    font-weight: bold;
    color: $secondary !important;

    &:hover{
        color: $secondary !important;
        background-color: transparent !important;
    }
}

.extra__action_g{
                                         
    span{
        font-size: 12px;
        color: #333;
        cursor: pointer;
        display: block;
        margin-bottom: 0.2rem;
        @extend .fontFamily400;

        &:hover{
            opacity: 0.6;
        }
    }
}

.account-user-container{
    width: 100%;
    margin-bottom: 9rem;
    height: auto;
    min-height: 50vh;
    padding: 0rem 1.00rem 0 0;
//background-color: var(--eke-bg-light-color);
    
    @include media-breakpoint-up(lg){
        width: $containerwidth !important;
        max-width: $maxWidth !important;

    }

    @include media-breakpoint-up(xl){
        width: $containerwidth;
        max-width: $maxWidth !important;
        //padding-top: 6.1rem;

    }

    .account-pro-row{  
        .col-lg-3{
            flex: 0 0 auto;
            width: 22% !important;
        }

        // .account-col-left{
        //     height: 100vh;
        //     padding-bottom: 2rem;
        //     padding-top: 30px;
        //     margin-right: 1rem;
           

        //     @include media-breakpoint-up(lg){
        //         border-right: 1px solid #ddd;
        //     }

        //       ._display-header{
        //           border-bottom:  1px solid $border-light;

        //           ._wrapper_h1 #app-inner{
        //               padding-top: 0 !important;
        //              // padding-bottom: 0 !important;
        //           }
        //         ._wrapper_h1 h1{
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //             white-space: nowrap;
        //             max-width: 16rem;
        //             margin-bottom: 0 !important;

        //             a{
        //                 // line-height: 1.5;
        //                 color: inherit;
        //                 // @extend .fontFamily700;
        //                 cursor: pointer;
        //                 text-decoration: none;
        //                 &:hover{
        //                     text-decoration: underline;
        //                 }
        //             }
        //         }

        //         span{
        //             margin-top: 0 !important;
        //             padding-top: 0 !important;
        //             margin-bottom: 0 !important;
        //             font-size: 12px !important;
        //             color: var(--eke-bg-secondary-color);
        //         }
        //       }

        //       ._list_child_group{
        //           border-bottom:  1px solid #efeff1;
        //           padding-bottom: 15px;
        //           margin-bottom: 10px;

                 
        //       }

             
        //         .list-group-item{
        //             background-color: transparent !important;
        //             border: none !important;
        //             transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
        //             border-radius: 0px !important;
        //             outline-offset: -2px;
        //             font-family: var(--eke-body-font-family);
        //             font-size: 13px !important;
        //             font-weight: var(--eke-font-weight-normal) !important;
        //             line-height: 1.3rem !important;
        //             color: var(--eke-body-secondary-color) !important;
        //             padding: .5rem 1rem .4rem 0rem !important;
                    
        //             .sD_m_{
        //                 display: flex;
        //                 align-items: center;
        //                 color: inherit;

        //                 span{
        //                     padding-top: 0px;
        //                 }

        //                 svg{
        //                     font-size: 0.9rem !important;
        //                     vertical-align: -0.175em;
        //                     margin-right: 0.5rem;
        //                 }
        //             }

        //             // svg{
        //             //     width: 5px;
        //             //     height: 22px;
        //             //     fill: $secondary;
        //             //     
        //             // }
        //             .icon__acc_2{
        //                 width: 1rem;
        //                 vertical-align: -0.175em;
        //                 display: inline-block;
        //                 margin-right: 0.5rem;
        //                 fill: $black;
        //                 stroke-width: 1 !important;
        //             }
        //             .icon__acc_{
        //                 font-size: 0.9rem !important;
        //                 vertical-align: -0.175em;
        //                 display: inline-block;
        //                 margin-right: 0.5rem;
        //               }

        //             &:hover{
        //                text-decoration: underline;
        //                 color: $primary-light !important;
        //                 .icon__acc_2{
        //                     fill: $primary-light !important;
        //                 }
                        
        //             }

                    
                    
        //         }

        //         .active{ 
        //             border-left: 3px solid $primary-light !important;
        //             background-color: rgba(#fff, 0.9) !important;
        //         }
        //     }




            .account-profile-col-right{
                border-radius: 4px;
                background-color:  #ffffff;  //$bg-color2;
                height: auto;
                padding-bottom: 2rem;
                padding: 7px 10px 5px 10px;

                @include media-breakpoint-up(lg){
                    padding: 10px 15px 5px 15px;
                }

                .account-row{
                    .card{
                        //background-color: transparent !important;
                        margin-top: 0.8rem;
                        border: none !important;
                        margin-bottom: 1rem;
                        @extend .shadow-sm;

                      .card-body{
                            padding-left: 0;
                            padding-right: 0;

                          .aD_de_, .aS_d_{
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              padding-right: 0.95rem;
                              padding-left: 0.95rem;
                              padding-bottom: 0.5rem;
                              border-bottom: 1px solid #ddd;
  
  
                              .aD_icon{
                                  font-size: 18px;
                                  color: $primary;
                                  cursor: pointer;
                              }
                          }

                          .aS_d_{
                            border-bottom: none !important;
                            padding-bottom: 0 !important;
                          }
                          .aD_n_{
                              padding-top: 1.0rem;
                              padding-bottom: 1.7rem;
                              padding-right: 0.95rem;
                              padding-left: 0.95rem;

                              h5{
                                  font-size: 15px;
                                  font-weight: 500;
                                  color: $secondary;
                              }

                              p{
                                  font-size: 14.5;
                                color: rgba($color:$secondary, $alpha: 0.8);
                              }
                          }

                          .aE_pas_{
                            padding-right: 0.95rem;
                            padding-left: 0.95rem;

                            a{
                                text-transform: uppercase;
                                color: $primary;
                                font-weight:500;
                                font-size: 15px;
                                text-decoration: none !important;
                            }
                          }
                      }
                    }

                        .address_form_card{
                            .card{
                                margin-top: 0.8rem;
                                border: none !important;
                                margin-bottom: 1rem;
                                @extend .shadow-sm;
                        
                              .card-body{
                                  padding: 15px 15px !important
                              }
                        
                            }
                    
                    }
                }

                .account-details-row{

                    padding: 0.8rem 1.0rem !important;

                    .form-control, .form-select{
                        padding-top: 0.6rem;
                        padding-bottom: 0.6rem;
                        font-size: 13px !important;
                        border-top: none !important;
                        border-left: none !important;
                        border-right: none !important;
                        border-radius: 0 !important;
                        font-family: Arial, Helvetica, sans-serif !important;
                    }
                    .form-select{
                        cursor: pointer !important;
                    }
                }




            }
            
           
        }
}