
@use "../../scss/abstract/mixin" as *;
@use "../VideoPlayer/VideoPlayerWithoutControls" as player;

.StockEmptyWrapper_float{
    position: absolute;
    top: 50%;
    left: 0%;
    right: 0%;
}
.boxShadow{
    @include boxShadow;
}


.prod_commponent_row_{
    padding-top: 15px !important;
    @include laptop(){
        .col-lg-3{
            flex: 0 0 auto !important;
            width: 20%!important;  
        }

    }
    .product__card__col{
        padding: 0;
        padding-right: 0.5rem;    
        border-radius: 10px !important;
    
    }

    @include desktop(){
        .col-lg-3{
            flex: 0 0 auto !important;
            width: 20%!important;  
        }

    }
    .product__card__col{
        padding: 0;
        padding-right: 0.5rem;    
        border-radius: 10px !important;
    
    }
}


.product_vid_commponent{

    .product__card__col{
        padding: 0 !important;
        border-radius: 10px !important;

        @include laptop(){
            padding-right: 0.5rem;    
            flex: 0 0 auto !important;
            width: 16.6666%!important;  
        }

        @include desktop(){
            padding-right: 0.5rem;    
            flex: 0 0 auto !important;
            width: 16.6666%!important;  
        }
    }
}

.product_vid_commponent,.prod_commponent_row_{
    margin-top: 1.8rem;

    ._add_cart_button{
        position: absolute;
        right: 8px;
        top: 170px;
        z-index: 2 !important;

        // .action_btn{
        //     padding: 0.57rem 0.895rem 0.57rem 0.895rem !important;

        //     &:hover{
        //         background-color: #ff4026 !important;
        //     }
        // }

        // .action_btn{
        //     width: 75px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     border-radius: 20px;
        //     color: colors.$white;
        //     font-family: fonts.$fontfamily !important;
        //     font-size: 14px !important;
        //     font-weight: 500 !important;
        //     background-color: #fc624d !important; 
        //     border: none !important;
        //     box-shadow: 0px 10px 10px rgba(0,0,0, 0.1);
        //     transition: all 0.3s ease-out;
        // }

        .group--components{
            display: flex;

            .quantity--picker{
                display: none;
            }

            .action_round_btn{
                width: 36px;
                height: 36px;
                font-size: 15px;
                border-radius: 50%;
                text-align: center;
                color: var(--eke-body-text-light-color);
                border: none !important;
                font-weight: 600 !important;
                background-color: var(--eke-bg-primary-color) !important;
                box-shadow: 0px 10px 10px rgba(0,0,0, 0.1);
                transition: all 0.3s ease-out;
    
                &:hover{
                    background-color: var(--eke-bg-primary-light-color) !important;
                }
            }

            &:hover{
                .action_round_btn{display: none;}
                .quantity--picker{display: block;}
            }
        }
    }


    .product__card__col{
        position: relative;
        margin-bottom: 0.7rem !important;
        
        .save-item-btn{
            position: absolute;
            width: 37.992px !important;
            height: 36.908px !important;
            padding: 7.909px;
            border-radius: 50%;
            text-align: center;
            line-height: 23px;
            right: 8px;
            top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            z-index: 5 !important;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%) !important;
            transition: all .3s ease-in-out;
            border-radius: 27.698px;
            background: var(--eke-color-primary-200) !important;
            overflow: hidden;
            cursor: pointer;
            &:hover{
            background: var(--eke-color-primary-400) !important;
                svg{
                    color: var(--eke-body-text-primary-light-color) !important;
                    fill: var(--eke-bg-primary-light-color) !important;
                }
            }
    }

    .active--saved{
        background: var(--eke-color-primary-400) !important;
    }

    .add--saved{
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%;

        // svg{
        //     display: inline-block;
        //     font-style: normal;
        //     font-weight: 400;
        //     fill: #ddd !important;
        //     stroke: #ddd !important;
        // }

    }


    .card-noBorder{
        box-shadow: none;
    }
    
    .card{
        position: relative;
        border-color: #ddd !important;
        border: none;
        transition: box-shadow .5s;
        border-radius: 10px !important;
        overflow: hidden;
        z-index: 0 !important;

        .priceOff{
            right: 5px !important;
        }
       
        .card--inner{
            text-decoration: none;

            .video__wrapper{
                width: 100% !important;
                height: 200px;
                transition: all .4s ease-in-out ;

                //@extend .paused--overlay;

                
                
                [data-testid="hover-video-player-container"]{
                    height: 200px !important;
                    z-index:0 !important;
                    width: 100% !important;
                }


                video{
                    object-fit: cover !important;
                    height: 200px;
                    border-radius: 10px;
                }

                .video-react-control-bar{
                    display: none !important;
                }
        
        
            }
        
            .card-body{
                padding-top: 8px !important;
                padding-bottom: 12px;
                padding-left: 12px !important;
                padding-right: 12px  !important;
                @include conditionBadgeStyle;
                .card-text{
                    margin-bottom: 0rem !important; 
        
                    .wrap__t__group {
                        display: flex;
                        align-items: baseline;
                        padding-top: 0.255rem;
        
                        .card-image-wrapper{
                           width: 0px;
                           height: 40px;
                           //background-color: #eee !important;
                           //padding: 0.1rem;

                        //    @include media-breakpoint-up(lg){
                        //         width: 60px;
                        //         height: 40px;
                        //     }
                        //    @include media-breakpoint-up(xl){
                        //         width: 60px;
                        //         height: 40px;
                        //     }
                   
        
                           img{
                               width: 100%;
                               height: 100%;
                               object-fit: contain;
                           }
        
                        }
        
                        // ._main_price_{
                        //     display: flex;
                        //     flex-direction: column;
                            
                           
                        //     h3{
                        //         margin-bottom: 0rem !important;
                        //         margin-right: 0.5rem;
                        //         font-family: var(--eke-body-font-family);
                        //         color: var(--greyscale-900, #212121);
                        //         font-weight: var(--eke-font-weight-bold);
                        //         font-size: 18.486px !important;
                        //         line-height: 120%; /* 24.583px */
                        //         span{
                        //             font-weight: var(--eke-font-weight-bold);
                        //         }
                              
                        //     }
                        //     .span__body{
                        //         font-size: 13.5px !important;
                        //         color: var(--eke-body-text-secondary-color);
                        //         opacity: .6;
                        //         font-weight: normal !important;
                        //         text-decoration: line-through;
                        //         font-family: var(--eke-body-font-family);
                        //         font-weight: (--eke-font-weight-light);
                        //     }
                                
                        // }
                        
        
                    }
        
                    // ._product_price{
                    //     display: flex;
                    //     align-items: flex-start;
                    //     justify-content: space-between;
                    // // border-bottom: 1px solid #eee solid #f2f2f2;
                    //     padding-top: 0.8rem;
                    //     padding-bottom: 0.3rem;
        
                    // }
        
        
                       
                    
        
                    .no_item_sold_{
                        padding-top: 0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 0.3rem;
                        
                        ._no_p_{
                            color: var( --eke-body-text-gray-color);
                            margin-bottom: 0!important;
                            font-family: var(--eke-body-font-family);
                            font-weight: (--eke-font-weight-normal);
                            
                            ._text_{
                                font-size: 12px !important;
                                text-decoration: none;
                                display: flex;
                                align-items: center;
                            }
                        }
        
                        ._n_rating_row{
                            display: flex;
                            align-items: center;
                            line-height: 0 !important;
                            padding-left: 0.2rem;
        
                            .star-ratings{
                                margin-top: 0!important;
                                padding-top: 0 !important;
                                margin-right: 0.1rem;
                                .star-container{
                                    padding-right: 2px !important;
                                    .widget-svg{
                                        width: 14px !important;
                                        height: 14px !important;
                                    }
                                }
                            }
        
                            .rate-score{
                                span{
                                    font-size: 12.2px !important;
                                    color: var( --eke-body-text-gray-color);
                                    font-family: var(--eke-body-font-family);
                                    font-weight: (--eke-font-weight-normal);
                                }
                            }
                            
                        }
        
                    }
        
                    .description_of_product{
                        font-size: 13px;
                        color: var(--eke-body-text-secondary-color);
                        line-height: 20px;
                        font-family: var(--eke-body-font-family);
                            font-weight: (--eke-font-weight-normal);
                    }
        
                    ._bottom_of_card{
                        margin-top: 0.6rem !important;
                        padding-top: 0.3rem !important;
                        padding-bottom: 0rem !important;
                        border-top: 1px solid #eee solid #f2f2f2;
        
                        ._no_p_{
                            display: flex;
                            align-items: baseline;
                            color: var( --eke-body-text-gray-color);
                            margin-bottom: 0!important;
                            font-family: var(--eke-body-font-family);
                            font-weight: (--eke-font-weight-normal);
                            
                            ._no_count{
                                font-size: 13px !important;
                                text-shadow: 0.1px solid #eee 0.1px solid #eee var( --eke-body-text-gray-color);
                                margin-right: 2.5px;
                            }
                            ._text_{
                                font-size: 12px !important;
                                text-decoration: none;
                                display: flex;
                                align-items: center;
                            }
                        }
        
                    }
                }
        
        
            }
        }

      

        &:hover{
            //@extend .shadow;

            // .saved--item_div{
            //     opacity: 1 !important;
            // }

            // .video-react-poster{
                        
            //     transform: scale(1.1) !important;
            // }

            .video__wrapper img{
               //transform: scale(1.1) !important;
            }

            .priceOff{
                display: none !important;
                transition: .3s all ease-in-out;

            }
        }

        }
        
}

}
