


.AddCart_Button{
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36.128px;
    height: 36.367px !important;
    padding: 20.677px !important;
    color: var(--eke-body-text-light-color) !important;
    font-size: 14px !important;
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-medium) !important;
    border: none !important;
    box-shadow: 0px 10px 10px rgba(0,0,0, 0.1);
    transition: all 0.3s ease-out;
    
    span {
        margin-top: 0px;
    }
}
