
@import "./index.scss";

.checkout__tit_{

    .checkout__resp{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom:58.333px;

        .display__resp__status{
            padding: 0 0 !important;
            position: relative;

            .anim__abs_div{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        .__resp__msg_n{

            h5{
                color: var(--eke-body-text-dark-color) !important;
                font-size: 14px !important;
                text-transform: uppercase;
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-normal);
            }

            button{
                display: flex;
                align-items: center;
                margin-top: 1rem;
                font-size: 13.2px;
                color: var(--eke-body-text-gray-color);
                border-color: var(--eke-body-text-gray-color);
                border-radius: 15px;
                display: inline-block;
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-light);

            }
        }
    }

}