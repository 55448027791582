.Center_Label{
    display: flex;
    align-items: center;
    justify-content: center;
}
  .labelText {
    width: 100%;
    line-height: 16px;
    font-weight: var(--eke-font-weight-normal);
    font-family: var(--eke-body-font-family);
    font-size: 14px;
    color: var(--greyscale-700, #616161);
  }
  
  .label {
     position: relative;
      width: 100%;
      color: var(--greyscale-700, #616161);
    }
    
    .TypeCheckbox{
      position: absolute;
      left: 2rem;
      top: 0px;
      opacity: .9;
      cursor: pointer;
}

.TextMarginBottom{
  display: block;
  margin-bottom: 8px;
  margin-top: 8px;
}

  .UpperCaseLabel{
     text-transform: uppercase;
  }

  .fullWidth {
    width: 100%;
  }
  
  .required {
    color: var(--eke-color-error) !important ;
    margin-left: 1px !important;
  }
  

  @media (max-width: 600px) {
    .fullWidth {
      margin-left: 3px;
    }
  }
