
.EventDiv_Wrapper{

    .ViewExternal__link{
        display: flex;
        align-items: center;
        font-size: 13px;
        color: var(--eke-color-primary);
        margin-top: 0.2rem;
        cursor: pointer;
        gap: 4px;
       

    
    }
}