
$primary-brand: #F8880A;
$primary-light:#fc624d;
//$primary-light-hover: #ff4026;

$secondary-brand: #1D4057;
$secondary-light: #364353;

$black: #000;

$white:   #fff;
$white-dark: #f1f2f3;

$blue: #0A54B1;
$blue-light: #f0f9ff57;

$green: #0F8C24;
$red: #d0011b;

$bg-color2: #F2F2F2;
$text-accent: #FD7657;

//$body-color: #F9FAFB;
$body-text-color: #424656;

$border-color: #dbdbdb;
$border-light: #E5E7EB;

$activGreen: #62d58c;

$textgray: #46474a;
$bg-green: #EAFBF1;

$orange: #f68b1e;
$orange-light: #feefde;



$primary:     $primary-brand;
$secondary:   $secondary-brand;