@use "./variable" as variable;
/**

 *
 * mixins
 *
 */

 @mixin phone {
  @media (min-width: map-get(variable.$breakpoints, "sm")) {
    @content;
  }
}

 @mixin tablet {
    @media (min-width: map-get(variable.$breakpoints, "md")) {
      @content;
    }
  }
  
  @mixin laptop {
    @media (min-width: map-get(variable.$breakpoints, "lg")) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: map-get(variable.$breakpoints, "xl")) {
      @content;
    }
  }

  @mixin extraLarge {
    @media (min-width: map-get(variable.$breakpoints, "xxl")) {
      @content;
    }
  }
  
  /**
  *
  * Mixins Spacing
  *
  */
  
  
  
  @mixin padding($space) {
    padding:  map-get(variable.$spacing, $space);
  }
  
  @mixin margin($space) {
    margin:  map-get(variable.$spacing, $space);
  }

  
  /**
   *
   * Condition Block
   *
   */
  
   
@mixin conditionBadgeStyle{
  .pr_condition_sec{

      .span__new{
          display: flex;
          width: 50px !important;
          padding: 7.226px 12.043px;
          justify-content: center;
          align-items: center;
          color: var(--eke-color-secondary);
          font-family: var(--eke-body-font-family);
          font-size: 10px;
          font-weight: var(--eke-font-weight-semibold);
          line-height: normal;
          letter-spacing: 0.2px;
          border-radius: 7.226px;
          background: var(--transparent-blue, #335ef714) !important;
      }

      .span__used{
          display: flex;
          width: 50px !important;
          padding: 7.226px 12.043px;
          justify-content: center;
          align-items: center;
          color: var(--eke-color-secondary);
          font-family: var(--eke-body-font-family);
          font-size: 10px;
          font-weight: var(--eke-font-weight-semibold);
          line-height: normal;
          letter-spacing: 0.2px;
          border-radius: 7.226px;
          background: var(--transparent-blue, #335ef714) !important;
      }
  }
}
  

/**
 *
 * Shadow
 *
 */

 @mixin boxShadow {
  box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px;
}


@mixin buttonAndSvg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color:var(--eke-body-text-secondary-color);
  border: none !important;
  padding: 7.999px 20px !important;
  width: 100%;
  text-align: center;
  height: auto;
  border-radius: 5px;
  background-color: rgba($color: variable.$secondary, $alpha: 0.9) !important;
  margin-top: 10px;
  font-weight: 600;
  //box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset !important;
  text-transform: uppercase;
  svg{
      margin-top: 0.2rem;
  }

  &:hover{
     // background-color: #CBD5E1 !important;
  }

  &:focus{
      box-shadow: none;
  }
}





