
@use "../../scss/abstract/mixin" as *;

.paused--overlay{

    .image-wrapper-div{
        width: 100%;
        height: 200px !important;
        z-index: 0 !important;

        img{
            transition: all .7s ease-in-out ;
            border-radius: 5px !important;
            height: 100% !important;
            width: 100%;
            object-fit: cover;
            object-position: top;


            @include laptop(){
                object-fit: cover;
            }
            
        }
    }


    
    .center-div{
        position: absolute;
        z-index: 1; 
        left: 0;
        top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        
            
            .center-button{
                position: relative;
                display: inline-block;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 23%;
                max-width: 120px;
                height: 100%;
                
                .ek-icon.play{
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    z-index: 2;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                    }
                .ek-icon.circle{
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                    }
                
                }
        }

        .ek-video-player--duration{
            position: absolute;
            z-index: 2;
            left: 1.5px;
            background: rgba(0,0,0,.6);
            border-radius: 1px;
            bottom: 1px;
            padding: 2px 6px;
            font-size: 12px;
            color: #fff;
        }
        
}
    
    
    

