@use '../../scss/abstract/mixin' as *;
@use '../../scss/abstract/util' as *;

// .mb_padd_{
//     @include phone(){
//         padding-top: 2.4rem !important;
//     }
//     @include tablet(){
//         padding-top: 2.4rem !important;
//     }
// }

.bg-brand {
  background-color: var(--eke-color-secondary) !important;
}

.main-lazy-load {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.d_collection_wrapper {
  width: 100%;
  height: auto;
  padding-top: 0rem;
  background-color: transparent !important;
  padding-bottom: 2rem;
  margin-bottom: 6em;

  @include laptop() {
    padding-top: 0rem;
  }

  .filtersHeading {
    font-size: 12.5px;
    color: var(--eke-color-secondary);
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-semibold) !important;
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  .container_bread_crumb {
    //padding-left: 0!important;
    padding-top: 1.3rem;
    max-width: $maxWidth !important;

    @include laptop() {
      width: 100% !important;
    }

    @include desktop() {
      width: var(--eke-container-width) !important;
    }
    @media (min-width: 1000px) and (max-width: 1300px) {
      width: 100% !important;
    }

    @media (min-width: 1000px) and (max-width: 1300px) {
      max-width: 100% !important;
      padding-left: 18px !important;
    }

    .collection-title {
      h1 {
        padding-top: 0 !important;
        padding-left: 0 !important;
      }
    }

    #app-inner {
      padding-top: 0.5rem !important;
      padding-bottom: 0 !important;
    }

    ._p_bread_crumb {
      padding-left: 0 !important;
      font-weight: var(--eke-font-weight-normal) !important;
      font-family: var(--eke-body-font-family) !important;
      font-size: 13px !important;
      a {
        text-decoration: none;
        color: var(--eke-color-secondary);
        &:hover {
          text-decoration: underline;
        }
      }

      .active_breadcrumb {
        a {
          color: var(--eke-color-primary) !important;
          font-weight: 500;
          cursor: text !important;

          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }

  .divider {
    border-bottom: 1px solid #edf0f4;
    width: 100%;
    height: 5px;
    margin-top: 0.5rem;
  }

  //@extend
  .collection_grid_v {
    width: 100% !important;
    max-width: var(--eke-container-maxWidth) !important;
    margin: 0 auto;

    @include laptop() {
      width: $collectionWrapperwidth !important;
    }

    @include desktop() {
      width: $collectionWrapperwidth;
    }

    @media (min-width: 993px) and (max-width: 1300px) {
      width: 100% !important;
    }

    .row {
      padding: 0px;
      margin: 0 !important;
      padding-top: 10px;

      @include laptop() {
        padding-left: 0 !important;
        margin-left: 0 !important;
        column-gap: 8px;

        .col-lg-2 {
          flex: 0 0 auto;
          width: 19.5666667% !important;
        }

        .col-lg-10 {
          flex: 0 0 auto;
          width: 79.33333333% !important;
        }
      }

      .collection_mainBar {
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-top: 0rem;
        background-color: var(--eke-color-white);
        border: 1px solid #e4f4ff;
        border-radius: 12px;

        //@extend .pl-2;

        @include laptop() {
          padding-left: 13.5px !important;
          padding-right: 13.5px !important;
        }

        // @include desktop(){
        //     padding-left: 12px !important;
        //     padding-right: 12px !important;

        // }

        .container-mainbar {
          .prod_commponent_row_ {
            padding-top: 15px !important;
            @include laptop() {
              .col-lg-3 {
                flex: 0 0 auto !important;
                width: 25% !important;
              }
            }
          }
        }

        .section__m_div {
          display: block;

          @include laptop() {
            display: none;
          }

          @include desktop() {
            display: none;
          }

          .pg_title_navig__btn {
            width: 100%;
            height: auto;
            padding: 0.9rem 0.6rem;
            background-color: var(--eke-color-white);

            ._postion_center {
              h5 {
                font-size: 18px;
                margin: 0;
                padding: 0;
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-extrabold);
                margin-left: 10px;
                text-align: center;
              }
            }
          }

          ._filter_sorting_ {
            height: auto;
            padding: 0.9rem 0.6rem;
            background-color: var(--eke-color-white);
            display: flex;
            align-items: center;
            justify-content: center;

            .sub_filter_sorting_ {
              color: var(--eke-color-secondary);
              font-size: 0.75rem;
              font-weight: 700;
              position: relative;
              text-align: center;
              text-transform: uppercase;
              width: 50%;
              text-decoration: none;

              &:first-child {
                border-right: 0.09975rem solid var(--eke-color-secondary);
              }

              .sort__fiIcon2 {
                width: 15px !important;
                height: 15px !important;
              }

              .sort__fiIcon {
                width: 20px !important;
                height: 20px !important;
              }
              .sort__fiIcon,
              .sort__fiIcon2 {
                fill: var(--eke-color-secondary);
                margin-right: 10px;
              }
            }
          }
        }

        .price_sort_wrapper {
          //display: none;
          width: 100%;
          padding-top: 7px;
          padding-bottom: 0.3rem;
          flex: 0;
          //border-bottom: 1px solid #e9ecef;

          @include laptop() {
            display: block;
          }
        }

        .grid_list_container {
          margin-bottom: 3rem;
          flex: 1;
          height: 100%;

          .__main_collection_product_card_ {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: auto;

            .row {
              padding-top: 15px !important;
              gap: 0;

              @include laptop() {
                .col-lg-2 {
                  flex: 0 0 auto;
                  width: 19.5666667% !important;
                }

                .col-lg-10 {
                  flex: 0 0 auto;
                  width: 79.33333333% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
