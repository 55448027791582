@use '../../scss/abstract/mixins.scss' as mixins;

.AppButton {
  border: none;
  font-size: 13.5px;
  outline: none !important;
  font-family: var(--eke-body-font-family);
  font-weight: 400 !important;
  margin-right: 10px;
  align-items: center;
  gap: 10px;
  text-align: center;
  justify-content: center;
  transition: ease background-color 250ms;
  width: auto !important;
  //max-width: 150px !important;

  span {
    font-size: 14px !important;
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-bold) !important;
    margin-top: 0px;
  }
}


.Shadow {
  @include mixins.boxShadow;
}

.BtnUppercase {
  text-transform: uppercase;
}

.BtnPrimary {
  background-color: var(--eke-color-primary);

  &:hover {
    background-color: var(--eke-color-primary-400);
    //box-shadow: 0 0 0 0 #fff, 0 0 0 calc(3px + 0px) rgba(246, 156, 59, 0.5), 0 0 #0000;
  }
}

.BtnSecondary {
  background-color: var(--eke-color-secondary);

  &:hover {
    opacity: 0.8;
  }
}

.BtnSuccess {
  background-color: var(--eke-color-success);
  &:hover {
    opacity: 0.8;
  }
}

.BtnDanger {
  background-color: var(--eke-color-error);
  &:hover {
    opacity: 0.8;
  }
}

.BtnLight {
  background-color: var(--eke-btn-light-color);
  &:hover {
    opacity: 0.8;
  }
}

.TextLight {
  color: var(--eke-body-text-light-color);
}

.TextDark {
  color: var(--eke-body-text-dark-color);
}

.TextPrimary {
  color: var(--eke-color-primary);
}

.TextSecondary {
  color: var(--eke-color-secondary);
}

.TextSuccess {
  color: var(--eke-color-success);
}

.TextDanger {
  color: var(--eke-color-error);
}

.TextDark {
  color: var(--eke-color-dark);
}

.Disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
  pointer-events: none !important;
}

.Button__Name {
  padding-top: 0px;
  font-size: 14px;
}

.ButtonName_Font {
  font-weight: var(--eke-font-weight-normal);
}

.ButtonName_FontLight {
  font-weight: var(--eke-font-weight-light);
}

.Button__Flex_Center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconButton__Style {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50% !important;
  padding: 7px 5px !important;
  height: 40px !important;
  width: 40px !important;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  &:hover {
    background-color: #eee;
  }
}

.TextButton__Style {
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  font-family: var(--eke-body-font-family);
  font-size: 13.5px;
  font-weight: var(--eke-font-weight-light) !important;
  box-shadow: none;
  transition: ease background-color 250ms;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    text-decoration: underline;
    opacity: 0.7;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    pointer-events: none !important;
  }
}

.btn {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 11.5px;
  padding-bottom: 10px; //padding-bottom: 12px;
  border-radius: 62.069px;
}
