

.AccordionList__Item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
}

.AccordionList__Name{
    font-size: 14px;
    font-weight: 400;
}
.AccordionList__Button{
    padding-left: 0.5rem !important;
}

.Discount_FieldWrap{
    width: 100% !important;
    margin-top: 10px;
}