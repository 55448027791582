
.RatingReview__Section{
     margin-top: 2.5rem;
     border-bottom: 1px solid var(--eke-border-white-dark-color);
    }
    
    .RatingReview__Fields{
      padding-top: 1.2rem;
        
}

.Cancel__Button{
    text-decoration: underline !important;
}

.MarginTop_Bar{
    margin-top: 1rem;
}