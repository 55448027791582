
@use "../../../../scss/abstract/colors" as *;
@use "../../../../scss/abstract/mixins" as *;
@use "../../../../scss/abstract/fonts" as *;

.ButtonGroup{
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
.PaymentHook__Button{
    position: relative;
    color: #fff;
    z-index: 1;
    width: 100%;
    // padding: 10px 46px;
    //margin-bottom: 30px;
    text-align: center;
    //font-size: 15px;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    background-color: $green;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    //border-radius:15px;
    @extend .fontFamily700;
    // animation-duration: calc(2 * 1s);
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;
    box-shadow: none;
  
     &:hover{
      background-color: $green;
     }
     &:focus{
         background-color: $green;
     }
    
    // &:before {
    //   content: "";
    //   z-index: 1;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   border: none;
    //   border-radius: 15px;
    // }
}

// .HookButton__shadow{
//     position: relative;
//     border-radius: 100%;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     height: 4px;
//     background: hsl(0, 10%, 65%);
//     animation: shadow 2s infinite ease-in-out;
//     z-index: -1;
// }