@use "../../../../scss/abstract/mixin" as *;

.prod_commponent_row_{
    padding-top: 15px !important;
    @include laptop(){
        .col-lg-3{
            flex: 0 0 auto !important;
            width: 20%!important;  
        }

    }
    .product__card__col{
        padding: 0;
        padding-right: 0.5rem;    
        border-radius: 10px !important;
    
    }

    @include desktop(){
        .col-lg-3{
            flex: 0 0 auto !important;
            width: 20%!important;  
        }

    }
    .product__card__col{
        padding: 0;
        padding-right: 0.5rem;    
        border-radius: 10px !important;
    
    }
}

.product_vid_commponent{

    .product__card__col{
        display: block;
        padding: 0 !important;
        border-radius: 10px !important;
        width: 100%!important;  

        @include laptop(){
            padding-right: 0.5rem;    
            flex: 0 0 auto !important;
            width: 100%!important;  
        }
    }
}


.Hr_horizontal{
    border: 0;
    border-bottom: 0.0625rem solid #f1f1f2;
    margin: 0;
    width: 98%;
}
