
@use "../../scss/abstract/colors" as *;
@use "../../scss/abstract/fonts" as *;
@use "../../scss/abstract/util" as *;
@use "../../scss/abstract/mixin" as mixin;

// .lazyload-wrapper{
//     z-index: -10000;
//     position: relative;  
// }
.nav-header_ {
  
        min-height: 75px !important;

    @include mixin.laptop() {
        min-height: inherit !important;
    }
}

.prompt__dialog .modal-content .modal-footer button{
    font-family: var(--eke-body-font-family);
    background-color: var(--eke-bg-primary-color) !important;
    border-color:var(--eke-border-primary-color) !important;
    outline: none !important;
    box-shadow: none;

    &:focus{
        outline: none !important;
        box-shadow: none !important;
    }

}

.prompt__dialog .modal-header .btn-close {
    display: block !important;
}

.Warning__Note{
    font-size: 13px;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-normal);
}

.notice__wrapper{
    padding-left: 1rem;
    padding-right: 1rem;

    .alert{
        background-color: $blue-light !important;
        border: 1px solid $blue-light !important;
        div{
            span{
                color: rgba($color: $black, $alpha: 0.9) !important;
            }
        }
    }

    .note__title{
        font-size: 14px;
    }

}

.delivery--durations{
    span{
        color: rgba($color: $black, $alpha: 0.9) !important;
    }
}

.span_extra__action {

    span {
        font-size: 12.1px;
        color: var(--eke-body-text-primary-color);   // #60A5FA;
        cursor: pointer;
        display: block;
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal);

        &:hover {
            opacity: 0.6;
        }
    }
}



.checkout_menubar {
    padding-top: 25px !important;
    padding-bottom: 10px !important;

    .back__icon_div {
        position: absolute;
        left: 22px;

        button {
            background-color: transparent !important;
            border: 1px solid rgb(203, 198, 198) !important;
            border-radius: 8px;
            color: rgb(203, 198, 198) !important;
            padding: 1.5px 1.5px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            //font-size: 16px;

            svg {
                font-size: 25px;
                stroke-width: 1px !important;
            }
        }
    }

    .Checkoutcontainer_wrapper {
        display: flex;
        align-items: center;
        justify-content: center !important;

        .brand_check_wrapper {
            img {
                width: auto !important;
            }
        }

        @include mixin.laptop() {
            width: 80% !important;
            max-width: $maxWidth !important;
            //margin: 0 auto;
        }

        @include mixin.desktop() {
            width: 80% !important;
            max-width: $maxWidth !important;
            margin: 0 auto;
        }
    }

}



.d_checkout_wrapper {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    min-height: 400px;
    position: relative;
    clear: both;


    .checkout_col__v {
        width: 100%;
        backdrop-filter: blur(0.5px);

        ._ct_wrapper {
            font-size: 12px !important;
            background-color: $blue-light;
            padding: 15px 15px 0.1px 15px !important;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-normal);

            .breadcrumb-item {
                a {
                    text-decoration: none;
                    color: var(--eke-body-text-secondary-color);

                    &:hover {
                        text-decoration: underline;

                    }
                }
            }

            .active_breadcrumb {
                a {
                    color: var(--eke-body-text-gray-color) !important;
                    cursor: text !important;

                    &:hover {
                        text-decoration: none !important;

                    }

                }
            }


        }

        .container {

            @include mixin.laptop() {
                width: 80% !important;
                max-width: $maxWidth !important;
                //margin: 0 auto;
            }

            @include mixin.desktop() {
                width: 80% !important;
                max-width: $maxWidth !important;
                margin: 0 auto;
            }

            .checkout__mainBar {

                .row {

                    .checkout__stepper__pro {
                        margin: 0 auto;
                    }

                    .checkout__stepper__pro {

                        .checkout__tit_,
                       // .checout__address__deliv,
                        .checkout_payment__option,
                        .checkout_shipp__option,
                        .checkout__delivery__option {
                            .card {
                                border: none !important;
                                margin-bottom: 0.8rem;
                                border-radius: 10px;
                                @include mixin.boxShadow;
                                backdrop-filter: blur(10px);
                                transition: all .4s ease-in-out;

                                //    box-shadow: 0 2px 10px rgb(0 0 0 / 5%), 0 10px 15px rgb(0 0 0 / 20%);
                                .inactive_check {
                                    background-color: #eee !important;
                                }

                                .active_check {
                                    background-color: $green; // 
                                }
                                
                                .card-body {
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                }
                            }
                        }

                        .checout__address__deliv {

                            .card {

                                .card-body {
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                    
                                    .delivery__address_option {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding-left: 2.5rem !important;
                                        margin-bottom: 10px;

                                        .list-group {
                                            background-color: transparent !important;

                                            .list-group-item {
                                                border: none !important;
                                                padding: 0 !important;
                                                background-color: transparent !important;

                                                h5 {
                                                    font-size: 14px !important;
                                                    color: var(--eke-body-text-dark-color);
                                                    margin: 0 !important;
                                                    padding: 0;
                                                    line-height: 21px;
                                                    font-family: var(--eke-body-font-family);
                                                    font-weight: var(--eke-font-weight-medium);
                                                }

                                                span {
                                                    font-size: 13px;
                                                    font-family: var(--eke-body-font-family);
                                                    color: var(--eke-body-text-dark-color);
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    .deliver__address_btn {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;

                                        button {
                                            @include mixin.buttonAndSvg;
                                            background-color: var(--eke-bg-primary-color) !important;
                                            color: var(--eke-body-text-light-color) !important;
                                            box-shadow: none;

                                        }

                                    }

                                    // Phone number Div
                                    .AccountUser_Phone_Div{
                                        padding-left: 2.5rem !important;

                                        .AccountUser_Phone_Span{
                                            display: flex;
                                            align-items: center;
                                            font-family: var(--eke-body-font-family);
                                            color: $blue;
                                            cursor: pointer;
                                            font-size: 12.5px
                                        }

                                        
                                        .AccountUser_InputGroup{
                                            display: inline-block;
                                            width: auto;
                                            position: relative;
                                            margin-top: 0.6rem;

                                            .AccountUser__Loader{
                                                position: absolute;
                                                top: 18%;
                                                right: -15%;
                                            }

                                            .AccountUser__Input{
                                                width: auto !important;
                                                padding-right: 2rem;
                                            }

                                            .AccountUser__Button{
                                                position: absolute;
                                                top: 2px;
                                                right: 2px;
                                                height: 26px;
                                                padding-top: 8px !important;
                                                padding-bottom: 5px !important;
                                                padding-left: 10px;
                                                padding-right: 10px;

                                                svg{
                                                    font-size: 17px !important
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        .checkout__delivery__option {
                            

                            .deliveryOrderBy{

                                .title{
                                    font-size: 14px !important;
                                    color: var(--eke-body-text-dark-color) !important;
                                    margin-bottom: 0.7rem;
                                    font-family: var(--eke-body-font-family);
                                    font-weight: var(--eke-font-weight-normal);
                                }
                            }

                            .placeor___btn {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                padding-left: 1rem;
                                padding-right: 1rem;

                                button {
                                    @include mixin.buttonAndSvg;
                                            background-color:  var(--eke-bg-primary-color) !important;
                                            color: var(--eke-body-text-light-color) !important;
                                            box-shadow: none;
                                            font-family: var(--eke-body-font-family);
                                            font-weight: var(--eke-font-weight-semibold);


                                }
                            }


                            .delivery__opt {
                                padding-left: 2.5rem !important;
                                margin-bottom: 10px;

                                //background-color: #EAF1FB;

                                p {
                                    font-size: 13.7px;
                                    color: var(--eke-body-text-secondary-color);
                                    font-family: var(--eke-body-font-family);
                                    font-weight: var(--eke-font-weight-semibold);
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    font-size: 13px !important;
                                    color: var(--eke-body-text-gray-color);
                                    font-family: var(--eke-body-font-family);
                                    font-weight: var(--eke-font-weight-normal);
                                }

                                .list__return__data {
                                    .list-group {
                                        .list-group-item {
                                            border: none !important;
                                            padding: 0 !important;
                                            padding-left: 10px !important;

                                            .delivery__ag__name {
                                                font-size: 13.5px !important;
                                                color: var(--eke-body-text-dark-color);
                                                padding: 0;
                                                line-height: 10px;
                                                font-family: var(--eke-body-font-family);
                                                font-weight: var(--eke-font-weight-semibold);
                                            }

                                            .deliver__by_span {
                                                font-size: 13.5px;
                                                color: gray;
                                                display: inline-block;
                                                font-family: var(--eke-body-font-family);
                                                font-weight: var(--eke-font-weight-normal);
                                            }

                                            .pickup_from_span{
                                                font-size: 12.5px;
                                                color: rgba($color: $black, $alpha: 0.7) !important;
                                                display: inline-block;
                                            }

                                            .delivery__charges__,
                                            span {
                                                font-size: 13.5px;
                                                color: var(--eke-body-text-success-color);
                                                font-family: var(--eke-body-font-family);
                                                font-weight: var(--eke-font-weight-medium);
                                            }
                                        }
                                    }
                                }

                                .listDelivery{
                                    background-color: $blue-light;
                                    padding: 15px;
                                    padding-top: 15px;
                                    border-radius: 10px;
                                }

                                .list__delivery__agency {

                                    .list-group {
                                        margin-top: 1rem;

                                        .list-group-item {
                                            border: none !important;
                                            background-color: transparent !important;


                                            .d__checkbox_ {

                                                .form-check, .RadioCheck {
                                                    display: flex;
                                                    align-items: center;
                                                    font-size: 13.5;
                                                    cursor: pointer;

                                                    input {
                                                        margin-top: 0.1rem !important;
                                                        cursor: pointer;
                                                        box-shadow: none !important;
                                                    }

                                                    .form-check-input:checked {
                                                        background-color: var(--eke-bg-success-color);
                                                        border-color: var(--eke-border-success-color);
                                                        box-shadow: none !important;
                                                    }

                                                    label {
                                                        font-size: 14px;
                                                        cursor: pointer;
                                                        margin-left: 0.5rem !important;
                                                        color: var(--eke-body-text-dark-color);
                                                        //@extend .fontFamily400;
                                                    }
                                                }

                                            }
                                            .delivery__selection {



                                                .listItem__price {
                                                    span {
                                                        font-size: 14px !important;
                                                        color: var(--eke-body-text-secondary-color);
                                                        font-family: var(--eke-body-font-family);
                                                        font-weight: var(--eke-font-weight-normal);
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }


                                .list__info__delivery {
                                    .container {
                                        padding: 3rem;
                                        padding-top: 2rem !important;
                                        padding-left: 15px !important;

                                        .list_g__info__ {
                                            padding: 2.5rem 0;
                                            margin-left: 0;
                                            background-color: #EAF1FB !important;

                                            ul {
                                                list-style: none;

                                                li {
                                                    font-size: 13px;
                                                    font-family: var(--eke-body-font-family);
                                                    color: var(--eke-body-text-secondary-color);
                                                    margin-bottom: 0.5rem;

                                                    &::before {

                                                        content: "";
                                                        width: 1.3em;
                                                        height: 1.3em;
                                                        display: inline-block;
                                                        background-image: url("data:image/svg+checkout_payment__optionxml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='50' fill='gray'/%3E%3C/svg%3E");
                                                        background-size: 38%;
                                                        background-repeat: no-repeat;
                                                        background-position: left center;
                                                        position: relative;
                                                        top: 0.2rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .pickup-addresses{
                                display: flex;
                                align-items: flex-start;
                                gap:10px;
                                margin-top: 5px;

                                label{
                                    color: #333;
                                    font-size: 13px;
                                }
                            }
                        }


                        .checkout_shipp__option {
                            .card {
                                border: none;
                                //box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgb(0 0 0 / 8%) !important;

                                .card-body {

                                    ._heading {
                                        font-family: var(--eke-body-font-family);
                                        color: var(--eke-body-text-secondary-color);
                                        font-size: 24px !important;
                                        font-weight: 700;
                                        margin-bottom: 0.5rem !important;
                                    }

                                    .next__button__link {
                                        padding-top: 0.8rem;
                                        padding-bottom: 0.8rem;
                                        color: var(--eke-body-text-light-color) !important;
                                        font-size: 16px;
                                        font-family: var(--eke-body-font-family);

                                        &:focus {
                                            box-shadow: none !important;
                                        }
                                    }
                                }
                            }

                        }



                        .checkout_payment__option {

                            .D__opt {
                                padding-left: 2.5rem !important;

                                .show__order_pay__summary {
                                    p {
                                        font-size: 14.5px !important;
                                        color: var(--eke-body-text-dark-color);
                                        padding: 0;
                                        line-height: 10px;
                                        font-family: var(--eke-body-font-family);
                                        font-weight: var(--eke-font-weight-bold);
                                    }

                                    .summary__list__g {
                                        margin-top: 15px;
                                        margin-bottom: 5px;
                                        border-bottom: 1px solid #eee;

                                        .list-group {
                                            padding-bottom: 18px;
                                            border-radius: 0px !important;

                                            .list-group-item {
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                border-radius: 0px !important;
                                                border: none !important;
                                                padding-left: 0 !important;
                                                padding-bottom: 0 !important;

                                                .order__item_price {
                                                    font-size: 13.5px !important;
                                                    color: #71717A;
                                                    padding: 0;
                                                    line-height: 10px;
                                                    font-family: var(--eke-body-font-family);
                                                    font-weight: var(--eke-font-weight-medium);
                                                }

                                                .item__group_ {
                                                    display: flex;
                                                    align-items: center;

                                                    .quan__byx {
                                                        margin-right: 10px;
                                                        font-size: 13px !important;
                                                        color: #71717A;
                                                        padding: 0;
                                                        font-family: var(--eke-body-font-family);
                                                        font-weight: var(--eke-font-weight-normal);
                                                    }

                                                    .order__item_name {
                                                        font-size: 13.3px !important;
                                                        color: #71717A;
                                                        padding: 0;
                                                        line-height: 19.5px;
                                                        text-transform: capitalize;
                                                        font-family: var(--eke-body-font-family);
                                                        font-weight: var(--eke-font-weight-medium);

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .all__subt__div {
                                    .list-group {
                                        padding-bottom: 18px;
                                        border-radius: 0px !important;

                                        .list-group-item {
                                            border-radius: 0px !important;
                                            border-top: none !important;
                                            border-left: none !important;
                                            border-right: none !important;
                                            border-bottom: 1px solid #eee;
                                            padding-left: 0 !important;
                                            padding-bottom: 13px !important;
                                            padding-top: 10px !important;

                                            .flex_flow_div {
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                            }

                                            .total_item_style {
                                                font-size: 14.5px !important;
                                                color: rgb(23, 23, 23);
                                                font-family: var(--eke-body-font-family);
                                                font-weight: var(--eke-font-weight-extrabold);
                                            }

                                            .subtotal__p_div {

                                                .sub__total_span,
                                                .sub__tot_price_ {}
                                            }

                                            .listitem__style {
                                                font-size: 14px !important;
                                                color: #71717A;
                                                font-family: var(--eke-body-font-family);
                                                font-weight: var(--eke-font-weight-semibold);
                                            }



                                        }
                                    }
                                }



                                .p__button_div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                // .button___card_box{

                                //     .card-body{
                                //         display: flex;
                                //         align-items: center;
                                //         justify-content: center;
                                //         padding-top: 1rem;
                                //         padding-bottom: 1rem;

                                //         button{
                                //             cursor: pointer;
                                //             padding-top: 0.65rem;
                                //             padding-bottom: 0.65rem;
                                //             width: 80%;
                                //             color: $white;
                                //             text-decoration: none;
                                //             text-align: center;
                                //             letter-spacing: .5px;
                                //             transition: background-color .2s ease-out;
                                //             background-color: $primary;
                                //             border-radius: 4px;
                                //             font-size: 14px;
                                //             font-weight: 500;
                                //             text-transform: uppercase!important;
                                //         }
                                //     }
                                // }
                            }
                        }
                    }

                    .checkout_order__items {


                        .card {
                            border: none !important;
                            border-radius: 10px;
                            transition: all .4s ease-in-out;
                            @include mixin.boxShadow;


                            .card-body {
                                padding: 1.8rem;
                                padding-top: 1.3rem !important;


                                .card-title {
                                    color: var(--eke-body-text-dark-color);
                                    border-bottom: 1px solid #f2f2f2;
                                    padding-bottom: 0.8rem;
                                    padding-left: 0.6rem;
                                    padding-right: 0.6rem;
                                    font-size: 22px !important;
                                    font-family: var(--eke-body-font-family);
                                    font-weight: var(--eke-font-weight-extrabold);

                                }

                                .checkout__order__no {
                                    padding: 0.7rem;
                                    background-color: var(--eke-bg-secondary-color);

                                    h5 {
                                        color: var(--eke-body-text-light-color);
                                        margin: 0 !important;


                                    }
                                }
                            }

                        }

                    }
                }

            }

        }

    }

}

.borderBottom{
    border-bottom: 1px solid $white-dark;
}
