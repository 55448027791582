
@use "../../scss/abstract/colors" as colors;

.SocialShare_Wrapper{
    width: 100%;

    &__Row{
        padding-top: 0.2rem;
        margin: 0 auto !important;
        row-gap: 12px;
    }

    &__Col{
        margin-bottom: 0.5rem;

        .SocialHelper__Text{
            display: block !important;
            color: var(--greyscale-900, #212121);
            text-align: center;
            font-family: var(--eke-body-font-family) !important;
            font-size: 12px;
            text-align: left !important;
            padding-top: 5px !important;
            font-weight: 500;
            letter-spacing: 0.2px;
        }
    }
}