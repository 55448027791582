
.ReturnRequest__Wrapper{

    .MarginTop{
        margin-top: 1rem;
    }

    .ReturnRequest__Content{
        padding-top: 1rem;
      
        
        .List__OrderItems{
            padding-top: 13px;
            padding-right: 25px;
            padding-left: 1.8rem;
    
          

            .ListOrderItem_Wrapper{

            }
        }
    }
}

.Hr_horizontal{
    border: 0;
    border-bottom: 0.0625rem solid #f1f1f2;
    margin: 0;
    width: 98%;
}

.Cancel__Button{
    text-decoration: underline !important;
}