
//@use "../../../../scss/abstract/colors" as *;
@use "../../../../scss/abstract/fonts" as *;
@use "../../../../scss/abstract/mixins" as *;
@use "../../../../scss/base/usebootstrap" as *;

.checkout__payment__option {
    .card {
        border: none !important;
        margin-bottom: 0.8rem;
        border-radius: 10px;
        @include boxShadow;
        backdrop-filter: blur(10px);
        transition: all .4s ease-in-out;

        //    box-shadow: 0 2px 10px rgb(0 0 0 / 5%), 0 10px 15px rgb(0 0 0 / 20%);
        .inactive_check {
            background-color: #eee !important;
        }

        .active_check {
            background-color: var(--eke-color-success); // 
        }

        .card-body {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .borderBottom{
                border-bottom: 1px solid var(--eke-color-info);
            }

            ._heading_d {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.7rem;
                padding-bottom: 0.2rem;
                margin-top: 0.3rem;
                padding-left: 1rem;
                padding-right: 1rem;


                .check__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50% !important;
                    color: var(--eke-color-white);
                    margin-right: 0.8rem;

                }

                h5 {
                    color: var(--eke-color-dark);
                    font-size: 14px !important;
                    text-transform: uppercase;
                    font-weight: var(--eke-font-weight-bold);
                }
            }
        }
      }
    }
    .Payment_option__Col{
        padding-left: 2.5rem !important;
        margin-bottom: 10px;

        //background-color: #EAF1FB;

        p {
            font-size: 13.7px;
            color: var(--eke-color-secondary);
            @extend .fontFamily600;
            margin: 0;
            padding: 0;
        }

        span {
            font-size: 12px !important;
            color: $gray-600;
            @extend .fontFamily400;
        }

            .list-group {
                .list-group-item {
                    border: none !important;
                    padding: 0 !important;
                   // padding-left: 10px !important;

                   .d__checkbox_ {

                        .form-check {
                            display: flex;
                            align-items: center;
                            font-size: 13.5;
                            cursor: pointer;

                            input {
                                margin-top: 0.1rem !important;
                                cursor: pointer;
                                box-shadow: none !important;
                            }

                            .form-check-input:checked {
                                background-color: var(--eke-color-success);
                                border-color: var(--eke-color-success);
                                box-shadow: none !important;
                            }

                            label {
                                font-size: 14px;
                                cursor: pointer;
                                margin-left: 0.5rem !important;
                                color: var(--eke-color-dark);
                                //@extend .fontFamily400;
                            }
                        }
                    }

                    .label__Only{
                        font-size: 14px !important;
                        color: var(--eke-color-dark);
                        @extend .fontFamily400;
                    }

                    .rendering_Image__Div{
                        padding-left: 1rem;
                        padding-top: 0.6rem;
                        img{
                            width: 135px;
                        }
                    }
                }
        }
    }

    .ButtonOp_Wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 1.5rem;

        button{
            text-transform: uppercase;

            .BtnSpan{
                font-weight: 600 !important;
            }
                

        }
    }