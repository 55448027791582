
.buyer__pg_tabs{
    margin-top: 20px !important;

    .nav-tabs{
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        white-space: nowrap;

        .nav-link.active{
            color: $primary !important;
            background-color: transparent !important;
            border: none !important;
            border-bottom: 2px solid $primary !important;
            font-family: $fontfamily !important;

        }
        .nav-item{

            .nav-link {
                min-width: 150px;
               font-size: 13.5px !important;
               font-weight: 480 !important;
               color: $secondary;
               border-top: 0px !important;
               border-left: 0px !important;
               border-right: 0px !important;
               padding-bottom: 1rem;
               font-family: $fontfamily;

            }
           
        }
      
    }

   

    .order--card-div{

        @include media-breakpoint-up(md){
            .col-md-3 {
                flex: 0 0 auto;
                width: 20% !important;
            }
        }

        .card--image_div{
            padding-right: 0 !important;
            
            img{
                height: 80px;

                @include media-breakpoint-up(lg){
                    height: 180px;
                }
            }
        }

        .card-body{
            padding-top: 15px;
            border-radius: 10px;

            .headings{
                font-size: 13.5px;
                font-family: $fontfamily;
                font-weight: 600;
            }

            .top--section__d{
                p{
                    margin-bottom: 10px !important;
                }
                
                .body-text_p{
                    font-size: 13px;
                    //font-family: $fontfamilyBody;
                }
            }

            .data--timeset{
                display: flex;
                align-items: center;
                
                span{
                    font-size: 13.8px;
                    color: $secondary;
                }
            }

            .t_section_p_{
                display: flex;
                align-items: flex-start;
                margin-top: 30px !important;

                .u_delivery_y{
                    font-size: 13px;
                    color: $white;
                    background-color: $secondary;
                    padding: 5px 10px;
                }

                
            }

        }
    }
}