
@use "../../../../scss/abstract/mixin" as *;

.ncm-authorization-card{
    .pmod-ifm-banner-tips {
        margin: 20px 10px 20px 10px;

        @include laptop(){
            margin: 40px 30px 40px 30px;
        }

        @include desktop(){
            margin: 40px;
        }



        .banner_{
            height: 132px;
            background: var(--eke-bg-light-color);
            -webkit-box-shadow: 0 18px 46px 0 rgb(0 0 0 / 5%);
            box-shadow: 0 18px 46px 0 rgb(0 0 0 / 5%);
            border-radius: 8px;
            background-color:  var(--eke-bg-light-color);
            background-image: url('../../../../assets/images/VkMe35xp1fbyu.webp');
            background-repeat: no-repeat;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: flex-start;
            padding-left: 140px;
            position: relative;

            @include laptop(){
                padding-left: 166px;  
                align-items: center;

            }

            div{
                margin-right: 2px;
                padding-top: 10px;


                @include laptop(){
                    margin-right: 184px;
                    padding-top: 0px;

                }

                h3{
                    font-size: 16px;
                    color: #333;
                    letter-spacing: 0;
                    margin-bottom: 9px;
                    line-height: 22px;
                }

                p{
                    color: #333;
                    margin-bottom: 7px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
                
            }

            span{
                margin: 0 32px;
                padding: 0 20px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                color: rgb(177, 175, 175);
                background-color: #eee;
                text-align: center;
                border-radius: 20px;
                white-space: nowrap;
                position: absolute;
                right: 9.5%;
                bottom: 10%;
                cursor: pointer;

                @include laptop(){
                    bottom: 0%;
                    right: 0%;

                }
            }
        }

       .tips_on_listing{
            padding: 32px;
            background:  var(--eke-bg-light-color);
            border-radius: 8px;
            -webkit-box-shadow: 0 18px 46px 0 rgb(0 0 0 / 5%);
            box-shadow: 0 18px 46px 0 rgb(0 0 0 / 5%);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-top: 40px;


            .tips_box{

                h2{
                    font-size: 24px;
                    color: #333;
                    margin-bottom: 23px;
                }

                p{
                    font-size: 14px;
                    padding: 0;
                    margin: 0;
                    color: #333;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
       }
    }
}