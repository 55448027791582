.message-frame-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 61px;
    padding-left: 18px;
    padding-top: 13px;
    border-bottom: 1px solid rgba($color: #323e4e, $alpha: 0.4);

    h5 {
      font-size: 16px;
      color: #eee;
      text-align: left;
      @extend .fontFamily400;
    }
  }

  .message-chat-box::-webkit-scrollbar {
    width: 0px;
  }
  .message-chat-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
    height: 580px;

    .starter-msg {
      width: 50%;
      margin: 10px auto;
      text-align: center;
      color: rgb(190, 189, 189);
      font-size: 12px;
      padding: 8.9px 19px;
      border-radius: 5px;
      background: rgba($color: #323e4e, $alpha: 0.3);
    }

    .message-chat {
      margin-top: 15px;
      padding: 0 15px 0;
      .message-details {
        p {
          display: flex;
          align-items: center;
          flex-direction: column;
          word-wrap: break-word;
          margin-bottom: 0 !important;
          min-width: 120px;

          .msg__u__info {
            min-height: auto;
            width: 100%;
            font-size: 13px;
            font-weight: 500;
          }

          .msg__u__content {
            font-size: 13px;
            width: 100%;
          }
        }
      }
    }

    .outgoing {
      display: flex;

      .message-details {
        margin-left: auto;
        max-width: calc(100% - 30px);
        .msg__u__info {
          color: rgb(236, 157, 108) !important;
        }

        p {
          justify-content: flex-end;
          padding: 3px 15px 5px 15px !important;
          background-color: #475569;
          color: #fff;
          border-radius: 18px 0px 18px 18px;
          text-align: right;
        }
      }
    }

    .incoming {
      display: flex;
      align-items: center;

      .message-details {
        margin-left: 10px;
        margin-right: auto;
        max-width: calc(100% - 30px);

        .msg__u__info {
          color: rgb(149, 148, 148);
        }

        p {
          justify-content: flex-start;
          background-color: #dbdddf;
          padding: 5px 15px 8px 15px !important;
          color: #333;
          border-radius: 0px 18px 18px 18px;
          text-align: left;
        }
      }
    }
  }

  .message-chat-input {
    position: relative;
    width: 100%;
    border-top: 1px solid rgba($color: #323e4e, $alpha: 0.4);
    padding-bottom: 15px;
    height: 60px;

    .msg__main__div {
      .messaging__stream__WRAPPER {
        display: none !important;
      }

      .messaging__stream__div {
        padding: 0 !important;

        .form__container_div {
        }
      }
    }
  }
}
