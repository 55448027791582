

.pd_collections{
    width: 100%;
    min-height: 10vh;
    border-radius: 8px;
    background-color: #fff;
    @extend .shadow-sm;

    

    .coll_headerline{
        //margin-bottom: 1rem;
        padding: 14px 0px 14px 0px;
        
        @include media-breakpoint-up(lg){
            padding: 14px 16px  14px  16px !important;
        }
          
    }


    .row_coll_{
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;



        @include media-breakpoint-up(lg){
           flex-direction: row;
            margin-top: 0px;

        }

        @include media-breakpoint-up(xl){
            margin-top: 0px;
            flex-direction: row;
        } 

        .col_img_container{
            padding: 0 !important;
            margin-bottom: 10px;
            
            @include media-breakpoint-up(lg){
                width: 400px;
                height: 300px;
                margin-bottom: 0px;

             }

            .b-inf_{
                display: block;
                width: 100%;
               
                img{
                    width: 100%;
                    height: 300px;
                    border-bottom-left-radius: 8px;

                    
                    @include media-breakpoint-up(lg){
                        width: 100%;
                        height: 300px;

                     }
                }
            }

        }

        .col_subcategory_sec{
            width: 100%;
            height: auto;
            background-color: #fff;
            border-bottom-right-radius: 8px;



            @include media-breakpoint-up(lg){
                height: 300px;
             }
     

            .row{
                padding-left: 15px;
                padding-right: 13px;

                .customcard{
                    border-radius: 0 !important;
                    border-top: none !important;
                    border-top: none !important;
                    padding: 0 !important;
                    height: 150px;

                    &:last-child .collectionSection { 
                        border-right: none !important; 
                    }
                    
                    .collectionSection{
                        position: relative;
                        border-top: 1px solid #f4f4f4;
                        border-right: 1px solid #f4f4f4;
                        padding: 16px;
                        height: 100%;


                        h5{
                            font-size: 16px;
                            color: $secondary;
                            line-height: 20px;
                            margin-bottom: 4px;
                            display: -ms-flexbox;
                            -ms-flex-align: center;
                            display: flex;
                            align-items: center;
                            @extend .fontFamily700;
                        }

                        span{
                            color: #666;
                            overflow: hidden;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            font-size: 13px;
                            @extend .fontFamily300;
                        }

                        img{
                            height: 65px;
                            position: absolute;
                            right: 10px;
                            bottom: 0;
                            color: transparent;
                            font-size: 0;
                            border: 0;
                            vertical-align: middle;
                        }

                       
                    }
                }

            }
        }
    }
}