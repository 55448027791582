@use "../../../../../scss/abstract/mixin" as mixin;


.UL_ListGroup{
    display: flex;
    flex-direction: row;
    padding: 8px 9.555px!important;
    width: 100%;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
   
 
    @include mixin.laptop(){
        flex-direction: column;
        gap: 0px;
        overflow-x: hidden;
        overflow-y: auto;
    }


    .Item_LI{
        position: relative;
        width: 100%;
        border: none !important;
        padding: 11px 2px !important;
        margin-top: 0rem;
        margin-bottom: 0rem;
        border-bottom: 1px solid var(--eke-border-white-gray-color)!important;

        .CardBox{
            width: 270px;
            border: none;
            border-radius: 0;

            @include mixin.laptop(){
              width: 100%;
            }
        

            .CardBody{
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-right: 1px;
                display: flex;
                align-items: flex-start;
                gap: 10px; 
    
                a{
                    
                    text-decoration:  none !important;
                }

                .ImageDiv{
                    width: 90px !important;
                    height: 90px !important;
                    display: inline-block;
        
                    img{
                        border-radius: 10px;
                        width: 90px !important;
                        height: 100% !important;
                        object-fit: cover;
        
                    }
                }

                .ContentGroup{
                    padding-top: 7px;

                    .TitleH5{
                        display:block;
                        display: -webkit-box;
                        margin-bottom: 0.2rem;
                        max-width: 100%;
                        height: auto; /* Fallback for non-webkit */
                        line-height: 1.4;
                        -webkit-line-clamp: 2; 
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 13.5px !important;
                        font-weight: var( --eke-font-weight-medium) !important;
        
                        &:hover{
                            color: var(--eke-body-text-primary-color) !important;
                            text-decoration: none !important;
                        }
                    }
                }

                .GroupSection{
                    display: flex;
                    align-items: center;
                    margin-top: 19.5px;
                    gap: 15px;
        
                    .BusinessTitle{
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

    }
}