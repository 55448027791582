@use "../../../../../scss/abstract/mixin" as mixin;

.MainInfo_Wrapper{
    
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
    height: auto;
    display: none;

    @include mixin.tablet(){
        display: none;
    }

    @include mixin.laptop(){
        display: flex;
    }


    &__Left{
        margin-top: 8px;
       

        .Heading{
            color: var(--eke-body-text-light-color) !important;
        }
        .GroupSection{
            display: flex;
            align-items: center;
            margin-top: 15px;
            gap: 15px;

            .BusinessTitle{
                margin-bottom: 0 !important;
            }
        }
    }

    &__Right{
      display: none;

      @include mixin.laptop(){
        display: block;
      }
    }
}