@use '../abstract/fonts' as *;
// @use '../abstract/variable' as *;

// @mixin padding($space) {
//     padding:  map-get($spacing, $space);
// }

// @mixin padding-left($space) {
//     padding-left:  map-get($spacing, $space);
// }


  

@mixin gradient {
    background:linear-gradient(to right, rgba(var(--eke-color-primary), 0.90), rgba(var(--eke-color-secondary),0.75));
}

// @mixin boxShadow {
//     box-shadow: rgba(0, 0, 0, 0.10) 0px 2px 4px;
//     //box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
// }

@mixin boxShadow {
    box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px;
}

.boxShadow{
    box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px;
}

btn:focus{
    box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px !important;
}


@mixin whiteGlassMorphism {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

@mixin ShareButtonStyle {
    .share__button, .product__button{
        //width: 39px;
        text-align: center;
        border-radius: 5px;
        border:none;
        color: #eee;
        padding: 0.54rem 0.7rem;
        display: flex;
    align-items: center;
    justify-content: center;
        background-color: rgba(var(--eke-color-secondary), $alpha: .2) !important;
        border: 0!important;
        box-shadow: 0 0 0 0 transparent,0 0 0 0 transparent,0 1px 1px 0 rgba(0,0,0,.1),0 0 0 1px rgba(60,66,87,.16),0 0 0 0 transparent,0 0 0 0 transparent,0 2px 5px 0 rgb(60 66 87/8%)!important;
        transition: all .3s ease-in-out;
        &:hover{
            background-color: rgba(var(--eke-color-secondary), $alpha: .8);
        }
    }
}

@mixin buttonAndSvg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--eke-color-secondary);
    border: none !important;
    padding: 7.999px 20px !important;
    width: 100%;
    text-align: center;
    height: auto;
    border-radius: 5px;
    background-color: rgba(var(--eke-color-secondary), $alpha: 0.9) !important;
    margin-top: 10px;
    font-weight: 600;
    //box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset !important;
    text-transform: uppercase;
    svg{
        margin-top: 0.2rem;
    }

    &:hover{
       // background-color: #CBD5E1 !important;
    }

    &:focus{
        box-shadow: none;
    }
}


@mixin getNavbarStyles {
    .dashboard-custom-nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:  0.6rem 0.5rem 0 0.4rem !important;
        //background-color: #F9FAFB;
        height: 60px;
        z-index: 9;
        @extend .shadow-sm;

        @media (min-width: 1200px) {
            padding:  0.6rem 2rem 0 !important;
        }
        
        .dashboard-custom-nav-menu{
            padding-bottom: 20px;
            button{
                border: none !important;
            }

            @media (min-width: 1200px) {

               button, .dashboard-brand{
                    display: none;
                }
            }
        }

        .dashboard-custom-nav-container{
                .ul__list{
                    display: flex;
                    align-items: center;
                    list-style: none;
                    gap: 1rem;

                    li{

                        .go-live-trigger{
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            font-size: 13.4px;
                            color: #333;
                            gap: 0.3rem;
                            @extend .fontFamily400;

                            span{
                                img{
                                    height: 40px;
                                }
                            }
                        }
                        
                        
                        .dashboard-custom-nav-profile{
                            display: flex;
                            align-items: center;
                            gap: 0.7rem;
                            margin-left: 0rem;
                            background-color: #eee;
                            border-radius: 2rem;
                            padding: 0.3rem 0.3rem;
                            cursor: pointer;

                            @media (min-width: 1200px) {
                                padding: 0.3rem 0.8rem;
                                margin-left: 1rem;
                             }
                         
                            .dashboard-custom-nav-image{
                                img{
                                    height: 1.5rem;
                                    border-radius: 2rem;
                                }
                            }
                            .dashboard-profile_name{
                                align-items: center;
                                gap: 0.7rem;
                                display: none;
                                h5{
                                    font-size: 13.4px;
                                    margin-bottom: 0 !important;
                                    @extend .fontFamily400;
                                }
                                .dropDownIcon{
                                    font-size: 12px;
                                    color: gray;
                                }

                                @media (min-width: 1200px) {
                                   display: flex;
                                    
                                }
                                
                            }
                        
                    }

                }
                }
           
        }
    }   
}

