
@use "../../scss/abstract/mixin" as mixin;

.d_cart_wrapper{
    @include mixin.conditionBadgeStyle;


    .cart_column_v{
        padding-top: 1.9em !important;

        .cart_mainBar {
            .CartTitle_Header{
                margin-bottom: 24px;
            }
        }
    }

    .list-group-item {
        margin-top: 0 !important;
    }

    .Hr_horizontal{
        border: 0;
        border-bottom: 0.0625rem solid var( --eke-border-white-gray-color);
        margin-top: 0.2rem;
        margin-bottom: 0.7rem;
        width: 100%;
    }
}