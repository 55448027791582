
.labelText_R{
    font-size: 14px;
    text-align: center;
    color: var(--eke-body-text-gray-color);
    opacity: .7;
    margin-bottom: 8px;

    i{
        font-size: 12.5px !important;
    }

    span{
        font-weight: var(--eke-font-weight-semibold);
        color: var(--eke-body-text-gray-color);
    }
}

.message_alert{
    width: fit-content !important;
    margin:  0 auto !important;
    margin-top: -25px!important;

    div{
        min-height: 0px !important
    }
}