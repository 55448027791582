@use "../../../../scss/abstract/colors" as colors;

.QuantityCart__Button{
  width: 150px;
  height: auto;
  padding: 0.4rem 0.1rem ;
  background-color: colors.$primary;
  border-radius: 20px;
  transition: all 0.3s ease-out;

  .QuantityCart__ButtonDiv{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .QuantityCart__Button_Display{
        font-size: 15px !important;
        text-align: center;
        color: colors.$white;
      }

      .QuantityCart__Minus,.QuantityCart__Increase{
        height: 25px;
        width: 25px;
        font-size: 15px;
        border-radius: 50%;
        color: colors.$white;
        text-align: center;
        cursor: pointer;
        transition: all .3s;

        &:hover{
          background: colors.$primary-light !important;
        }
      }

      .QuantityCart__Active{
        background-color: colors.$primary-light;
      }
}
}


