@use "../../../../../scss/abstract/mixin" as *;

// .Side__Bar{

//     .col-lg-3{
//         flex: 0 0 auto;
//         width: 20% !important;
//     }
// }

.AccountSide__Bar{
    background-color: var(--eke-bg-light-color);
    height: 100vh;
    padding-bottom: 2rem;
    padding-top: 20px;
    margin-right: 1rem;
    

        // @include laptop(){
        //     border-right: 1px solid #ddd;
        // }

         .Display_Title{
            padding-left: 5px;
         }

        

          .BorderBottom{ 
            border-bottom: 1px solid #efeff1;

             ._wrapper_h1 #app-inner{
                  padding-top: 0 !important;
                 // padding-bottom: 0 !important;
            }
            ._wrapper_h1 h1{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 16rem;
                margin-bottom: 0 !important;

                a{
                    // line-height: 1.5;
                    color: inherit;
                    // @extend .fontFamily700;
                    cursor: pointer;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            span{
                margin-top: 0 !important;
                padding-top: 0 !important;
                margin-bottom: 0 !important;
                font-size: 12px !important;
                color: var(--eke-bg-secondary-color);
            }
          }


            ._list_child_group{
                border-bottom:  1px solid #efeff1;
                padding-top: 15px;
                padding-bottom: 15px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
            }


            .list-group-item{
                background-color: transparent !important;
                border: none !important;
                transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
                border-radius: 0px !important;
                outline-offset: -2px;
                font-family: var(--eke-body-font-family) !important;
                padding: .5rem 1rem .4rem 0rem !important;
                color: var(--eke-color-dark);
                font-size: 13.8px !important;
                font-weight: var(--eke-font-weight-medium) !important;
                line-height: 140%; /* 22.4px */
                letter-spacing: 0.2px;
                
                .sD_m_{
                    display: flex;
                    align-items: center;
                    color: inherit;

                    span{
                        padding-top: 0px;
                    }

                    svg{
                        width: 16px !important;
                        vertical-align: -0.175em;
                        margin-right: 0.5rem;
                    }
                }

                // svg{
                //     width: 5px;
                //     height: 22px;
                //     fill: $secondary;
                //     
                // }
                // .icon__acc_2{
                //     width: 1rem;
                //     vertical-align: -0.175em;
                //     display: inline-block;
                //     margin-right: 0.5rem;
                //     fill: var(--eke-bg-secondary-color);
                //     stroke-width: 1 !important;
                // }
                // .icon__acc_{
                //     font-size: 0.9rem !important;
                //     vertical-align: -0.175em;
                //     display: inline-block;
                //     margin-right: 0.5rem;
                //   }

                &:hover{
                   text-decoration: underline;
                    color: var(--eke-color-primary) !important;
                    .icon__acc_2{
                        fill: var(--eke-color-primary) !important;
                    }
                    
                }

                
                
            }

            .active{ 
                border-left: 3px solid var(--eke-border-primary-light-color) !important;
                color: var(--eke-color-primary) !important;
                //background-color: rgba(#fff, 0.9) !important;
            }
        }
