
.modal-lightColor .modal-content{
    border-radius: 16px !important;
    border: 1px solid var(--greyscale-100, #F5F5F5) !important;
    background: var(--others-white, #FFF) !important;
}

.modal-header-div{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 19px !important;

    button{
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }
}


.modal-darkColor .modal-content{
    border-radius: 16px !important;
    border: 1px solid var(--greyscale-100, #333) !important;
    background: var(--others-gray, #333) !important;
}