@use "../../../../../scss/abstract/mixin" as *;

.stream__col__4{
    width: 100%;
    height: auto;
    flex-shrink: 0;
    overflow: hidden;

    
    @include laptop(){
        width: 300px;
        display: flex;  
        flex-grow: 0;
        height: 100%;
        overflow: hidden;



    }


    .right__side_stream_div{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;



        .top__section__container{ 
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 10px;
            min-height: 50px;
            background-color: var(--eke-bg-light-color);
            border-bottom: 1px solid var(--eke-border-white-gray-color);


            .__tp_avatar_text__{
                display: flex;
                align-items: center;
                width: 100%;


                .t_avatar_rounded{
                    width: 50px;
                    height: 45px;
                    border-radius: 50%;
                    border: 1px solid var(--eke-border-white-dark-color);
                    padding: 0.1rem;

                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .t__title__{
                    color: var( --eke-body-text-light-color)  !important;
                    padding-left: 10px;
                    font-size: 14px !important;
                    line-height: 17.5555px;
                    font-family: var( --eke-body-font-family);
                    font-weight: var(--eke-font-weight-normal);

                }
            }

            ._tp__follow__btn{
                width: 150px;
            }
        }

        .bottom__section__container{

            width: 100%;
            height: 100%;
            background-color: var(--eke-bg-light-color);

            .all_p_section{
                padding: 10.555px 9.555px;
                padding-left: 2px !important;
                border-bottom: 1px solid var(--eke-border-white-gray-color);
              

                // h5{
                //     display: flex;
                //     align-items: center;
                //     font-size: 14px !important;
                //     padding-left: 10px;
                //     min-height: 20px;
                //     line-height: 1;
                //     color: $black;
                //     border-left: 2px solid var(--);
                //     margin-bottom: 0 !important;
                //     font-family: var( --eke-body-font-family);
                //     font-weight: var(--eke-font-weight-semibold);
                // }
            }


        // List product
            .list__user__product{
                width: 100%;
                padding: 5px 9.555px;
               height: 550px !important; //
               min-height: 550px !important; //
                overflow-x: hidden;
                overflow-y: auto;

          

                .list_p__item_uls{
                    padding-left: 0 !important;
                    display: flex;
                    flex-direction: row;
                    padding-left: 0;
                    width: 100%;
                    gap: 10px;
                    overflow-x: auto;
                    overflow-y: hidden;
                   
                 
     
                    @include laptop(){
                        flex-direction: column;
                        gap: 0px;
                        overflow-x: hidden;
                        overflow-y: hidden;
                    }
                }
            }
        }
    }
}