
.ListAddress_WrapperFrame{
    margin-top: 0px;
    width: 100%;

    .ListAddress_HeadingFlex{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 15px;

        button{
            padding: 0!important;
            padding-top: 10px !important;
            text-decoration: underline;
    
            span{
                font-size: 13px !important;
            }
    
        }
    }

    .ListAddressItem_Wrapper{
        max-height: 300px;
        min-height: 200;
        height: auto;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .ListSingleItem_DIV{
        padding-top: 4px !important;
        padding-right: 10px !important;
    }


    .ListAddress_Item{
        padding-top: 5px;
        padding-right: 25px;

        .Hr_horizontal{
            border: 0;
            border-bottom: 0.0625rem solid #f1f1f2;
            margin: 0;
            width: 98%;
        }

        
        .save__Button{
            margin-top: 5px;
            text-transform: uppercase;

            .BtnSpan{
                font-weight: 600 !important;
            }
            
        }
        
        .Cancel__Button{
            text-decoration: underline !important;
        }
    }
}
