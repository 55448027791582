
.dall_live_wrapper_al{
    width: 100%;
    min-height: 50vh;
    padding-top: 0.4rem; 
    padding-bottom: 2rem;
    
    .overall__pg_top{
        
        .Dlarge__b_img{
            //background-color: $primary-light;
            width: 100%;
            //background: url('../../../assets/images/c7febdfd-924d-41fd-a222-b3e95c9162ce.webp') no-repeat;
            min-height: auto;
            margin-bottom: 0rem;

            img{
                width: 100%;
                min-height: 65px;
                margin-top: 2.9rem;
            }

            @include media-breakpoint-up(lg){
                width: 100%;
                min-height: 250px;
                margin-bottom: 1.0rem;

                img{
                    margin-top: 0rem;
                    min-height: auto;

                }
           }
        }

        .main__container_wrapper{
            display: flex;
            align-items: center;
            justify-content: center;

            .container{
                width: 100% !important;
                //@include whiteGlassMorphism;
                
                @include media-breakpoint-down(md){
                    padding: .95555rem 1rem;
                }
    
                @include media-breakpoint-up(lg){
                    width: $containerwidth !important;
                    max-width: $maxWidth !important;
                    margin: 0 auto;
                }
    
                @include media-breakpoint-up(xl){
                    width: $containerwidth !important;
                    max-width: $maxWidth !important;
                    margin: 0 auto;
                }

                .col__h3{
                    margin-top: 0rem;

                    h3{
                        font-size: 20px;
                        color: $secondary;
                        padding: 16px 0;
                        @extend .fontFamily800;

                        @include media-breakpoint-up(xl){
                            font-size: 26px;
                        }
                    }
                }

               
            }
        }

    }

}