
.ListItem__Flex{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
}


.ListIBox__OrderItem{
    display: flex; 
    align-items: flex-start;
}

.ListItem_TypographyDiv{
    margin-top: 0.8rem;
}

// .ListItem_TypoDiv{
//     span{
//         // color: rgb(104, 100, 100);
//     }
// }