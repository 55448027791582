.avatar {
    display: block;
  }
  .isCircle {
    border-radius: 50%;
    //overflow: hidden;
  }
  .isCircle:after {
    border-radius: 50%;
  }
  .mini {
    width: 16px;
    height: 16px;
    font-size: 7.25px;
    line-height: 9px;
  }
  