

.QuantityBtn__Wrapper{
    width: 100px;
    
    .QuantityBtn__div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        border: 1px solid #ddd;
        height: 25px;

        .QuantityBtn__DisplayNo_Div{

            .QuantityBtn__Display_No{
                display: inline-block;
                font-size: 13px;
                vertical-align: middle;
                padding-bottom: 0.2rem;
            }

            input{display: none;}
        }

        .QuantityBtn__span{
            width: 30px;
             height: 100%;
            background-color: #fbf9f9;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg{
                font-size: 13px !important;
                text-align: center;
            }
        }

        .QuantityBtn__Reduce{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .QuantityBtn__Increase{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .disabled{
        background-color: #f0ebeb7a;
        color: rgba(0,0,0,.5);
        opacity: .4;
        cursor: none;
    }

}