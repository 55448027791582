@use "../../scss/abstract/mixin" as *;

.AppHeader_header {
    position: relative;
    z-index: 1;
    margin-bottom: -1px;
    padding-left: 29px;
    padding-right: 0px;

    @include laptop(){
      padding-left: 20px;
    }
  }

  .AppHeader_centered{
    text-align: center !important;
  }
  .AppHeader_inner {
    width: 100%;
    display: block;
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
    align-items: center;
    
    @include tablet(){
      padding-bottom: 10px;
      display: flex;

    }
  }

  .AppHeader_Responsive{
    align-items: center !important;
    flex-direction: column !important;
    @include tablet(){
    align-items: center !important;
      flex-direction: row !important;
    }
  }
  .AppHeader_hasTabs .AppHeader_inner {
    padding-bottom: 40px;
  }
  .AppHeader_marginBottom {
    margin-bottom: 0px;
    
    @include laptop(){
      margin-bottom: 20px;
    }
  }

  .AppHeader_spreadForApp {
    margin-top: -20px;
    margin-left: -20px;
    margin-right: 0px;
    padding-left: 20px;
    padding-right: 5px;

    @include tablet(){
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .AppHeader_title {
    flex: 1;
    margin: 0;
    min-width: 0;
    //white-space: nowrap;
    text-overflow: ellipsis;
  }
  .AppHeader_hasBreadcrumbs .AppHeader_title {
    margin-top: 12px;
  }
  .AppHeader_actions {
    display: flex;
    align-items: center;
  }
  .AppHeader_actions > * {
    margin-left: 1px;

    @include laptop(){
      margin-left: 8px;

    }
  }
  .AppHeader_breadcrumbsWrap {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 0;
  }

  .AppHeader_titleSub {
    display: block;
    word-wrap: break-word;
    color: #464a55;
    font-size: 15px;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-light) !important;
    line-height: 1.42857143;
    margin-top: 4px;
  }
  @media screen and (max-width: 599px) {
    .AppHeader_breakActionsOnMobile .AppHeader_inner {
      display: block;
    }
    .AppHeader_breakActionsOnMobile .AppHeader_actions {
      margin-top: 16px;
      justify-content: center;
    }
  }

  .AppHeader_Title_Typography{
    font-size: 23.5px;
    line-height: 45px;
    padding-bottom: 0px;
    padding-top: 15px;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-extrabold);
    text-transform: capitalize;

    
    @include laptop(){
      font-size: 29.5px;
      line-height: 40px;

    }

    // @include media-breakpoint-up(lg){
    // }
 }

 .AppHeader_isLink{
  text-decoration: none;
  color: inherit;
 }
  
  // @media (max-width: 600px) {
  //   .AppHeader_inner {display: block; padding-bottom: 15px;}
  //   .AppHeader_actions > * {
  //     margin-left: 0px;
  //     margin-right: 8px;
  //     margin-top: 10px;
  //   }
  //   .AppHeader_header{padding-left: 15px;}
  
  //   .AppHeader_actions {
  //     display: -webkit-box;
  //   }
  // }
