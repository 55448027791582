$fontfamily: var(--eke-body-font-family);

.fontFamily300{
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-light) !important;
}

.fontFamily400{
    font-family: var(--eke-body-font-family)  !important;
    font-weight: var(--eke-font-weight-normal) !important;
}
.fontFamily500{
    font-family: var(--eke-body-font-family)  !important;
    font-weight: var(--eke-font-weight-medium) !important;
}
.fontFamily600{
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-semibold) !important;
}
.fontFamily700{
    font-family: var(--eke-body-font-family)  !important;
    font-weight: var(--eke-font-weight-bold) !important;
}
.fontFamily800{
    font-family: var(--eke-body-font-family)  !important;
    font-weight: var(--eke-font-weight-extrabold) !important;
}
.fontFamily900{
    font-family: var(--eke-body-font-family)  !important;
    font-weight: var(--eke-font-weight-extrabold) !important;
}
