

.mb_planet_ {
    width: 100%;
    background-color: $bg-green;
    margin-bottom: 5em;

    .container {
        width: 100% !important;
        max-width: $maxWidth !important;
        height: auto;

        
      

        @include media-breakpoint-up(lg){
            width: 100% !important;
            max-width: $maxWidth !important;
            margin: 0 auto;
           height: 100vh;

        }

        @include media-breakpoint-up(xl){
            width: 100% !important;
            max-width: $maxWidth !important;
            margin: 0 auto;


        } 

        .row{
            .column{
              padding-left: 0px;

              @media (min-width: 768px) {
                padding-left: 18px;
    
             }
              
              @include media-breakpoint-up(lg){
                padding-left: 78px;

              }

                .lite-block{
                    padding: 20px 20px;
                  
                    h2{
                        width: 90%;
                        font-size: 70px;
                        margin-bottom: 30px;
                        line-height: 75px;
                        color: var(--eke-body-text-secondary-color);
                        @extend .fontFamily800;

                       
                    }

                    p{
                        font-size: 14px;
                        color:#0A54B1;
                        font-family: var(--eke-body-font-family);  
                        font-weight: var(--eke-font-weight-normal);
                        span{
                            color: var(--eke-body-text-primary-color) !important;
                        font-weight: var(--eke-font-weight-medium);


                        }
                    }

                    @include media-breakpoint-down(md){
                     
                        h2{
                            width: 100%;
                            font-size:40px ;
                            line-height: 45px;
                            font-weight: 500;
                            text-align: center;

                        }
                        p{
                            text-align: center;
                        }

                     }
    
                }
            }
            .column_lite_mg{
                padding-right: 0 !important;
                padding: 0 !important;

                .lite-svg-image{
                 
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;

                    svg{
                        margin-top: -18px;
                    }

                    @include media-breakpoint-up(lg){
                        position: absolute;
                        right: 0;
                        align-items: center;
                        
                        svg{
                            margin-top: 0;

                        }


        
                      }
                }
            }
        }
    }
}