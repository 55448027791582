.svgLoader {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  color: #8792a2;
  transform-origin: 50% 50%;
  transition-timing-function: ease;
  transition-property: opacity, transform;
  animation: SpinnerAnimationShow 0.25s ease normal,
    SpinnerAnimationRotation 0.6s linear infinite;
}

@keyframes SpinnerAnimationShow {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes SpinnerAnimationRotation {
  to {
    transform: rotate(1turn);
  }
}

.largeLoader{
  width: 28px !important;
  height: 28px !important;
  stroke-width: 5;
}

.xlargeLoader{
  width: 38px !important;
  height: 38px !important;
  stroke-width: 5;
}

.smallLoader{
  width: 16px !important;
  height: 16px !important;
}

.Span{
  width: 100%;
}


.padding{
  padding: 1.2555rem !important;
}
  
.isCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
  
