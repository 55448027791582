.sc-fotOHu{

    .row{

        @include media-breakpoint-down(md){

            .add__button_col.col-lg-3{
                width: auto !important;
            }

            .add__button_col{
                margin-top: -1rem !important;
                margin-bottom: 18px;
            }
          }


    }
}


.addmore_feature{
    display: flex;
    align-items: center;
    font-size: 13px !important;
    color: #fff !important;
    padding: 0.3rem 0.7rem !important;
    box-shadow: none;
    @extend .fontFamily400;
    
    &:hover{
        color: #fff !important;
        opacity: .9;

    }

    svg{
        font-size: 15px !important;
    }

}

.dissmiss__button{
    position: absolute;
    padding: 0.3rem 0.3rem;

    top: 0;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 1;
    background-color: rgba($secondary, $alpha: 0.8);
    border-radius: 50%;
    cursor: pointer;

    svg{
        font-size: 13px!important;
    }
}

.submit__btn{
    font-size: 14px !important;
    color: #fff !important;
    font-family:  greyCliffcflight;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

._add_product_{
    
    
    .row__col_d{
        justify-content: space-between;
        .col__div{
            
            .heading_d{
                font-size: 22px;
                font-family: $fontfamily !important;
                font-weight: 600 !important;
                margin-bottom: 1.1rem;
           }

           p{
               font-family: $fontfamily;
               font-size: 14px;
               color: rgb(143, 140, 140);
               @extend .col-10;
           }
        
    }
    .col__button{
            display: flex;
            justify-items: flex-end;
            .outline__style, .outline_icon{
                font-size: 13.5px;
                font-family: $fontfamily;
                border-color: rgb(143, 140, 140);
                box-shadow: none !important;
            }
            .outline_icon{
                margin-left: 0.4rem;
            
            }
    }
   }


   .collection_container{
       .sub__coll__card{
           @extend .card;
           padding: .99625rem .855rem;
           background-color: #f6f8fa;
       }
       
        .collection__heading{
            
            font-size: 15px;
            font-weight: 600;
            font-family: $fontfamily;
            color: rgba($secondary, $alpha: 1);
        }

        .formgroup__wrapper{



            label{
                text-transform: uppercase;
                font-size: 12.5px;
                color: $secondary;
                font-family: $fontfamily;
            }

            .css-6j8wv5-Input{
                cursor: pointer !important;
                padding: .4625rem .855rem;
            }

            .offer__range__label{
                font-size: 13px;
                font-family: $fontfamily;
                color: gray;
            }

            .form-control{
                padding: .6625rem .855rem;

            }

            .form-control, .css-6j8wv5-Input,.css-qc6sy-singleValue,.css-14el2xx-placeholder, 
            .css-jzldcf-Input{
                font-size: 13.2px !important;
                font-family: $fontfamily;
          }
        }

        

   ._coll_add_product_{

      .row{
        @include media-breakpoint-down(md){
          flex-direction: column !important;
        }

        .section__left{
            
            .group__div{

                h5{
                    span{
                        font-size: 15px;
                        font-weight: 600;
                        font-family: $fontfamily;
                        color: rgba($secondary, $alpha: 1);

                    }
                    svg{
                        width: 11px !important;
                        margin-left: 0.5rem;
                        height: auto;
                        cursor: pointer !important;
                        fill: rgb(211, 209, 209) !important;
    
                    }
                }

                .group_div__Span{
                    font-size: 13.5px;
                }
                  
                .brand__ui__container{
                    display: flex;
                    align-items: center;

                    .custom-select{
                        flex-grow: 1;

                        .css-b62m3t-container{

                            .css-p8wmd7-control{ 
                                border-bottom-right-radius: 0 !important;
                                border-top-right-radius: 0 !important;
                                border-right-color: #eee;
                                

                                .css-319lph-ValueContainer{
                                    
                                    .css-6j8wv5-Input{

                                    }
                                }

                            }
                        }

                    }
                    
                    .btn__new_brand{
                        background-color: #fff;
                        border-top: 1px solid hsl(0, 0%, 80%);
                        border-bottom: 1px solid hsl(0, 0%, 80%);
                        border-right: 1px solid hsl(0, 0%, 80%);
                        border-left: 1px solid hsl(0, 0%, 80%);
                        padding: 12.5555px 0;
                        width: 38px;
                        // border-bottom-right-radius: 4px !important;
                        // border-top-right-radius: 4px !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @extend .shadow-sm;

                        &:focus{
                            border: none !important;
                            box-shadow: none !important; 
                        }

                        svg{
                            font-size: 17px;
                            text-align: center;
                            color: rgb(65, 64, 64);
                        }

                        &:disabled {
                            background: #eee !important;
                            cursor: default;
                          }
                    }

                }
                    .add___pl___o{
                        p{
                            display: flex;
                            align-items: center;

                            span {
                                font-size: 12px;
                                color: #60A5FA;
                                cursor: pointer;
                                display: block;
                                margin-bottom: 0.2rem;
                                @extend .fontFamily400;

                                &:hover {
                                    opacity: 0.6;
                                }
                            }
                        }
                    
                }
            }

        }

        .section__right{

            .group__div{

                h5{
                    span{
                        font-size: 15px;
                        font-weight: 600;
                        font-family: $fontfamily;
                        color: rgba($secondary, $alpha: 1);

                    }
                    svg{
                        width: 11px !important;
                        margin-left: 0.5rem;
                        height: auto;
                        cursor: pointer !important;
                        fill: rgb(211, 209, 209) !important;
                    }
                }
            }

        }
      }

    }

      ._coll_p__d{
          margin-top: 3rem;

          .form-check{
            input{cursor: pointer;}

            .form-check-label{
                font-size: 13px !important;
                font-family: $fontfamily !important;
                cursor:  pointer;
            }
        }
  
            .group__div{

                h5{
                    span{
                        font-size: 15px;
                        font-weight: 600;
                        font-family: $fontfamily;
                        color: rgba($secondary, $alpha: 1);

                    }
                    svg{
                        width: 11px !important;
                        margin-left: 0.5rem;
                        height: auto;
                        cursor: pointer !important;
                        fill: rgb(211, 209, 209) !important;
                    }
                }
            }
        }
   }


}

