
.AccordionCustom_Wrapper{
    width: auto;
    padding-bottom: 10px;

    &__Header{
        font-size: 14px;
        font-weight: 600;
    }

    &__Body{

        ul{
            list-style: none;
        }

        a{
            text-decoration: none;
        }
    }
}