
.visble-sidebar{
    display: flex;
}

.not-visble-sidebar{
    display: none ;
}

.dashboard-custom-sidebar{
   // position: absolute;
    // background-color: #1E293B !important;
    // //display: none;
    // width: 250px;
    // left: 0;
    // top: 0;
    // flex-direction: column;
    // justify-content: space-between;
    // transition: 0.3s all ease-in-out;
    // z-index: 999999;
    // overflow-y: auto;
    // overflow-x: hidden;
    // height: 100vh;



    // @media (min-width: 1200px) {
    //  position: fixed;
    //  display: flex !important;
    //  width: 15rem;
    // height: 100vh;

    // }

    // .dashboard-brand{
    //     padding-top: 0.5rem;
    //     padding-bottom: 0.5rem;
    //     border-bottom: 1px solid rgba($color: #323e4e, $alpha: .4);
    // }

    // .switch-buyer{
       
    // }

    .dashboard-menu-links{
        .top-menu-list{
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .topp-menu-list{
            margin-bottom: 15px;
            padding-left: 0 !important;
        }

        .to-menu-list{
            padding-left: 0 !important;
        }

        // .menu-title{
        //     padding-left: 18.55px !important;
        //     margin-bottom: 0px;

        //     h5{
        //         font-size: 13px;
        //         padding-bottom: 2px;
        //         color: rgba($color: #ddd, $alpha: .7);
        //         text-transform: uppercase;
        //         letter-spacing: 2px;
        //         margin: 0;
        //         @extend.fontFamily800;
                
        //     }
        // }


        ul{
            list-style: none !important;
            margin-top: 1rem;

            // .active{
            //     border-right: 0.3rem solid $primary-light;

            //     a{
            //         color: #fff;
            //         @extend .fontFamily500;
            //     }
            // }

            .sub-menu{
                // .sub-menu-href{
                //     display: flex;
                //     align-items: center;
                //     justify-content: space-between;

                //     .left-icon-text{
                //         display: flex;
                //         align-items: center;
                //         gap: 1rem;
                //     }
                //     .icon-arrow{
                //         padding-right: 15px;
                //         font-size: 19.95px;
                     
                //     }
                // }

                

                
                
                // .sub-menu-ul{
                //     background-color: #323e4e !important;
                //     padding-top: 2px !important;
                //     padding-bottom: 2px !important;
                //     margin-top: 2px !important;
                //     margin-bottom: 2px !important;
                //     transition: .3s all ease-in-out;
                //     overflow: hidden;
                // }

                .visible-dropdown{
                    display: block !important;
                }
                .not-visible-dropdown{
                    display: none !important;
                }
            }

            // .menu-item, .sub-menu{
            //     margin: 1rem 0;
            //     list-style-type: none;
                
                
            //     a{
            //         padding-left: 25.55px !important;
            //         width: 100%;
            //         height: auto;
            //         text-decoration: none;
            //         color:  #adadad; // rgb(93, 98, 145);
            //         display: flex;
            //         align-items: center;
            //         gap: 1rem;
            //         //color: var(--greyscale-700, #616161);
            //         font-family: var(--eke-body-font-family);
            //         font-size: 13.5px;
            //         font-weight: var(--eke-font-weight-normal) !important;
            //         line-height: 140%; /* 22.4px */
            //         letter-spacing: 0.2px;

            //         span{
            //                 display: inline-block;
            //                 font-size: 14px;
            //                 height: 35px;
            //                 line-height: 35px;
            //                 min-width: 35px;
            //                 text-align: center;
            //                 width: 35px;
            //                 background-color: transparent!important;
            //             }
            //         transition: 0.3s ease-in-out;

            //         &:hover{
                        
                        
            //         }
            //     }
            // }

        }

    }
    .dashboard-live-ad{
        color: white;
        background-color: $primary-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.6rem;
        margin: 0 1rem;
        border-radius: 2rem;
        margin-bottom: 1rem;
        padding: 1rem;
        text-align: center;
        @extend .shadow;
        .ek_dashboard_img{
            margin-bottom: 0.5rem;
           
            img{
                height: 60px;
            }
        }
        h3{
            font-size: 20px;
            margin-bottom: 0.2rem !important;
            @extend .fontFamily800;
        }
        h5{
            font-size: 12px;
            margin-top: 0rem !important;
            line-height: 18px;
            @extend .fontFamily300;

        }
        button{
            color: $primary-light;
            font-weight: 700;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 2rem;
            margin-bottom: 0.2rem;
        }
    }
}