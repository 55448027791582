
.inputPrepend__Level{
    display: flex;
    align-items: center;
    width: auto;
    margin-bottom: 0.5rem;

    span{
        font-size: 13.5px;
        // margin-right: 0.8rem;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal);

    }
}