
@use "../../scss/abstract/fonts.scss" as fonts;

.Custom__Alert {
    width: 100%;
    min-height: 40px;

   .Container__Alert { 
       width: 100%;
       padding-top: 0.3rem;

       .Alert_Danger {
        border-color: #ef8e92 #ef8e92 #ef8e92  var(--eke-border-danger-color);
        color: var(--eke-body-text-danger-color);
       }

      .Alert_Success {
        background: #eaf3e6;
        border-color: #95c381 #95c381 #95c381 var(--eke-border-success-color);
        color: var(--eke-body-text-success-color);
       }
       
       .Alert {
         border-radius: 0.25rem;
         border-style: solid;
         border-width: 0.0625rem 0.0625rem 0.0625rem 0.25rem;
         box-sizing: border-box;
         display: flex;
         font-size: .875rem;
         line-height: 1.25rem;
         padding: 0.4375rem 1rem 0.4375rem 0.75rem;
         width: 100%;

           .AlertIcon{
            margin-right: 0.5rem;
           }
       
           .Alert__Span{
            font-size: 14px !important;
            text-transform: capitalize !important;
            column-gap: 1rem;
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.25rem;
           }
       }

   }

}