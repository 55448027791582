@use "../../scss/abstract/fonts" as fonts;

.StockEmptyWrapper{
    z-index: 99;
    
    .StockEmptyWrapper__sub{
        background-color: #ededed;
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
        color: red;


        .StockEmptySpan{
            font-size: 13px; 
            color: red;
            margin-left: 5px;
            @extend .fontFamily300;

        }
    }
}