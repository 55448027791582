

.ProfileRow__Parent{
    clear: both;
}

.pac-container {
    z-index: 99999999999 !important;
}

.MarginTop{
    padding-top: 16px;
}

.Section__Group{
    margin-bottom: 0.1rem;

    .Row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 4px;
        padding-bottom: 8px;

        &__Button{
            display: inline;
            font-size: 14px !important;
            padding: 0 !important;
            text-decoration: underline;
            cursor: pointer;
        }

        &__Button_Verified{
            display: inline;
            font-size: 14px !important;
            padding: 0 !important;
            text-decoration: underline;
            cursor: default !important;
        }

        &__Heading{
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }
}
.Hr_horizontal{
    border: 0;
    border-bottom: 0.0625rem solid #f1f1f2;  // #e3e4e5;
    margin: 0;
    width: 100%;
}

.FormGroup__DIV{
    margin-top: 1.5rem;
}

.Edit__Button{
    padding: 0 !important;
}

.EditTypograph_Heading{
    font-size: 14.3px !important;
    font-weight: var(--eke-font-weight-medium) !important;
    color: var(--eke-body-text-gray-dark-color) !important;
}