
.eke-Pagination__container{
    height: auto;
    box-sizing: content-box;
    padding: 18px 0px;
    background: var(--eke-bg-light-color);
    border-radius: 8px;
    margin-top: 20px;

    .next-pagination {
        display: inline-block;
        box-sizing: content-box;
        height: auto;

        ul{
            list-style: none !important;
            display: flex;
            align-items: center;
            padding: 0 !important;
            margin: 0 !important;
            

            li{
                margin-left: 10px;

                a{
                    border-radius: 2px;
                    padding: 0 10px;
                    text-decoration: none;
                    position: relative;
                    display: inline-block;
                    box-shadow: none;
                    text-decoration: none;
                    text-align: center;
                    text-transform: none;
                    white-space: nowrap;
                    vertical-align: middle;
                    user-select: none;
                    transition: all .3s ease-out;
                    cursor: pointer;
                    box-sizing: border-box;
                    border: 1px solid #ccc!important;
                    background-color: var(--eke-color-white)!important;
                    height: 32px;
                    line-height: 29.7px;
                    font-size: 13.8px;
                    font-family: var(--eke-body-font-family);
                    font-weight: var(--eke-font-weight-normal);
                }
            }

            li.selected{

                a{
                   background: var(--eke-bg-primary-color) !important;
                   color: var(--eke-body-text-light-color) !important;
                   border: none !important;
                   line-height: 29.7px;
                }
            }

            li.previous{
                margin-left: 0px !important;

                a{
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    color: var(--eke-body-text-secondary-color) !important;
                }
            }

            
            li.next{
                a{
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    color: var(--eke-body-text-secondary-color)  !important;
                }
            }

            li.disabledbtn{
                a{
                    color: #ccc !important;
                    border-color: #eee !important;
                }
            }
        }
    }

   



    .jump--ara_ {
        display: inline-flex;
        align-items: center;
        vertical-align: top;

       span {
            margin-left: 10px;
            font-size: 14px;
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-normal);
        }

        .total-page {
            line-height: 36px;
        }

        .next-input.next-large {
            vertical-align: top;
            border-radius: 4px;
        }

        .next-input{
            border-radius: 2px!important;
            width: 55px !important;
            height: 32px!important;
            
            input{
                width: 55px !important;
                height: 32px!important;
                padding: 0 12px;
                font-size: 14px;
                border: none;
                outline: none;
                border-spacing: 0;
                transition: all .3s ease-out;
                border: 1px solid #ccc;
                background-color: var(--eke-bg-light-color);
            }
        }

        .j-btn {
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 4px 15px;
            min-width: 50px;
            height: 32px;
            display: inline-block;
            cursor: pointer;
            vertical-align: top;
        }

    }
}