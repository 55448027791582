@use "../../../../scss/abstract/mixins.scss" as mixins;
@use "../../../../scss/abstract/colors.scss" as colors;

.tippy_custom_styles {
    box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
        0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
    overflow: hidden;
    /* border: ; */
    border-radius: 4px;
    overflow: hidden;
}
.tippy_custom_styles > div {
    padding: 0 !important;
}

.dropdown_menu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
}
.divider {
    height: 1px;
    margin: 5px 1px;
    background-color: #e5e5e5;
    border-bottom: 1px solid colors.$white;
}


.exportButton{
background: colors.$white;
  border: none;
  font-size: 13.5px;
  outline: none !important;
  font-family: 'Noto Sans', sans-serif;
  //box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 1px 1px 0 rgb(0 0 0 / 12%), 0 0 0 1px rgb(60 66 87 / 16%), 0 0 0 0 transparent, 0 0 0 0 transparent, 0 2px 5px 0 rgb(60 66 87 / 8%);
    margin-right: 10px;
    @include mixins.boxShadow;
    
}

.exportButton:focus{
    outline: none !important;
    @include mixins.boxShadow;

}

.btn{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
  }