
.Comment_Text{
    width:170px;
    display:block;
    display: -webkit-box;
    line-height: 1.5;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}