.cancel_button{
    color: red;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
  }
  
  .mb-display{
    display: none;
  }
  
  .ml_dash{
    margin-left: 21px;
  }
  
  .dash_top{
    margin-top: 1px;
  }
  // #basic-navbar-nav{
  //   display: none !important;
  // }
  .frow{
    display: flex;
    align-items: center;
  }
  
  .show-mobile{
    display: flex !important;
  }

  .collectionSection{
    color: black;
    text-decoration: none;
    padding: '6px';
    cursor: pointer;
    height: '162px' 
  }
  
  .slider_top{
    margin-top: 10px;
  }
  
  .hrline {
    margin-left: 15px;
    border: 5px solid #dcdee3;
    border-radius: 3px;
    overflow: hidden;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 15px;
  }
  
  $breakpoint-phone: 553px;
  $breakpoint-tablet: 768px;
  $breakpoint-small: 1024px;
  @media (max-width: $breakpoint-phone) {
    .dashboard_inner_bar{
      position: relative !important;
      height: 100vh;
      
    }
  
    .slider_top{
      margin-top: 160px;
    }
  
    .dash_top{
      margin-top: 45px !important;
    }
  
    .ml_dash{
      margin-left: 0px;
    }
  
    .mb-display{
      display: block;
    }
    #basic-navbar-nav{
      display: none;
    }
  
    .mobile_none{
      display: none !important;
    }
  
    .navbar-expand-lg{
      position: fixed !important;
      z-index: 99;
      width: 100%;
    
    }
  }
  
  @media (max-width: $breakpoint-tablet) {
    .dashboard_inner_bar{
      position: relative !important;
      height: 100vh;
      
    }
  
    .dash_top{
      margin-top: 45px !important;
    }
  
    .ml_dash{
      margin-left: 0px;
    }
  
    .mb-display{
      display: block;
    }
    #basic-navbar-nav{
      display: none;
    }
  
    .navbar-expand-lg{
      position: fixed !important;
      z-index: 99;
      width: 100%;
    
    }
  }
  
  @media (max-width: $breakpoint-small) and  (min-width: $breakpoint-small){
    .ml_dash {
      margin-left: 100px;
    }
  }
  
  .top__htext{
    font-size: 18px;
    font-weight: 700;
  }
  
  .sm-text{
    font-size: 13px;
  }
  
  .xfloat{
      position:fixed;
      width:60px;
      height:60px;
      bottom:40px;
      right:40px;
      background-color:#0C9;
      color:#FFF;
      border-radius:50px;
      text-align:center;
      box-shadow: 2px 2px 3px #999;
  }
  
  .my-float{
      margin-top:22px;
  }