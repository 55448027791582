@use "../../scss/abstract/mixin" as mixin;

.CardBox_Container{
    width: 100%;
    border-radius: 0.5rem;
    background-color: var(--eke-bg-light-color);
    margin-bottom: 10px;
}

.CardBox_Pad{
    padding: 24px;
}

.CardBox_FlexAlign{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// .CardBox_Typography{
//     font-size: 15px !important;
//     font-weight: var(--eke-font-weight-medium) !important;
//     color: var(--eke-body-text-gray-dark-color) !important;
// }
.Shadow{
    @include mixin.boxShadow;
}