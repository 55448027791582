@use "../../scss/abstract/mixin.scss" as *;

.ButtonGroup__Wrapper{
    display: flex;
    align-items: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    @include laptop(){
        flex-direction: row;
    }
    
}

.pos_Left{
    justify-content: flex-start;
}

.pos_Right{
    justify-content: flex-end;
}