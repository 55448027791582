@use "../../../scss/abstract/mixin" as *;
@use "../../../scss/abstract/fonts" as fonts;
@use "../../../scss/abstract/colors" as colors;
//@use "../../../scss/abstract/mixins" as *;
@use "../../../scss/abstract/util" as *;


.browse__collection{
    width: 100%;
    min-height: 500px;
    height: auto;
    padding-bottom: 1rem !important;

    @include laptop(){
        padding-bottom: 2rem;
    }

    @include desktop(){
        padding-bottom: 2rem;
    }

    .container{
        //padding: 0;
        margin-top: 0;
        background-color: var(--eke-color-white);
        min-height: 500px;
        width: 100% !important;
        max-width: $maxWidth !important;
 
         @include laptop(){
            background-color: transparent;
             width: 100% !important;
         }
 
         @include desktop(){
             width: $containerwidth !important;
             background-color: transparent;
         } 
         
         @media (min-width: 1000px) and (max-width: 1300px) {
            background-color: transparent;
             width: 100% !important;
          }
       
    
        .all_collection_heading{
            width: 100%;
            padding-top: 10px;
            padding-bottom: 0px;
            margin-bottom: 0px;
            border-bottom: none;

            h5{
                margin-left: 24px;
                margin-right: 24px;
                font-size: 18px;
                color: var(--eke-body-text-dark-color);
               @extend .fontFamily700;

            }
            @include laptop(){
                 padding-top: 18px;
                margin-top: 0px;
                margin-bottom: 10px;
                border-bottom: 1px solid colors.$border-light;

                h5{
                    padding-top: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                    font-size: 24.5px;
                    line-height: 40px;
                    font-weight: 800 !important;
                }
           }

           @include desktop(){
            padding-top: 18px;
           margin-top: 0px;
           margin-bottom: 10px;
           border-bottom: 1px solid colors.$border-light;

           h5{
               padding-top: 10px;
               margin-left: 0px;
               margin-right: 0px;
               font-size: 24.5px;
               line-height: 40px;
               font-weight: 800 !important;

           }
      }
        }

        .main__all_collection_{
            margin-top: 0rem;

            @include laptop(){ margin-top: 1.5rem;}
            @include desktop{ margin-top: 1.5rem;}

            .single__col_mobile{
                display: block;
                width: 100%;

                @include laptop(){
                    display: none;
                }

                @include desktop(){
                    display: none;
                }

                .accordion{
                    width: 100%;

                    .accordion-item{
                        padding: 8px;
                        border-radius: 0 !important;
                        border-left: none;
                        border-right: none;
                        border-color: #f1f1f2 !important;

                        .accordion-header{
                            .accordion-button:not(.collapsed) {
                                color: var(--eke-body-text-dark-color);
                            }
                            .accordion-button:not(.collapsed)::after {
                                transform: rotate(-180deg);
                            }

                            .accordion-button{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background-color: transparent;
                                box-shadow: none !important;

                                &:focus{
                                    box-shadow: none !important;
                                }

                                .accord__header_sub{
                                    
                                    
                                    h5{
                                        padding-top: 0.2rem;
                                        font-size: 14.3px;
                                        margin-left: 24px;
                                        color: #2e2f32;
                                        @extend .fontFamily600;
                                    }
                                }
                            }
                        }

                        .accordion-body{
                            padding-top: 0;
                            padding-left: 0;
                            ._list{
                                list-style: none;
                                padding-left: 50px !important;
                                padding-top: 8px;

                                li{
                                    padding-bottom: 0;
                                    padding-top: 0;

                                    a{
                                        display: block;
                                        color: colors.$textgray;
                                        font-size: 14px;
                                        text-decoration: none;
                                        padding-bottom: 8px;
                                        padding-top: 8px;

                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }


                    }
                }
            }

            

            .row{
               
                .single__col{
                    margin-bottom: 24px;
                    display: none;

                    @include laptop(){
                        display: block;
                    }

                    @include desktop(){
                        display: block;
                    }

                    .card{
                        border: none;
                        border-radius: 8px;
                        @include boxShadow;
                        backdrop-filter: blur(10px);
                        box-sizing: border-box;
                        min-height: 550px;

                        

                        .card-body{
                            padding: 26.333px 26.33px !important;

                            .left__v_content{
                                display: flex;
                                flex-direction: column;
                                max-width: calc(100% - 150px);

                                .m0a{
                                    line-height: 29px;
                                    margin-bottom: 10px;

                                    a{
                                        text-decoration: none;
                                        font-size: 17px;
                                        color: var(--eke-body-text-dark-color);
                                        @extend .fontFamily700;
                                    }
                                }

                                .list{
                                    list-style: none;
                                    padding-left: 0 !important;
                                    padding-top: 8px;

                                    li{
                                        padding-bottom: 0;
                                        padding-top: 0;

                                        a{
                                            display: block;
                                            color: var(--eke-color-secondary);
                                            font-size: 14px;
                                            text-decoration: none;
                                            padding-bottom: 8px;
                                            font-weight: var(--eke-font-weight-normal);
                                            font-family: var(--eke-body-font-family);
                                            padding-top: 8px;

                                            &:hover{
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}