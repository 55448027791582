
.CardBox_AddressList{
    padding: 24px !important;
    padding-top: 26px !important;
    padding-bottom: 35px !important;
    display: flex;
    align-items: center;

    .AddressItem_Typography{
        width: 100%;
        flex-grow: 1;

        .Address_Para{
            margin-top: 5px !important;
            margin-bottom: 0 !important;
        }
    
        .AddressDefault{
            margin-top: 8px;
            display: flex;
            align-items: center;
            gap: 3px;
            line-height: 1.5rem;
            font-size: .65rem !important;
            padding: 0 !important;
            //color: var(--eke-body-text-gray-color);
            font-weight: var(--eke-font-weight-light) !important;
    
            svg{
                width: 0.85rem !important;
                fill: var(--eke-bg-success-color);
            }
        }
    }

    .AddressItem_Button_Group{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .Address_BorderHoz{
            padding-left: 10px;
            padding-right: 15px;
            margin-bottom: 0.3rem;
        }

        .BtnSpan{
            font-size: 13px !important;
        }

        .AddressItem_Button{
            padding: 0 !important;
            text-decoration: underline;
        }
    }
}