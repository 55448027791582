
.Radio__Container{
    position: relative;
    padding-left: 0px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    gap: 2px;

    label{
      cursor: pointer;
    }
    .form-check-label{
        margin-left: 0 !important;
    }
}

.Radio_input{
  cursor: pointer !important;
}


.Radio_SVG_{
    margin-right: 12px;
    box-sizing: border-box;
    color: #000;
    padding: 2px;
    display: block;
    fill: none;
    flex: none;
    height: 18px;
    pointer-events: none;
    width: 18px;
    border: none;
    z-index: 1;
    border-radius: 50%;
}

.RadioPrimary{
    border: 1px solid var(--eke-color-primary);
}

.RadioSuccess{
    border: 1px solid var(--eke-color-success);
    
}
.Radio__Container input:checked ~ .RadioPrimary {
    fill: var(--eke-color-primary);
  }

.Radio__Container input:checked ~ .RadioSuccess {
  fill: var(--eke-color-success);
}

.Radio__Container:hover input ~ .Radio_SVG_ {
    fill: #eee;
  }

.RadioDisabled:disabled ~ .Radio_SVG_{
    fill: transparent;
    border: 1px solid #ddd;
    cursor: not-allowed !important;
    
}

.Radio__Container input:disabled {
    cursor: not-allowed !important;
  }

.RadioDisabled_Label{
    opacity: .6;
}
  