@use "../../../../../scss/abstract/mixin" as *;


.FlexBox{
    display: flex;
    align-items: center;
}

.CardBox_Review{
    position: relative;
    display: flex;
    align-items: flex-start;

    &__Image{
        margin-right: 0.2rem;
    }

    .FlexItem__Button{
        width: auto;
        margin-top: 18px;

        @include laptop(){
          margin-top: 0;
        }
    }

    .Item_Typography{
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        margin-top: 0.8rem;
        min-height: 10vh;

        h5{
            flex-grow: 1;
        }

        @include laptop(){
            align-items: center;
            justify-content: space-between;
        flex-direction: row;

        }


    }

    .OrderNo{
        margin-left: 5px;
        margin-top: 0.1rem;

    }

    .CompletedOrder{
            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 3px;
            line-height: 1.5rem;
            font-size: .65rem !important;
            padding: 0 !important;
            //color: var(--eke-body-text-gray-color);
            font-weight: var(--eke-font-weight-light) !important;
    
            svg{
                width: 0.85rem !important;
                fill: var(--eke-bg-success-color);
            }
        }
}