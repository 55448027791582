
.text-primary{
    color: $primary !important;
}

.h_re{

    border: none !important;
    margin-bottom: 1.0rem !important;
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 1px 1px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 17.5px );
    -webkit-backdrop-filter: blur( 17.5px );


    .card-body{

        padding-left: 15px !important;
        padding-right: 15px !important;

        ._Dhor_slider_heading{
            font-size: 18px !important;
            font-weight: 600 !important;
            font-family: $fontfamily !important;
            letter-spacing: 0.5px;
            margin-bottom: 1rem;
            color: $primary;
        }

         .col__horiz__{

                    .card{
                        margin-top: 1.0rem !important;
                        margin-bottom: 1.0rem !important;
                        background: rgba( 255, 255, 255, 0.15 );
                        box-shadow: 0 1px 1px 0 rgba( 31, 38, 135, 0.37 );
                        backdrop-filter: blur( 17.5px );
                        -webkit-backdrop-filter: blur( 17.5px );
                        border-radius: 10px;
                        border: 1px solid rgba( 255, 255, 255, 0.18 );
                    
                        .card-body{
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                    
                            h4{
                                color: $secondary;
                                font-size: 16px !important;
                                font-weight: 500 !important;
                                font-family: $fontfamily;
                            }
                    
                            .pDcol_h{
                                width: 100%;
                                height: 300px;
                                
                                img{
                                    width: 100% !important;
                                    height: 100% !important;
                                    cursor: pointer;
                                    background-color: #f2f2f2;
                                    transition: .15s ease-in-out;
                    
                                    &:hover{
                                        opacity: .15s ease-in-out;
                                        @extend .shadow;
                    
                                    }
                                }
                            }
                    
                            .bott_DQ{
                                
                                a{
                                    text-decoration: none;
                                    font-size: 14px;
                                    font-weight: 480;
                                    font-family: $fontfamily !important;
                                    letter-spacing: -0.5px;
                                }
                            }
                        }
                    
                        &:hover{
                            @extend .shadow-sm;
                    
                        }
                    }
        }
        
                }


            }
