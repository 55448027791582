.d_cart_wrapper {
  margin-bottom: 5rem;
  min-height: 80vh;
  max-height: auto;


  .cart_column_v {
    .container {
      @include media-breakpoint-up(lg) {
        width: $containerwidth !important;
        max-width: $maxWidth !important;
        margin: 0 auto;
      }

      @include media-breakpoint-up(xl) {
        width: $containerwidth !important;
        max-width: $maxWidth !important;
        margin: 0 auto;
      }

      .outline-button {
        color: $secondary !important;
        font-size: 13.4px;
        font-weight: 500;

        &:hover {
          background-color: transparent !important;
          @extend .shadow;
        }
      }

      ._heading {
        .card {
          border: none !important;
          margin-bottom: 0.5rem;
          border-radius: 10px;
          //@extend .shadow-sm;
          @include boxShadow;

          .card-body {
            h5 {
              font-family: var(--eke-body-font-family);
              font-weight: var(--eke-font-weight-bold);
              color: $secondary;
              font-size: 24px !important;
            }
          }
        }
      }

      .cart_mainBar {
        .list_cart_ul {
          padding: 0 !important;

          .list-group-item {
            border: none;
            padding: 0 !important;
            background-color: transparent !important;
            margin-top: 0.7rem;

            .saved__item- {
              display: none;
              width: 27px;
              height: 27px;
              border-radius: 50%;
              text-align: center;
              background: #fff;
              line-height: 25px;
              position: absolute;
              left: 4px;
              top: 4px;
              opacity: 1;
              z-index: 2 !important;
              box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
              transition: all 0.3s ease-in-out;

              color: $secondary;
              svg {
                cursor: pointer !important;
              }

              &:hover {
                color: $primary-light;
              }
            }

            .card {
              border: none !important;
              margin-bottom: 0.5rem;
              border-radius: 10px;
              box-shadow: none !important;
              background-color: transparent;

              .card-body {
                a {
                  text-decoration: none !important;
                }
                .card-text {
                  .row {
                    ._group_img_title {
                      display: flex;
                      align-items: flex-start;

                      @include media-breakpoint-up(lg) {
                        align-items: flex-start;
                      }

                      ._card_img_col {
                        width: 80px !important;
                        height: 80px !important;
                        border-radius: 10px;

                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 10px;
                          object-fit: cover;
                        }
                      }

                      ._title_desc_ {
                        .d_wrapper {
                          margin: 0;
                          padding: 0;
                          align-items: flex-start;

                          .card-title {
                            display: block;
                            display: -webkit-box;
                            max-width: 100%;
                            font-size: 14px !important;
                            color: $secondary;
                            margin-bottom: 7.55px;
                            max-width: 100%;
                            height: auto; /* Fallback for non-webkit */
                            line-height: 1.4;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @extend .fontFamily400;

                            &:hover {
                              color: $primary;
                              text-decoration: none;
                            }
                          }

                          .cart_actual_price {
                            padding-right: 0 !important;
                            //font-family: $fontfamily !important;
                            font-size: 16px !important;
                            color: $secondary;
                            @extend .fontFamily700;
                          }
                        }

                        .d_wrapper_rig {
                          .actual_price_quantity {
                            color: #9b9b9b;
                            font-size: 0.8125rem;
                            font-weight: 600;
                            margin-bottom: 10.555px !important;
                          }


                          ._list_controls_types {
                            width: 50%;
                            height: 55px;
                            margin-top: 0.6rem;
                            overflow: hidden;
                            border: 1px solid #f2f2f2;
                            padding: 0.4rem 0.8rem !important;

                            .list-group-item {
                              ul {
                                display: flex;
                                align-items: center;
                                list-style: none !important;
                                margin: 0;
                                padding: 0;
                                flex-wrap: wrap;

                                li {
                                  display: flex;
                                  align-items: center;
                                  margin-right: 0.5rem;
                                  margin-bottom: 0.3rem;

                                  span {
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #666666;
                                  }
                                }
                              }
                            }
                          }

                          .cart_quantity_remove_col {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            ._action_icons_col {
                              display: flex;
                              align-items: center;
                              justify-content: flex-start;
                              margin-top: 0.3rem;
                              margin-bottom: 0.3rem;
                              color: $secondary;
                              text-decoration: underline !important;

                              span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 13px !important;
                                cursor: pointer;

                                svg {
                                  color: rgba(
                                    $secondary,
                                    $alpha: 0.8
                                  ) !important;
                                  font-size: 13px !important;
                                  margin-top: 0.1rem;
                                  cursor: pointer;
                                }
                              }

                              .spinner_div {
                                svg {
                                  stroke-width: 0.3px !important;
                                }
                              }

                              // .remove__item_{
                              //     display: flex;
                              //     align-items: center;
                              //     font-size: 12.5px;
                              //     cursor: pointer;
                              //     gap: 1px;

                              //     svg{
                              //         margin-top: 2px;
                              //         font-size: 14px !important;
                              //     }

                              // }

                              &:hover {
                                text-decoration: underline !important;
                                color: $secondary !important;
                              }

                              @include media-breakpoint-up(lg) {
                                justify-content: flex-end;
                                margin-top: 0rem;
                                margin-bottom: 0rem;
                              }

                              @include media-breakpoint-up(xl) {
                                justify-content: flex-end;
                                margin-top: 0rem;
                                margin-bottom: 0rem;
                              }
                            }

                            .cart_listing_quantity {
                              width: 100px !important;
                              margin-top: 0.5rem;
                              margin-left: 2rem;

                              .quantity-picker {
                                border: none !important;
                                border-radius: 3px;
                                vertical-align: middle;
                                display: flex !important;
                                align-items: center;
                                justify-content: space-between;

                                .quantity-display {
                                  background-color: transparent !important;
                                  padding: 0rem;
                                  width: 2rem !important;
                                  font-size: 12px !important;
                                  font-family: $fontfamily !important;
                                  border: 0;
                                  border-top: 0 solid #dbdbdb;
                                  border-bottom: 0 solid #dbdbdb;
                                  text-align: center;
                                  color: $secondary;
                                }

                                .quantity-modifier {
                                  height: auto;
                                  width: 1.5rem;
                                  font-size: 15px;
                                  background: #f2f2f2 !important;
                                  color: rgba($secondary, $alpha: 0.6);
                                  text-align: center;
                                  cursor: pointer;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .empty__cart__div {
          .empty__cart__text {
            h5 {
              font-size: 14.5px !important;
              color: $secondary;
              font-weight: 550;
              font-family: $fontfamily;
            }
            p {
              font-size: 15px;
              font-family: $fontfamily;
            }
          }
        }
      }

      .cart__total_price {
        @include media-breakpoint-up(lg) {
          flex: 0 0 auto !important;
          width: 28.33333333% !important;
        }

        .card {
          border: none !important;
          border-radius: 10px;
          //@extend .shadow-sm;
          @include boxShadow;

          .card-body {
            padding-top: 1rem !important;

            .card-title {
              font-family: var(--eke-body-font-family);
              font-weight: var(--eke-font-weight-bold);
              font-size: 24px !important;
              color: $secondary;
              border-bottom: 1px solid #f2f2f2;
              padding-bottom: 0.8rem;
              padding-left: 0.6rem;
              padding-right: 0.6rem;
            }

            .summary__shipping__price {
              margin-top: 0.2rem;
              //margin-bottom: 1rem;
              padding-bottom: 1.8rem !important;
              border-bottom: 1px solid #f2f2f2 !important;

              .list-group {
                .list-group-item {
                  border: none !important;
                  display: flex;
                  background-color: transparent !important;
                  align-items: flex-start;
                  justify-content: space-between;
                  padding: 0 !important;
                  padding-left: 0.6rem !important;
                  padding-right: 0.6rem !important;

                  ._total_price__ {
                    // font-size: 16px;
                    // font-weight: 500;
                    // color: #1c1d1ce8;
                  }
                }
                ._top_cart_total {
                  font-family: $fontfamily;
                  //border-bottom: 1px solid #f2f2f2 !important;
                  padding-bottom: 0.3rem !important;
                }

                ._shipping__charges {
                  padding-top: 0rem !important;

                  ._shipping_ {
                    color: #9b9b9b;
                    font-size: 0.625rem;
                    font-weight: 400;
                    text-align: right;
                    width: 6.05rem;
                  }
                }
              }
            }

            .summary__total__price {
              padding-top: 0.8rem !important;

              .list-group {
                margin-top: 0.3rem;
                margin-bottom: 1.5rem !important;

                .list-group-item {
                  border: none !important;
                  display: flex;
                  background-color: transparent !important;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 !important;
                  padding-left: 0rem !important;
                  padding-right: 0rem !important;

                  .subtotal_label {
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $fontfamily;
                  }

                  .subtotal_price {
                    font-size: 24px;
                    font-weight: 700;
                    font-family: $fontfamily;
                  }
                }
              }
            }

            .border__line {
              border: 1px solid #f2f2f2 !important;
              margin-top: 0.2rem;
              margin-bottom: 0.8rem;
            }

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
