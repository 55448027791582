
@use "../abstract/colors" as *;
@use "../abstract/fonts" as *;



@import "~bootstrap/scss/bootstrap-utilities";


$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
  ),
  )
  );


  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ) !default;
      
  
  // $container-max-widths: (
  //   sm: 500px,
  //   md: 680px,
  //   lg: 983px,
  //   xl: 1170px
  // ) !default;
 
 // @import "~bootstrap/scss/functions";

  $primary:     var(--eke-color-primary);
  $secondary:   var(--eke-color-secondary);
  $input-box-shadow: none;
  $input-focus-box-shadow: none;
  $input-btn-focus-box-shadow: none;

  $btn-box-shadow: none;
  $btn-focus-box-shadow: none;

  $accordion-button-focus-border-color:     none;
  $accordion-button-focus-box-shadow:       none;
  $accordion-border-radius:                 7px;
  $accordion-border-width:                  1px;
  $accordion-border-color:                  rgba(0,0,0, .1);

  $form-check-input-width:                  1.2em;
  $form-check-input-bg:                     #fff;
  $form-check-input-border:                 2.5px solid var(--eke-color-primary);
  
  @import "~bootstrap/scss/bootstrap"; 
  
// // 3. Include remainder of required Bootstrap stylesheets
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// // 4. Include any optional Bootstrap components as you like
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/type";
// // @import "../node_modules/bootstrap/scss/images";
