
.NewAddress__Button{
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    button{
        padding: 0!important;
        text-decoration: underline;

        span{
            font-size: 13px !important;
        }

    }

}