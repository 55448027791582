.Container_Width{
 width: 100%;
}

.Checkbox__Container{
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.7em;

    // .form-check-label{
    //     margin-left: 0 !important;
    // }

    label{
        top: 3px !important;
    }

    .requiredStyle{
        color: var(--eke-color-error) !important ;
        margin-left: 1px !important;
    }

    .Checkbox__Input{
        cursor: pointer !important;
        display: flex;
        align-items: center;
    }

    .Checkbox__Label{
        cursor: pointer;
        width: 100%;
        font-size: 13.5px !important;
        font-family: var(--eke-body-font-family) !important;
        font-weight: var(--eke-font-weight-normal) !important;
    }
}

