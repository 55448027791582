@use "../../../../../scss/abstract/mixin"  as mixin;


.CardBox_Rating{
    margin-top: 1rem;
    border-top: 1px solid var(--eke-border-white-dark-color);
    padding-top: 1.3rem;
    border-radius: 0 !important;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    @include mixin.laptop(){
        flex-direction: row;
    }

.CardBox_Image{
    height: auto;
}
    
.Item_Typography{
    margin-top: 0.55rem;
}
    // .Typography__Group{
    //     margin-top: 1rem;
    // }


    .RatingDiv{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        padding-top: 0.5rem;

        @include mixin.laptop(){
            flex-direction: row;
        }

        span{
            margin-top: 0.5rem;
        }
    }
}