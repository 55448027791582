
.ErrorUI___Wrapper{
    position: relative;
    background-color: var(--eke-bg-light-color);
    width: 100%;
    height: 100vh;
    padding-top: 0px;


    .ErrorBound__container{
        margin: 0 auto;
        height: auto;


        .ErrorBound_Logo__wrapper{
            display: block;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--eke-bg-secondary-color);
            padding: 8px;
    
            img{
                width: 150px;
                height: 100%;
            }
        }

        .ErrorBound_Content__wrapper{
            
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 80vh;
            width: 100%;
            padding: 20px 50px 20px 50px;

        }

    }



}