.new_return__req{
    border-radius: 0.25rem;
    margin-bottom: 50px;
    @extend .shadow-sm;
}

.return__req, 
.new_return__req{

    a{text-decoration: none !important;}
    .btn__new__request{
        //color: #fff !important;
        font-size: 13.5px !important;
        box-shadow: none !important;

        &:focus{
            box-shadow: none !important;
        }
    }


    .empty__request_place{
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        height:400px;

        .empty__div_centered{
             
            .image__container_order{
               text-align: center;
               margin-bottom: 10px;

               img{
                width: 100px;
                height: auto;
                filter: grayscale(0.8) !important;
                opacity: .3;
               }
            }

            p{
                font-size: 20px;
                font-family: $fontfamily;
                font-weight: bolder;
                color: rgba($secondary, $alpha: 0.5);
            }

        }
    }

    margin-top: 0.8rem;

    ._heading_tit{
        margin-top: 0.45rem;
        margin-bottom: 0.45rem;
        h5{
            color: $secondary;
            font-size: 16px;
            font-family: $fontfamily;

        }
    }

    .list_of_product{
        width: 90%;
        height: 250px;
        margin: 0 auto;
        background-color: #EAF1FB;
    }

    ._col__product{

        label{
            font-size: 14px;
            font-weight: 500;
            color: $secondary;
        }

        .form-select {
            font-size: 13.5px;
        }
    }
}