
.tab__{

    .nav-tabs{
       border-bottom: 0 !important;
        .nav-item{
            margin-right: 0.8rem ;
            

            .nav-link {
                background: white !important;
               border-radius: 100px !important;
                box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px !important;
                color: #232F3E !important;
                cursor: pointer;
                display: flex ;
                align-items: center;
                font-family: $fontfamily;
                padding: 10px 20px !important;
                text-align: center;
                text-decoration: none;
                transition: all 250ms;
                border: 0;
                font-size: 13.5px;
                user-select: none;
                -webkit-user-select: none;
                touch-action: manipulation;

                .tab__identifier{
                    border-radius: 50% !important;
                    padding: 0.2em 0.2em !important;
                    font-size: 0.65em !important;
                    font-weight: 450 !important;
                    vertical-align: middle;
                }
                
                .tab__identifier_completed{
                    background-color: #32cd32 !important;
                }

                .tab__identifier_processing{
                    background-color: #1e90ff !important;
                }

                .tab__identifier_pending{
                    background-color: orange !important;
                }

                .tab__identifier_cancelled{
                    background-color: red !important;
                    
                }

                
            }

            .nav-link.active{
                //box-shadow:  0 -2px 8px inset !important;
                transform: scale(1.01) !important;
            }
        }
    }

}