
.EkeMarket__Div{
    padding: 0px 10px;

    @include media-breakpoint-up(xl){
        padding: 0px 0px;

    }



    .EkeMarket__rowDirection{
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

      
        .EkeMarket__Col{
            width: 100% !important;

            @include media-breakpoint-up(xl){
                width: 32.333% !important;
            }
            
            .card {
                margin-bottom: 10px;
                @include boxShadow;
                
                .card-body{
                    padding: 40px 30px 40px 30px !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;


                    // .EkeMarket__heading-title{
                    //     font-size: 16px;
                    //     color: $secondary;
                    //     text-transform: capitalize;
                    //     @extend .fontFamily400;
                    // }
                    
                    .EkeMarket__heading{
                        font-size: 17px !important;
                        padding: 0 !important;
                        font-weight: 500 !important;
                    }

                    span.EkeMarket__icon{
                      

                        svg{
                            font-size: 35px;
                            //color: $primary;
                        }
                    }
                }
    
            }
        }

    }
}