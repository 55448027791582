
.dashboard-custom-main-bar-margin{
    margin-left: 0;
    @media (min-width: 1200px) {
        margin-left: 15rem;
    }
}
.dashboard-custom-main-bar{
    height: 100%;
    display: flex;
    flex-direction: column;

    @include getNavbarStyles();

    .dashboard-live-stream-bg{
       background-color: #0F172A !important;
    }

    .dashboard-custom-main-bar-content-div{
        padding-top: 0rem !important;
        overflow-x: hidden !important;
        overflow-y: auto;

        // seller dashboard
        .accordion{
    
            .accordion-header{
                
                button{ 
                  
                    .icon_variant_color{
                        color: $primary !important;
                        font-size: 17px !important;
                    }
    
                span{
                    font-family:  $fontfamily !important;
                    font-size: 15px !important;
                    font-weight: 400 !important;
                    color: $secondary !important;
                }
                
            }
        }
    
        ._order_custom {
    
            button{
                display: flex;
                    align-items: center;
                    justify-content: space-between;
                &::after {
                    flex-shrink: 1 !important;
                    margin-left: -60% !important;
                }
    
            }
        }
        
        .accordion-collapse{
            
            .accordion-body{
                
                ._getstarted_sellerdrop_{
                    
                    h5{
                        font-family: $fontfamily !important;
                        font-weight: 600 !important;
                        font-size: 17px !important;
                    }
                    p{
                        font-family: $fontfamily !important;
                        font-size: 14px !important;
    
                      }
    
                      .vertical-timeline{
                        width: 100% !important ;
                        /* max-width: 1170px; */
                        margin: 0 auto;
                        position: relative;
                        padding: 0em 0 !important;
    
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 18px;
                            height: 100%;
                            width: 2px !important;
                        }
    
                        .vertical-timeline-element--work{
                            margin: 0em 0 1rem 0 !important;
    
                            .vertical-timeline-element-icon{
                                position: absolute;
                                top: 0;
                                left: 0.7%;
                                width: 25px;
                                height: 25px;
                                -webkit-box-shadow: none !important;
                                box-shadow: none !important;
                                text-align: center;
                                font-size: 13.5px !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $secondary !important;
                                background-color: #fcede9 !important;
                                
    
                            }
    
                            .vertical-timeline-element-content {
                                -webkit-box-shadow: none !important;
                                box-shadow: none !important;
                                width: 25% !important;
                                background-color: #f1f1f4 !important;
    
    
                                .vertical-timeline-element-subtitle{
                                    font-size: 13.5px !important;
                                    font-family: $fontfamily !important;
                                    font-weight: 400 !important;

                                    a{
                                        color: inherit !important;
                                        text-decoration: none;

                                        &:hover{
                                            text-decoration: underline;

                                        }
                                    }
                                }
    
                                    .vertical-timeline-element-date {
                                        padding:0 !important;
                                    }
                                    
    
                            }
                        }
                      }
                    }
                }
            }
    
    
        }
    }
}