


.error {
    color: var(--eke-body-text-danger-color);
    display: flex;
    font-size: .75rem;
    line-height: 1rem;
    gap: 0.2rem;
    margin: 0.25rem 0.6rem;

    svg{
      fill: var(--eke-bg-danger-color) !important;
    }
  }
 
  
.bottom {
  margin-top: 5px;
  width: 100%;
  padding: 0;
}

.fullWidth {
  width: 100%;
}


.disabled {
  cursor: default;
}


@media (max-width: 600px) {
  .fullWidth {
    margin-left: 3px;
  }
}


.FieldInput__Prefix{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1 !important;
  padding: 11px 10px 10px 10px;
  font-size: 15px !important;
  color: var(--eke-body-text-gray-dark-color);

}

.FieldInput__PrefixPadding{
  padding-left: 2.2rem !important;
  font-size: 13.5px !important;
}

.TextAreaField{
  width: 100%;
  height: 110px;
}