
.CardBox_Parent{
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    height: 400px;
    background-color: transparent !important;

    .Empty__div_centered{
        text-align: center;

        .Image {
            text-align: center;
            //margin-bottom: 20px;
      
            img {
              width: 200px;
              height: 220px;
              filter: grayscale(0.8) !important;
             opacity: 0.6;
            }
    }

    .CardBox_ParaText{
     color: #3b3b3b;
     text-align: center !important;
    }
}
}