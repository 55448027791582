@use "../../scss/abstract/mixins.scss" as mixins;

.tippy_custom_styles {
    box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
        0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
    overflow: hidden;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-light);
    /* border: ; */
    border-radius: 4px;
    overflow: hidden;
}
.tippy_custom_styles > div {
    padding: 0 !important;
}
.tippy_custom_styles li {
    width: 100% !important;
}

.dropdown_menu {
    min-width: 150px;
    min-height: auto;
    padding: 5px 0px;
    margin: 2px 0 0;
    list-style: none;

    li{

    }
}
.divider {
    height: 1px;
    margin: 5px 1px;
    /* background-color: #e5e5e5; */
    border-bottom: 1px solid #eee;
}


.Dropdown_Button{
background: white;
  border: none;
  font-size: 13.5px !important;
  outline: none !important;
  font-family: var(--eke-body-font-family);
  //box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 1px 1px 0 rgb(0 0 0 / 12%), 0 0 0 1px rgb(60 66 87 / 16%), 0 0 0 0 transparent, 0 0 0 0 transparent, 0 2px 5px 0 rgb(60 66 87 / 8%);
    margin-right: 10px;
    font-weight: var(--eke-font-weight-light);
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover{
        background: #F5FBFF !important;
    }
    @include mixins.boxShadow;

}

.DropdownMenu__Flex{
    display: flex;
	align-items: center;
    gap: 1px;
}

.Dropdown_ButtonIcon{
  border: none !important;
  width: auto !important;
  background-color: transparent !important;
}

.DropdownMenu__item {
	display: flex;
    width: 100%;
	line-height: 2;
    justify-content: space-between;
	align-items: center;
    display: flex;
    padding: 5px 15px;
    padding-left: 8px !important;
    align-items: center;
    gap: 2px;
	cursor: pointer;
}

.DropdownMenu__item:hover:not(.is-disabled) {
	background-color: #F5FBFF;
}
// .DropdownMenu__item:hover:not(.is-disabled) .DropdownMenu__icon {
// 	color: #fff;
// }
// .DropdownMenu__item:hover:not(.is-disabled) .DropdownMenu__body {
// 	color: #fff;
// }
.DropdownMenu__item.is-disabled {
	cursor: default;
	pointer-events: none;
	opacity: 0.3;
}


.DropdownMenu__text{
    color: var(--greyscale-800, #424242);
    font-family: var(--eke-body-font-family);
    font-size: 13.5px;
    font-weight: var(--eke-font-weight-medium) !important;
    line-height: 29px;
   
}


.btn{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;

   
  }